import * as React from 'react';
import { css, Stack, PrimaryButton, Text, getTheme, INavLinkGroup, Nav, IStackProps, INavLink, TextField, Label, IconButton, StackItem } from 'office-ui-fabric-react';
import '../Proposal.scss';
import { IFormProps } from '../Models/IFormProps';
import { observer } from 'mobx-react';
import { WizardNavigationButtons } from './WizardNavigationFooter';
import { IUserAccount } from '../../LoginComponent/LoginComponent';
import ProposalFormValidation from './ProposalFormValidation';

export interface IStudyTeamProps extends IFormProps {
    currentUser?: IUserAccount;

    fieldErrors?: any;
    goToStep?: any;
}

export interface IStudyTeamState {    
    freezeApplicant:boolean;
}

@observer
export class StudyTeam extends React.Component<IStudyTeamProps, IStudyTeamState> {

    private isDefaultApplicant:boolean = false;
    //private fieldErrors:any = null;

    constructor(props: IStudyTeamProps) {
        super(props);

        this.setDefaultApplicant(props.currentUser);            
        //this.fieldErrors = props.fieldErrors && props.fieldErrors[0] ? props.fieldErrors[0] : {};
    }

    public componentDidMount() {
    }

    public componentWillReceiveProps(newProps: IStudyTeamProps) {
        if (this.props.currentUser != newProps.currentUser) {
            this.setDefaultApplicant(newProps.currentUser);            
        }

        // if(this.props.fieldErrors != newProps.fieldErrors){
        //     this.fieldErrors = this.props.fieldErrors && this.props.fieldErrors[0] ? this.props.fieldErrors[0] : {};
        // }
    }

    private setDefaultApplicant = (currentUser: IUserAccount) => {
        console.log("setDefaultApplicant: ", currentUser);
        let userName = currentUser ? `${currentUser.firstName} ${currentUser.lastName}` : null;
        this.props.proposal.applicantName = this.props.proposal.applicantName || userName;
        this.props.proposal.applicantEmail = this.props.proposal.applicantEmail || currentUser?.email;
        this.isDefaultApplicant = true;
    };

    public render() {
        const columnProps1: Partial<IStackProps> = {
            tokens: { childrenGap: 10 },
            styles: { root: { width: "20%" } },
        };

        const columnProps2: Partial<IStackProps> = {
            tokens: { childrenGap: 10 },
            styles: { root: { width: "30%" } },
        };

        const columnProps3: Partial<IStackProps> = {
            tokens: { childrenGap: 10 },
            styles: { root: { width: "50%" } },
        };

        const columnProps4: Partial<IStackProps> = {
            tokens: { childrenGap: 10 },
            styles: { root: { width: "70%" } },
        };

        const columnProps5: Partial<IStackProps> = {
            tokens: { childrenGap: 10 },
            styles: { root: { width: "80%" } },
        };

        if (!this.props.proposal.coInvestigators || this.props.proposal.coInvestigators.length == 0) {
            this.props.proposal.coInvestigators = [];
            this.props.proposal.coInvestigators.push({});
        }

        if (!this.props.proposal.subInvestigators || this.props.proposal.subInvestigators.length == 0) {
            this.props.proposal.subInvestigators = [];
            this.props.proposal.subInvestigators.push({});
        }

        //fieldErrors = this.props.fieldErrors && this.props.fieldErrors[0] ? this.props.fieldErrors[0] : {};
        
        //console.log("FieldErrors", this.props.proposal?.fieldErrors);

        return (
            <Stack verticalAlign="center" className={css('fullWidth', 'animate', 'stepPopIn')} verticalFill={true} tokens={{ childrenGap: 20 }}>
                <Stack verticalAlign="space-between" tokens={{ childrenGap: 5 }}>
                    {
                        this.props.isReadonly && <Label className="heading">Study Team</Label>
                    }
                    {
                        !this.props.isReadonly && <h1>Study Team</h1>
                    }
                </Stack>
                <Stack className="fullWidth">
                    <ProposalFormValidation {...this.props} fieldErrors={this.props.proposal.fieldErrors} ></ProposalFormValidation>
                </Stack>
                <Stack className="fullWidth" tokens={{ childrenGap: 10 }}>
                    <fieldset>
                        <legend>Primary Investigator (PI)</legend>
                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                            <Stack {...columnProps3}>
                                <Label>Applicant Name: <span className="smallLabel">{this.props.proposal.applicantName}</span></Label>
                                {
                                    /**
                                     * 
                                                                     <TextField label="Applicant Name" value={this.props.proposal.applicantName} readOnly={this.props.isReadonly || this.isDefaultApplicant} borderless={this.props.isReadonly || this.isDefaultApplicant}
                                    onChange={(_event: any, newValue?: string) => {
                                        this.props.proposal.applicantName = newValue;
                                    }}></TextField>
                                     

                                     * 
                                     
                                     * **/
                                }
                            </Stack>

                            <Stack {...columnProps3}>
                                <Label>Applicant Email: <span className="smallLabel">{this.props.proposal.applicantEmail}</span></Label>
                                {
                                    
                                    /**
                                <TextField label="Applicant Email" value={this.props.proposal.applicantEmail} readOnly={this.props.isReadonly || this.isDefaultApplicant} borderless={this.props.isReadonly || this.isDefaultApplicant}
                                    onChange={(_event: any, newValue?: string) => {
                                        this.props.proposal.applicantEmail = newValue;
                                    }}></TextField>

**/
                                }
                            </Stack>
                        </Stack>
                        <Stack className="fullWidth" tokens={{ childrenGap: 10 }}>
                            <TextField maxLength={255} placeholder="Institute Name" required errorMessage={this.props.proposal.fieldErrors && this.props.proposal.fieldErrors.length!==0? this.props.proposal.fieldErrors[0].piName?.error : null} value={this.props.proposal.piName} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                onChange={(_event: any, newValue?: string) => {
                                    this.props.proposal.piName = newValue;
                                }}></TextField>
                            <Stack horizontal tokens={{ childrenGap: 10 }}>
                                <Stack {...columnProps5}>
                                    <TextField placeholder="Street" required value={this.props.proposal.contactDetailsPI?.street} errorMessage={this.props.proposal.fieldErrors ? this.props.proposal.fieldErrors[0]["contactDetailsPI.street"]?.error : null} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                        onChange={(_event: any, newValue?: string) => {
                                            if (!this.props.proposal.contactDetailsPI)
                                                this.props.proposal.contactDetailsPI = {};
                                            this.props.proposal.contactDetailsPI.street = newValue;
                                        }}></TextField>
                                </Stack>
                                <Stack {...columnProps1}>
                                    <TextField placeholder="No" required value={this.props.proposal.contactDetailsPI?.no} errorMessage={this.props.proposal.fieldErrors ? this.props.proposal.fieldErrors[0]["contactDetailsPI.no"]?.error : null} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                        onChange={(_event: any, newValue?: string) => {
                                            if (!this.props.proposal.contactDetailsPI)
                                                this.props.proposal.contactDetailsPI = {};
                                            this.props.proposal.contactDetailsPI.no = newValue;
                                        }}></TextField>
                                </Stack>
                            </Stack>
                            <Stack horizontal tokens={{ childrenGap: 10 }}>
                                <Stack {...columnProps2}>
                                    <TextField placeholder="Postal Code" required value={this.props.proposal.contactDetailsPI?.postalCode} errorMessage={this.props.proposal.fieldErrors ? this.props.proposal.fieldErrors[0]["contactDetailsPI.postalCode"]?.error : null} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                        onChange={(_event: any, newValue?: string) => {
                                            if (!this.props.proposal.contactDetailsPI)
                                                this.props.proposal.contactDetailsPI = {};
                                            this.props.proposal.contactDetailsPI.postalCode = newValue;
                                        }}></TextField>
                                </Stack>
                                <Stack {...columnProps4}>
                                    <TextField placeholder="City" required errorMessage={this.props.proposal.fieldErrors ? this.props.proposal.fieldErrors[0]["contactDetailsPI.city"]?.error : null} value={this.props.proposal.contactDetailsPI?.city} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                        onChange={(_event: any, newValue?: string) => {
                                            if (!this.props.proposal.contactDetailsPI)
                                                this.props.proposal.contactDetailsPI = {};
                                            this.props.proposal.contactDetailsPI.city = newValue;
                                        }}></TextField>
                                </Stack>
                            </Stack>
                            <Stack horizontal tokens={{ childrenGap: 10 }}>
                                <Stack {...columnProps3}>
                                    <TextField placeholder="Country" required errorMessage={this.props.proposal.fieldErrors ? this.props.proposal.fieldErrors[0]["contactDetailsPI.country"]?.error : null} value={this.props.proposal.contactDetailsPI?.country} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                        onChange={(_event: any, newValue?: string) => {
                                            if (!this.props.proposal.contactDetailsPI)
                                                this.props.proposal.contactDetailsPI = {};
                                            this.props.proposal.contactDetailsPI.country = newValue;
                                        }}></TextField>
                                </Stack>
                                <Stack {...columnProps3}>
                                    <TextField placeholder="State (for US addresses)" value={this.props.proposal.contactDetailsPI?.state} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                        onChange={(_event: any, newValue?: string) => {
                                            if (!this.props.proposal.contactDetailsPI)
                                                this.props.proposal.contactDetailsPI = {};
                                            this.props.proposal.contactDetailsPI.state = newValue;
                                        }}></TextField>
                                </Stack>
                            </Stack>
                            <Stack horizontal tokens={{ childrenGap: 10 }}>
                                <Stack {...columnProps3}>
                                    <TextField placeholder="Email" required errorMessage={this.props.proposal.fieldErrors? this.props.proposal.fieldErrors[0]["contactDetailsPI.email"]?.error : null}  value={this.props.proposal.contactDetailsPI?.email} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                        onChange={(_event: any, newValue?: string) => {
                                            if (!this.props.proposal.contactDetailsPI)
                                                this.props.proposal.contactDetailsPI = {};
                                            this.props.proposal.contactDetailsPI.email = newValue;
                                        }}></TextField>
                                </Stack>
                                <Stack {...columnProps3}>
                                    <TextField placeholder="Phone" required errorMessage={this.props.proposal.fieldErrors? this.props.proposal.fieldErrors[0]["contactDetailsPI.phone"]?.error : null} value={this.props.proposal.contactDetailsPI?.phone} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                        onChange={(_event: any, newValue?: string) => {
                                            if (!this.props.proposal.contactDetailsPI)
                                                this.props.proposal.contactDetailsPI = {};
                                            this.props.proposal.contactDetailsPI.phone = newValue;
                                        }}></TextField>
                                </Stack>
                            </Stack>
                        </Stack>
                    </fieldset>
                    <fieldset>
                        <legend>Co Investigators</legend>
                        <Stack className="fullWidth" tokens={{ childrenGap: 10 }}>
                            <Stack horizontal tokens={{ childrenGap: 10 }}>
                                <Stack {...columnProps1}>
                                    <TextField placeholder="Firstname" value={this.props.proposal.coInvestigators[0].firstname} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                        onChange={(_event: any, newValue?: string) => {
                                            if (this.props.proposal.coInvestigators)
                                                this.props.proposal.coInvestigators[0].firstname = newValue;
                                        }}></TextField>
                                </Stack>
                                <Stack {...columnProps1}>
                                    <TextField placeholder="Lastname" value={this.props.proposal.coInvestigators[0].lastname} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                        onChange={(_event: any, newValue?: string) => {
                                            if (this.props.proposal.coInvestigators)
                                                this.props.proposal.coInvestigators[0].lastname = newValue;
                                        }}></TextField>
                                </Stack>
                                <Stack {...columnProps1}>
                                    <TextField placeholder="Email" value={this.props.proposal.coInvestigators[0].email} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                        onChange={(_event: any, newValue?: string) => {
                                            if (this.props.proposal.coInvestigators)
                                                this.props.proposal.coInvestigators[0].email = newValue;
                                        }}></TextField>
                                </Stack>
                                <Stack {...columnProps1}>
                                    <TextField placeholder="Institute" value={this.props.proposal.coInvestigators[0].institute} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                        onChange={(_event: any, newValue?: string) => {
                                            if (this.props.proposal.coInvestigators)
                                                this.props.proposal.coInvestigators[0].institute = newValue;
                                        }}></TextField>
                                </Stack>
                                {
                                    !this.props.isReadonly &&
                                    <IconButton iconProps={{ iconName: 'Add' }}
                                        title="Add" ariaLabel="Add" onClick={() => {
                                            this.props.proposal.coInvestigators?.push({});
                                        }} />
                                }
                            </Stack>
                            {
                                this.props.proposal.coInvestigators?.map((item, index) => {
                                    if (index != 0) {
                                        return <Stack key={index} horizontal tokens={{ childrenGap: 10 }}>
                                            <Stack {...columnProps1}>
                                                <TextField placeholder="Firstname" value={item.firstname || ''} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                                    onChange={(_event: any, newValue?: string) => {
                                                        item.firstname = newValue;
                                                    }}></TextField>
                                            </Stack>
                                            <Stack {...columnProps1}>
                                                <TextField placeholder="Lastname" value={item.lastname || ''} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                                    onChange={(_event: any, newValue?: string) => {
                                                        item.lastname = newValue;
                                                    }}></TextField>
                                            </Stack>
                                            <Stack {...columnProps1}>
                                                <TextField placeholder="Email" value={item.email || ''} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                                    onChange={(_event: any, newValue?: string) => {
                                                        item.email = newValue;
                                                    }}></TextField>
                                            </Stack>
                                            <Stack {...columnProps1}>
                                                <TextField placeholder="Institute" value={item.institute || ''} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                                    onChange={(_event: any, newValue?: string) => {
                                                        item.institute = newValue;
                                                    }}></TextField>
                                            </Stack>
                                            {
                                                !this.props.isReadonly &&
                                                <IconButton iconProps={{ iconName: 'Remove' }}
                                                    title="Remove" ariaLabel="Remove" onClick={() => {
                                                        this.props.proposal.coInvestigators?.splice(index, 1);
                                                    }} />
                                            }
                                        </Stack>;
                                    }
                                })
                            }
                        </Stack>
                    </fieldset>
                    <fieldset>
                        <legend>Sub Investigators</legend>
                        <Stack className="fullWidth" tokens={{ childrenGap: 10 }}>
                            <Stack horizontal tokens={{ childrenGap: 10 }}>
                                <Stack {...columnProps1}>
                                    <TextField placeholder="Firstname" value={this.props.proposal.subInvestigators[0].firstname} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                        onChange={(_event: any, newValue?: string) => {
                                            if (this.props.proposal.subInvestigators)
                                                this.props.proposal.subInvestigators[0].firstname = newValue;
                                        }}></TextField>
                                </Stack>
                                <Stack {...columnProps1}>
                                    <TextField placeholder="Lastname" value={this.props.proposal.subInvestigators[0].lastname} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                        onChange={(_event: any, newValue?: string) => {
                                            if (this.props.proposal.subInvestigators)
                                                this.props.proposal.subInvestigators[0].lastname = newValue;
                                        }}></TextField>
                                </Stack>
                                <Stack {...columnProps1}>
                                    <TextField placeholder="Email" value={this.props.proposal.subInvestigators[0].email} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                        onChange={(_event: any, newValue?: string) => {
                                            if (this.props.proposal.subInvestigators)
                                                this.props.proposal.subInvestigators[0].email = newValue;
                                        }}></TextField>
                                </Stack>
                                <Stack {...columnProps1}>
                                    <TextField placeholder="Institute" value={this.props.proposal.subInvestigators[0].institute} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                        onChange={(_event: any, newValue?: string) => {
                                            if (this.props.proposal.subInvestigators)
                                                this.props.proposal.subInvestigators[0].institute = newValue;
                                        }}></TextField>
                                </Stack>
                                {
                                    !this.props.isReadonly &&
                                    <IconButton iconProps={{ iconName: 'Add' }}
                                        title="Add" ariaLabel="Add" onClick={() => {
                                            this.props.proposal.subInvestigators?.push({});
                                        }} />
                                }
                            </Stack>
                            {
                                this.props.proposal.subInvestigators?.map((item, index) => {
                                    if (index != 0) {
                                        return <Stack key={`subInvest_${index}`} horizontal tokens={{ childrenGap: 10 }}>
                                            <Stack {...columnProps1}>
                                                <TextField placeholder="Firstname" value={item.firstname || ''} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                                    onChange={(_event: any, newValue?: string) => {
                                                        item.firstname = newValue;
                                                    }}></TextField>
                                            </Stack>
                                            <Stack {...columnProps1}>
                                                <TextField placeholder="Lastname" value={item.lastname || ''} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                                    onChange={(_event: any, newValue?: string) => {
                                                        item.lastname = newValue;
                                                    }}></TextField>
                                            </Stack>
                                            <Stack {...columnProps1}>
                                                <TextField placeholder="Email" value={item.email || ''} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                                    onChange={(_event: any, newValue?: string) => {
                                                        item.email = newValue;
                                                    }}></TextField>
                                            </Stack>
                                            <Stack {...columnProps1}>
                                                <TextField placeholder="Institute" value={item.institute || ''} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                                    onChange={(_event: any, newValue?: string) => {
                                                        item.institute = newValue;
                                                    }}></TextField>
                                            </Stack>
                                            {
                                                !this.props.isReadonly &&
                                                <IconButton iconProps={{ iconName: 'Remove' }}
                                                    title="Remove" ariaLabel="Remove" onClick={() => {
                                                        this.props.proposal.subInvestigators?.splice(index, 1);
                                                    }} />
                                            }
                                        </Stack>;
                                    }
                                })
                            }
                        </Stack>
                    </fieldset>
                    <fieldset>
                        <legend>Study Nurse / Coordinator</legend>
                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                            <Stack {...columnProps1}>
                                <TextField placeholder="Firstname" value={this.props.proposal.studyNurse?.firstname} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                    onChange={(_event: any, newValue?: string) => {
                                        if (!this.props.proposal.studyNurse)
                                            this.props.proposal.studyNurse = {};
                                        this.props.proposal.studyNurse.firstname = newValue;
                                    }}></TextField>
                            </Stack>
                            <Stack {...columnProps1}>
                                <TextField placeholder="Lastname" value={this.props.proposal.studyNurse?.lastname} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                    onChange={(_event: any, newValue?: string) => {
                                        if (!this.props.proposal.studyNurse)
                                            this.props.proposal.studyNurse = {};
                                        this.props.proposal.studyNurse.lastname = newValue;
                                    }}></TextField>
                            </Stack>
                            <Stack {...columnProps1}>
                                <TextField placeholder="Email" value={this.props.proposal.studyNurse?.email} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                    onChange={(_event: any, newValue?: string) => {
                                        if (!this.props.proposal.studyNurse)
                                            this.props.proposal.studyNurse = {};
                                        this.props.proposal.studyNurse.email = newValue;
                                    }}></TextField>
                            </Stack>
                            <Stack {...columnProps1}>
                                <TextField placeholder="Institute" value={this.props.proposal.studyNurse?.institute} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                    onChange={(_event: any, newValue?: string) => {
                                        if (!this.props.proposal.studyNurse)
                                            this.props.proposal.studyNurse = {};
                                        this.props.proposal.studyNurse.institute = newValue;
                                    }}></TextField>
                            </Stack>
                        </Stack>
                    </fieldset>
                </Stack>

                {
                    this.props.showWizardNavigation && <StackItem className={css("wizardNavigationContainer")}>
                        <WizardNavigationButtons {...this.props} goToStep={this.props.goToStep} step={this.props.currentStep} save={this.props.onSave}></WizardNavigationButtons>
                    </StackItem>
                }
            </Stack>
        );

    }
}