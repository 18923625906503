import * as React from 'react';
import { css, Stack, } from 'office-ui-fabric-react';
import { Link } from 'react-router-dom';
import { PageURL } from '../../../App';

export interface ISuccessBoxProps {
    location: Location;
    history: History;
    stepSizeClass: string;
    message: string;
}

const SuccessBox = (props: ISuccessBoxProps) => {
    return (
        <Stack horizontal verticalAlign="center" className="fullWidth" horizontalAlign="center" verticalFill={true} tokens={{ childrenGap: 20 }}>
            <Stack horizontal horizontalAlign="center" className={css("subscriptionContainerInner", props.stepSizeClass)} tokens={{ childrenGap: 5 }}>
                <div className="successBox">
                    <h4 className="stepHeading">{props.message}</h4>
                </div>
            </Stack>

        </Stack>
    );
}

export default SuccessBox;