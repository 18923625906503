import * as React from 'react';
import { css, Stack, PrimaryButton, Text, getTheme, INavLinkGroup, Nav, IStackProps, INavLink, MessageBarType, Dialog, DialogType, Spinner, SpinnerSize, MessageBar, Link } from 'office-ui-fabric-react';
import StepWizard, { StepWizardChildProps, StepWizardProps } from 'react-step-wizard';
import './Proposal.scss';
import { StudyTeam } from './StepForm/StudyTeam';
import { StudyDescription } from './StepForm/StudyDescription';
import NavMenu from '../NavMenu';
import { StudyPower } from './StepForm/StudyPower';
import { StudyIntervensions } from './StepForm/StudyIntervensions';
import { MaterialsAndMethodes } from './StepForm/MaterialsAndMethodes';
import { RequestedSupport } from './StepForm/RequestedSupport';
import { ProjectPlan } from './StepForm/ProjectPlan';
import { IDataProvider } from '../../dataprovider/IDataProvider';
import { IProposal } from './Models/IProposal';
import { Attachments } from './StepForm/Attachments';
import { observer } from 'mobx-react';
import ProposalStore from './ProposalStore';
import { IApplicationSettings } from './Models/IApplicationSettings';
import { Summary } from './StepForm/Summary';
import SuccessBox from './StepForm/SuccessBox';
import { pdfCreator } from '../pdfGenerator/pdfCreator';
import { IUserAccount } from '../LoginComponent/LoginComponent';
import { IFileItem } from './Models/IFileItem';

export interface IProposalFormProps {
    dataProvider: IDataProvider;
    proposalStore: ProposalStore;
    location: Location;
    history: History;
    isLoggedIn: boolean;
    currentUser?: IUserAccount;

    sessionExpired: () => void;
}

export interface IProposalFormState {
    showWaitingSpinner: boolean;
    message?: string | JSX.Element | null;
    messageType?: MessageBarType;
    showOrderSuccessMessage?: boolean;
    stepWizardContext?: StepWizardProps | null;
    currentStep?: number;
    isLoggedIn: boolean;
    currentUser: IUserAccount;

    validationFirstErrorTab: number;
    validationErrorTabs: string[];
    validationErrorFields: any[];

    hasValidated: boolean;
}

export interface IValidationRule {
    name: string;
    display: string;
    error?: string;
    errorRequired?: string;
    errorFormat?: string;
    required: boolean | ((childRow?:any) => boolean);
    format?: RegExp;
    maxLength?: number;
    minValue?: number | Date | ((childRow?: any) => number) | ((childRow?: any) => Date);
    maxValue?: number | Date | ((childRow?: any) => number) | ((childRow?: any) => Date);
    childValidations?: IValidationRule[];
    onValidate?: (childRow?: any) => [boolean, boolean];
}

const transitions = {
    enterRight: `animated enterRight`,
    enterLeft: `animated enterLeft`,
    exitRight: `.animated exitRight`,
    exitLeft: `animated exitLeft`,
    intro: `animated intro`,
};

@observer
export class ProposalForm extends React.Component<IProposalFormProps, IProposalFormState> {

    constructor(props: IProposalFormProps) {
        super(props);
        console.log("User in ProposalForm: " + props.currentUser);
        this.state = {
            //message: "d",
            //showOrderSuccessMessage:true,
            showWaitingSpinner: false,
            stepWizardContext: null,
            currentStep: 1,
            isLoggedIn: props.isLoggedIn,
            currentUser: props.currentUser,

            validationErrorTabs: [],
            validationErrorFields: [],
            validationFirstErrorTab: null,
            hasValidated: false
        }

    }

    public componentWillReceiveProps(newProps: any) {
        if (this.state.isLoggedIn != newProps.isLoggedIn) {
            this.setState({ isLoggedIn: newProps.isLoggedIn });
        }

        if (this.state.currentUser != newProps.currentUser) {
            this.setState({ currentUser: newProps.currentUser });
        }
    }

    public componentDidMount() {
        try {
            this.props.dataProvider.getApplicationSettings().then((applicationSettings: IApplicationSettings) => {
                //console.log(applicationSettings);
                this.props.proposalStore.typeOfStudyList = applicationSettings.typeOfStudyList;
                this.props.proposalStore.studyDesignAList = applicationSettings.studyDesignAList;
                this.props.proposalStore.studyDesignBList = applicationSettings.studyDesignBList;
                this.props.proposalStore.studyDesignCList = applicationSettings.studyDesignCList;
                this.props.proposalStore.fieldsList = applicationSettings.fieldsList;
            });

            let query = new URLSearchParams((this.props as any).location.search);
            const proposalId = query.get("id")
            if (proposalId) {
                this.props.dataProvider.getProposal(proposalId).then((proposal: IProposal) => {
                    console.log(proposal);
                    this.props.proposalStore.proposal = proposal;
                    if (proposal.applicantEmail != this.state.currentUser.email) {
                        this.hideSpinnerAndShowSuccessMessage("You have no access to this proposal.");
                    } else if (proposal.status === "Submitted") {
                        this.hideSpinnerAndShowSuccessMessage("This proposal was submitted.");
                    }

                    this.props.dataProvider.getAttachments(proposalId).then((attachments: IFileItem[]) => {
                        this.props.proposalStore.isoCertificateAttachments = [...attachments.filter(x => x.docType === "GCP certificate")];

                        this.props.proposalStore.cvAttachments = [...attachments.filter(x => x.docType === "CV")];

                        this.props.proposalStore.otherAttachments = [...attachments.filter(x => x.docType === "Others")];
                    });
                }).catch((error) => {
                    if(error=="Unauthorized"){
                        this.hideSpinnerAndShowErrorMessage("You are unauthorized to access this proposal. You can only access your own proposals.", "_onClickSavePricingAppAdmin");
                    }else{
                        this.hideSpinnerAndShowErrorMessage(error, "_onClickSavePricingAppAdmin");
                    }
                });
            }

        } catch (error) {
            this.hideSpinnerAndShowErrorMessage(error, "_onClickSavePricingAppAdmin");
        }
    }

    private onStepChange = (stats: any) => {
        this.setState({
            currentStep: stats.activeStep
        });
    };

    private setInstance = (stepWizardContext: StepWizardProps) => this.setState({
        stepWizardContext,
    });

    private onSaveProposal = (isSubmit?: boolean) => {
        return new Promise((resolve,reject) => {
            if (this.props.proposalStore.proposal) {
                this.setState({
                    showWaitingSpinner: true
                })

                const proposal = { ...this.props.proposalStore.proposal };
                if (isSubmit === true)
                    proposal.status = "Submitted";

                proposal.userId = this.props.currentUser.userId;
                proposal.coInvestigators = proposal.coInvestigators?.filter(x => x.institute || x.firstname || x.lastname || x.email);
                proposal.subInvestigators = proposal.subInvestigators?.filter(x => x.institute || x.firstname || x.lastname || x.email);
                proposal.additionalStudyArms = proposal.additionalStudyArms?.filter(x => x.device || x.name || x.description);
                proposal.secondaryOutcomeParam = proposal.secondaryOutcomeParam?.filter(x => x.name || x.description || x.baseline || x.endpoint);
                if (proposal.funds?.costItems) {
                    if (proposal.funds?.costItems?.filter(x => x.name || x.budget).length > 0) {
                        proposal.funds.costItems = proposal.funds?.costItems?.filter(x => x.name || x.budget);
                    }
                }

                if (proposal.materialSupport?.filter(x => x.description || x.articelNo || x.quantity).length > 0) {
                    proposal.materialSupport = proposal.materialSupport?.filter(x => x.description || x.articelNo || x.quantity);
                }

                if (proposal.disc?.filter(x => x.type || x.dimention || x.quantity).length > 0) {
                    proposal.disc = proposal.disc?.filter(x => x.type || x.dimention || x.quantity);
                }

                if (proposal.projectTimeline?.timelines) {
                    if (proposal.projectTimeline?.timelines?.filter(x => x.no || x.milestone || x.startDate || x.endDate).length > 0) {
                        proposal.projectTimeline.timelines = proposal.projectTimeline?.timelines?.filter(x => x.no || x.milestone || x.startDate || x.endDate);
                    }

                }

                this.props.dataProvider.createProposal(proposal).then(async (result: any) => {
                    console.log(result);
                    let newItem = !proposal.id;
                    proposal.id = result.id;
                    proposal.proposalId = result.proposalID;

                    await this.props.dataProvider.uploadISOCertificate(proposal, [...this.props.proposalStore.isoCertificateAttachments]);
                    await this.props.dataProvider.uploadCV(proposal, [...this.props.proposalStore.cvAttachments]);
                    await this.props.dataProvider.uploadOthers(proposal, [...this.props.proposalStore.otherAttachments]);

                    if (isSubmit === true)
                    {
                        resolve(null);
                        //Your proposal was submitted successfully. A confirmation email will be sent to you shortly. If you do not receive this email, please click here to contact us.
                        this.hideSpinnerAndShowSuccessMessage("Your proposal was submitted successfully. A confirmation email will be sent to you shortly. If you do not receive this email, please contact us.");
                    }
                    else {
                        if (newItem) {
                            var url = this.props.dataProvider.addParam(`id=${result.id}`, window.location.href);
                            window.history.pushState(null, "", url);
                        }

                        this.props.proposalStore.proposal = proposal;

                        this.props.dataProvider.getAttachments(result.id).then((attachments: IFileItem[]) => {
                            this.props.proposalStore.isoCertificateAttachments = [...attachments.filter(x => x.docType === "GCP certificate")];

                            this.props.proposalStore.cvAttachments = [...attachments.filter(x => x.docType === "CV")];

                            this.props.proposalStore.otherAttachments = [...attachments.filter(x => x.docType === "Others")];

                            if (this.state.hasValidated) {
                                this.validateProposal();
                            }

                            resolve(null);
                        });

                        this.setState({
                            message: "Proposal saved successfully.",
                            messageType: MessageBarType.success,
                            showWaitingSpinner: false,
                            showOrderSuccessMessage: false,
                        });


                    }
                }).catch(error => {
                    this.hideSpinnerAndShowErrorMessage(error, "_onClickSavePricingAppAdmin");
                    if (error.indexOf("Authentication Token Expired") === 0) {
                        if (this.props.sessionExpired) {
                            this.props.sessionExpired();
                        }
                    }
                    reject(error);
                });
            }
        })

    }

    private hideSpinnerAndShowSuccessMessage = (message: string) => {
        this.setState({
            message: message,
            messageType: MessageBarType.success,
            showWaitingSpinner: false,
            showOrderSuccessMessage: true,
        });
    }

    private hideSpinnerAndShowErrorMessage = (error: any, errorAreaName: string) => {

        this.props.dataProvider.logErrorInConsole?.(errorAreaName, error);

        this.setState({
            message: this.props.dataProvider.extractErrorMessageFromErrorObject?.(error),
            messageType: MessageBarType.error,
            showWaitingSpinner: false,
            showOrderSuccessMessage: false,
        });
    }

    private validateField = (obj: any, fieldName: string, required: boolean, format?: RegExp, maxLength?: number, minValue?: number | Date | (() => number) | (() => Date), maxValue?: number | Date | (() => number) | (() => Date), childValidations?: IValidationRule[]): [boolean, boolean, any] => {
        let hasMinValue = minValue !== null && minValue !== undefined;
        let hasMaxValue = maxValue !== null && maxValue !== undefined;
        let hasMaxLength = maxLength !== null && maxLength !== undefined;
        if (format && (hasMinValue || hasMaxValue)){
            alert("Bad validation rule defined. Format and min/maxValue can't both be used. Use min/maxValue only for numbers and format only for strings.");
        }
        var requiredValid = true;
        var childrenRequiredValid = true;
        var childValidationErrors: any = null;
        
        let fieldContainsData = false;
        if (obj.hasOwnProperty(fieldName)) {
            if (Array.isArray(obj[fieldName])) {
                fieldContainsData = obj[fieldName].length !== 0;

                // if there are child-validation rules, consider them also
                if (childValidations && childValidations.length) {
                    childValidationErrors = {};
                    obj[fieldName].forEach((childRow: any, childRowIdx: number) => {
                        childValidations.forEach((childRule, ruleIdx) => {
                            console.log(`Validating ChildRow ${childRowIdx}`);
                            let childFieldRequired = typeof childRule.required === 'function' ? childRule.required(childRow) : childRule.required;
                            let childMaxValue = typeof childRule.maxValue === 'function' ? childRule.maxValue(childRow) : childRule.maxValue;
                            let [requiredIsValid, formatIsValid]: [boolean, boolean, any] = this.validateField(childRow, childRule.name, childFieldRequired, childRule.format, childRule.maxLength, childRule.minValue, childMaxValue);
                            childrenRequiredValid = childrenRequiredValid && requiredIsValid;
                            fieldContainsData = requiredIsValid && formatIsValid && fieldContainsData;

                            if (!requiredIsValid) {
                                childValidationErrors[`${fieldName}_${childRule.name}_${childRowIdx}`] = { ...childRule, error: childRule.errorRequired, rowIdx: childRowIdx };
                            } else if (!formatIsValid) {
                                childValidationErrors[`${fieldName}_${childRule.name}_${childRowIdx}`] = { ...childRule, error: childRule.errorFormat, rowIdx: childRowIdx };
                            }
                        });
                    });
                }
            } else if (typeof obj[fieldName] === 'number') {
                fieldContainsData = obj[fieldName] !== null && obj[fieldName] !== undefined;
            } else if (typeof obj[fieldName] === 'object' && obj[fieldName] && obj[fieldName].getDate) {
                // handle date validation

                // if field value was correctly identified as Date, it has a value
                fieldContainsData = true;
            } else{
                fieldContainsData = Boolean(obj[fieldName]?.trim().length);
            }
        }
        
        requiredValid = (required && fieldContainsData && childrenRequiredValid) || (!required && childrenRequiredValid);
        

        var formatValid = true;
        if (format && fieldContainsData) {
            let matchResults = obj[fieldName].match(format);
            formatValid = Boolean(matchResults) && Boolean(matchResults.length);
        } else if ((hasMinValue || hasMaxValue) && fieldContainsData) {
            if (hasMinValue) {
                let actualMinValue = typeof minValue === 'function' ? minValue() : minValue;
                formatValid = formatValid && (obj[fieldName] >= actualMinValue);
            }
            if (hasMaxValue) {
                let actualMaxValue = typeof maxValue === 'function' ? maxValue() : maxValue;
                formatValid = formatValid && (obj[fieldName] <= actualMaxValue);
            }
        } else if ((hasMaxLength && fieldContainsData)) {
            formatValid = formatValid && (obj[fieldName].length <= maxLength);
        }
        return [requiredValid, formatValid, childValidationErrors];
    };

    private validateProposal = (): boolean => {
        let errorTabs: string[] = [];
        let tabNames = ['Study Team', 'Study Description', 'Study Power', 'Study Interventions', 'Materials and Methods', 'Requested Support', 'Project Plan', 'Attachment', 'Summary'];
        let tabErrors = [false, false, false, false, false, false, false, false];

        this.setState({ hasValidated: true });

        // initialize field error array freshly
        let fieldValidationErrors: any[] = [];
        for (var i = 0; i < tabNames.length; i++) {
            fieldValidationErrors.push({});
        }

        let nGroupRequiredValidation = () => {
            return !Boolean(this.props.proposalStore.proposal.studyDesign?.groupB == "Single-arm");
        };
       
        let validationRules: IValidationRule[][] = [
            [ // Tab0 StudyTeam
                { name: "piName", display: 'Primary Investigator Name', errorRequired: "Field is required", required: true, maxLength: 255, errorFormat: "max 255 characters" },
                { name: "contactDetailsPI.email", display: 'Primary Investigator Email', required: true, format: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g, errorRequired: "Field is required", errorFormat: "Email Address is invalid" },
                { name: "contactDetailsPI.phone", display: 'Primary Investigator Phone', errorRequired: "Field is required", required: true },
                { name: "contactDetailsPI.street", display: 'Primary Investigator Street', errorRequired: "Field is required", required: true },
                { name: "contactDetailsPI.no", display: 'Primary Investigator Street no', errorRequired: "Field is required", required: true },
                { name: "contactDetailsPI.postalCode", display: 'Primary Investigator Postal Code', errorRequired: "Field is required", required: true },
                { name: "contactDetailsPI.city", display: 'Primary Investigator City', errorRequired: "Field is required", required: true },
                { name: "contactDetailsPI.country", display: 'Primary Investigator Country', errorRequired: "Field is required", required: true }

            ],
            [ // Tab1 StudyDescription
                { name: "studyTitle", display: 'Study Title', errorRequired: "Field is required", required: true, maxLength: 255, errorFormat: "max 255 characters" },
                { name: "summary", display: 'Summary', errorRequired: "Field is required", required: false, maxLength: 1000, errorFormat: "max 1000 characters" },
                { name: "backgroundRatione", display: 'Background and rationale', errorRequired: "Field is required", required: true, maxLength: 2000, errorFormat: "max 2000 characters" },
                { name: "studyHypothesis", display: 'Primary study hypothesis', errorRequired: "Field is required", required: true },
                { name: "typeOfStudy", display: 'Type of study', errorRequired: "Field is required", required: true, },
                { name: "typeOfStudyOther", display: 'Type of study - Other', errorRequired: "Field is required if Type of Study is 'Other'", required: () => { return this.props.proposalStore.proposal.typeOfStudy === 'Other'; } },
                { name: "fields", display: 'Fields', errorRequired: "At least 1 field must be selected", required: true },
                { name: "fieldOthers", display: 'Fields - Others', errorRequired: "Field is required if 'Other' was chosen", required: () => { return (this.props.proposalStore?.proposal?.fields as string[])?.includes('Others'); } }
            ],
            [ // Tab2 StudyPower
                { name: "sampleSize.nTotal", display: 'N total', errorRequired: "Field is required", errorFormat: "'N total' must be larger than 0", required: true, minValue: 1 },
                {
                    name: "sampleSize.nPerGroup", display: 'N per group', errorRequired: "Field is required", required: nGroupRequiredValidation, errorFormat: "'N per group' must be larger than 0 and smaller than or equal to 'N total'", minValue: () => {
                        let isRequired = nGroupRequiredValidation();
                        return isRequired ? 1 : 0;
                    }, maxValue: () => { return this.props.proposalStore.proposal?.sampleSize?.nTotal; }
                },
                { name: "sampleSize.anticipatedDropOutRate", display: 'Anticipated drop-out rate', errorRequired: "Field is required", required: true, errorFormat: "Valid percentage values must be between 0 and 100", minValue: 0, maxValue: 100 },
                { name: "sampleSize.powerAnalysis", display: 'Adequate power analysis and justification', errorRequired: "Field is required", required: true },
            ],
            [ // Tab3 StudyIntervensions
                { // studyIntTestArm.device
                    name: "studyIntTestArm.device", display: 'Study Intervention - Test Arm - Study Device', required: () => {
                        return true;//Boolean(this.props.proposalStore?.proposal?.studyIntTestArm?.name) || Boolean(this.props.proposalStore?.proposal?.studyIntTestArm?.description);
                    }, errorRequired: "Field is required if any of the other 'Test Arm' fields are filled out"
                },
                {
                    name: "studyIntTestArm.name", display: 'Study Intervention - Test Arm - Test Treatment Name', required: () => {
                        return true;// Boolean(this.props.proposalStore?.proposal?.studyIntTestArm?.device) || Boolean(this.props.proposalStore?.proposal?.studyIntTestArm?.description);
                    }, errorRequired: "Field is required if any of the other 'Test Arm' fields are filled out"
                },
                {
                    name: "studyIntTestArm.description", display: 'Study Intervention - Test Arm - Test Treatment Description', required: () => {
                        return true; // Boolean(this.props.proposalStore?.proposal?.studyIntTestArm?.name) || Boolean(this.props.proposalStore?.proposal?.studyIntTestArm?.device);
                    }, errorRequired: "Field is required if any of the other 'Test Arm' fields are filled out"
                },
                { // controlIntControlArm.device
                    name: "controlIntControlArm.device", display: 'Control Intervention - Control Arm - Study Device', required: () => {
                        return Boolean(this.props.proposalStore?.proposal?.controlIntControlArm?.name) || Boolean(this.props.proposalStore?.proposal?.controlIntControlArm?.description);
                    }, errorRequired: "Field is required if any of the other 'Control Arm' fields are filled out"
                },
                {
                    name: "controlIntControlArm.name", display: 'Control Intervention - Control Arm - Test Treatment Name', required: () => {
                        return Boolean(this.props.proposalStore?.proposal?.controlIntControlArm?.device) || Boolean(this.props.proposalStore?.proposal?.controlIntControlArm?.description);
                    }, errorRequired: "Field is required if any of the other 'Control Arm' fields are filled out"
                },
                {
                    name: "controlIntControlArm.description", display: 'Control Intervention - Control Arm - Test Treatment Description', required: () => {
                        return Boolean(this.props.proposalStore?.proposal?.controlIntControlArm?.name) || Boolean(this.props.proposalStore?.proposal?.controlIntControlArm?.device);
                    }, errorRequired: "Field is required if any of the other 'Control Arm' fields are filled out"
                },
                {
                    name: "additionalStudyArms", display: 'Additional Study arms', required: false, childValidations: [
                        {
                            name: "device", display: 'Study Device', required: (childRow?:any) => {
                                return Boolean(childRow.name) || Boolean(childRow.description);
                            }, errorRequired: "Field is required if any of the other field in this 'Additional Study arm' block is filled out"
                        },
                        {
                            name: "name", display: 'Test Treatment name', required: (childRow?: any) => {
                                return Boolean(childRow.device) || Boolean(childRow.description);
                            }, errorRequired: "Field is required if any of the other field in this 'Additional Study arm' block is filled out"
                        },
                        {
                            name: "description", display: 'Test Treatment description', required: (childRow?: any) => {
                                return Boolean(childRow.device) || Boolean(childRow.name);
                            }, errorRequired: "Field is required if any of the other field in this 'Additional Study arm' block is filled out"
                        }
                    ]
                }
            ],
            [ // Tab4 MaterialsAndMethodes
                { name: "materialMethode.populationIndication", display: 'Material and Methods - population and indication', errorRequired: "Field is required", required: () => { return (this.props.proposalStore?.proposal?.typeOfStudy == "Animal" || this.props.proposalStore?.proposal?.typeOfStudy == "Clinical");}},
                { name: "materialMethode.treatments", display: 'Material and Methods - treatments', errorRequired: "Field is required", required: () => { return (this.props.proposalStore?.proposal?.typeOfStudy == "Animal" || this.props.proposalStore?.proposal?.typeOfStudy == "Clinical");}},
                { name: "materialMethode.experimentalProcedures", display: 'Material and Methods - experimental procedures', errorRequired: "Field is required", required: () => { return (this.props.proposalStore?.proposal?.typeOfStudy == "Animal" || this.props.proposalStore?.proposal?.typeOfStudy == "Clinical");}},
                { name: "materialMethode.materialExperimentalSetup", display: 'Material and Methods - material and experimental setup', errorRequired: "Field is required", required: () => { return !(this.props.proposalStore?.proposal?.typeOfStudy == "Animal" || this.props.proposalStore?.proposal?.typeOfStudy == "Clinical");}},
                { name: "statisticalMethods", display: 'Statistical methods', errorRequired: "Field is required", required: true },
                { name: "primaryOutcomeParam.name", display: 'Primary outcome parameter - Name', errorRequired: "Field is required", required: true },
                { name: "primaryOutcomeParam.description", display: 'Primary outcome parameter - Description', errorRequired: "Field is required", required: true },
                { name: "primaryOutcomeParam.baseline", display: 'Primary outcome parameter - Baseline', errorRequired: "Field is required", required: true },
                { name: "primaryOutcomeParam.endpoint", display: 'Primary outcome parameter - Endpoint', errorRequired: "Field is required", required: true },
                {
                    name: "secondaryOutcomeParam", display: 'Secondary outcome parameters', required: false, childValidations: [
                        {
                            name: "name", display: 'Name', required: (childRow?: any) => {
                                return Boolean(childRow.description) || Boolean(childRow.baseline) || Boolean(childRow.endpoint);
                            }, errorRequired: "Field is required if any of the other field in this 'Secondary outcome parameters' block is filled out"
                        },
                        {
                            name: "description", display: 'Description', required: (childRow?: any) => {
                                return Boolean(childRow.name) || Boolean(childRow.baseline) || Boolean(childRow.endpoint);
                            }, errorRequired: "Field is required if any of the other field in this 'Secondary outcome parameters' block is filled out"
                        },
                        {
                            name: "baseline", display: 'Baseline', required: (childRow?: any) => {
                                return Boolean(childRow.description) || Boolean(childRow.name) || Boolean(childRow.endpoint);
                            }, errorRequired: "Field is required if any of the other field in this 'Secondary outcome parameters' block is filled out"
                        },
                        {
                            name: "endpoint", display: 'Endpoint', required: (childRow?: any) => {
                                return Boolean(childRow.description) || Boolean(childRow.baseline) || Boolean(childRow.name);
                            }, errorRequired: "Field is required if any of the other field in this 'Secondary outcome parameters' block is filled out"
                        }
                    ]
                }

            ],
            [ // Tab5 Requested Support
                { name: "funds.currency", display: 'Funds - Currency', errorRequired: "Choose a currency", required: () => { return this.props.proposalStore?.proposal?.fundsRequested; }},
                { name: "funds.overheadCosts.percent", display: 'Funds - Percent', errorRequired: "Choose a currency", required: false, maxValue: 100, minValue: 0, errorFormat: 'Quantity must be between 0 and 100' },
                /*{
                    name: "funds.overheadCosts", display: 'Funds - Percent', required: false, childValidations: [
                        { name: "percent", display: 'Funds - Percent', errorRequired: "Choose a currency", required: false, maxValue: 100, minValue: 0, errorFormat: 'Quantity must be between 0 and 100' },
                    ]
                },*/
                {
                    name: "funds.costItems", display: 'Funds - Cost type / item', errorRequired: "If funds are requested, at least 1 cost type/item is required", required: () => { return this.props.proposalStore?.proposal?.fundsRequested; }, childValidations:[
                        { name: "name", display: 'Name', errorRequired: "Field is required", required: () => { return this.props.proposalStore?.proposal?.fundsRequested; } },
                        { name: "budget", display: 'Requested funds', errorRequired: "Field is required", required: () => { return this.props.proposalStore?.proposal?.fundsRequested; }, minValue: 0, errorFormat: "Requested funds must be positive values" }
                    ]
                },
                {
                    name: "materialSupport", display: 'Study Material', errorRequired: "If Study Material is requested, add at least 1 Material or delselect 'Study Material requested'", required: () => { return this.props.proposalStore?.proposal?.materialSupportRequested; }, childValidations: [
                        { name: "description", display: 'Article description', errorRequired: "Field is required", required: () => { return this.props.proposalStore?.proposal?.materialSupportRequested; } },
                        { name: "quantity", display: 'Quantity', errorRequired: "Field is required", required: () => { return this.props.proposalStore?.proposal?.materialSupportRequested; }, minValue: 0, errorFormat:'Quantity must be a positive amount' }
                    ]
                }
            ],
            [ // Tab6 Project Plan                
                {
                    name: "projectTimeline.timelines", display: 'Project timetable', required: true, childValidations: [
                        { name: "no", display: 'No of milestone', required: (childRow?: any) => { return Boolean(childRow.milestone?.trim()) || Boolean(childRow.startDate) || Boolean(childRow.endDate); }, errorRequired: 'Field is required' },
                        { name: "milestone", display: 'No of milestone', required: (childRow?: any) => { return Boolean(childRow.no?.trim()) || Boolean(childRow.startDate) || Boolean(childRow.endDate); }, errorRequired: 'Field is required' },
                        {
                            name: "startDate", display: 'Start date', required: (childRow?: any) => { return Boolean(childRow.milestone?.trim()) || Boolean(childRow.no) || Boolean(childRow.endDate); },
                            errorRequired: 'Field is required',
                            maxValue: (childRow?: any) => { return childRow.endDate; },
                            errorFormat: 'Start date must be before End Date'
                        },
                        { name: "endDate", display: 'End date', required: (childRow?: any) => { return Boolean(childRow.milestone?.trim()) || Boolean(childRow.startDate) || Boolean(childRow.no); }, errorRequired: 'Field is required' }
                    ]
                }
            ],
            [ // Tab7 Attachment
                {
                    name: "isoCertificateAttachments", display: 'ISO Certificate', required: () => { return this.props.proposalStore?.proposal?.typeOfStudy == "Clinical" }, errorRequired: "For clinical studies, the ISO Certificate must be uploaded", onValidate: () => {
                        let isRequiredValid: boolean = this.props?.proposalStore?.isoCertificateAttachments && Boolean(this.props?.proposalStore?.isoCertificateAttachments.length);
                        return [isRequiredValid, true];
                    }
                },
                {
                    name: "cvAttachments", display: 'CV', required: true, errorRequired: "CV upload is required", onValidate: () => {
                        let isRequiredValid: boolean = this.props?.proposalStore?.cvAttachments && Boolean(this.props?.proposalStore?.cvAttachments.length);
                        return [isRequiredValid, true];
                    }
                }
            ]

        ];

        let hasErrors = false;
        let proposal: any = this.props.proposalStore?.proposal;
        if (proposal) {
            validationRules.forEach((tab, tabIdx) => {
                tab.forEach((field, fieldIdx) => {

                    let [requiredIsValid, formatIsValid, childValidationErrors]: [boolean, boolean, any] = [false, false, undefined];
                    let required: boolean = typeof field.required === 'function' ? field.required() : field.required;
                    var fieldStillValid: boolean = true;

                    if (field.onValidate) {
                        [requiredIsValid, formatIsValid] = field.onValidate();
                        fieldStillValid = (required ? requiredIsValid : true) && formatIsValid;
                    } else {
                        let fieldHierarchies = field.name.split(".");
                        var model: any = null;
                        for (var i = 0; i < fieldHierarchies.length; i++) {

                            if (i === 0) {
                                model = proposal;
                            } else {
                                model = model[fieldHierarchies[i - 1]];
                            }
                            if (!model) {
                                // stop iterating, no more data under this level
                                fieldStillValid = false;

                                // if field is NOT Required => mark it as valid even if the model hierarchy is missing

                                if (!required) {
                                    fieldStillValid = true;
                                }
                                break;
                            }

                            // if we arrive at bottom of hierarchy, validate field
                            if (i === fieldHierarchies.length - 1) {
                                [requiredIsValid, formatIsValid, childValidationErrors] = this.validateField(model, fieldHierarchies[i], required, field.format, field.maxLength, field.minValue, field.maxValue, field.childValidations);
                                fieldStillValid = requiredIsValid && formatIsValid;
                            }
                        }
                    }                    

                    if (!fieldStillValid) {
                        // add tabError
                        tabErrors[tabIdx] = true;

                        // add field error
                        if (!requiredIsValid) {
                            fieldValidationErrors[tabIdx][field.name] = { ...field, error: field.errorRequired };                            
                        } else {
                            fieldValidationErrors[tabIdx][field.name] = { ...field, error: field.errorFormat };
                        }

                        if (childValidationErrors) {
                            fieldValidationErrors[tabIdx][field.name].childErrors = childValidationErrors;
                        }

                        hasErrors = true;
                    }
                });
            });
        }

        let firstErrorTab: number = null;
        tabErrors.forEach((error, idx) => {
            if (error) {
                if (!firstErrorTab) {
                    firstErrorTab = idx + 1;  // StepWizard tabs are 1-based
                }
                errorTabs.push(tabNames[idx]);
            }
        });

        this.props.proposalStore.proposal.errorTabs = hasErrors ? errorTabs : [];
        this.props.proposalStore.proposal.fieldErrors = hasErrors ? [...fieldValidationErrors] : null;
        this.props.proposalStore.proposal.firstErrorTab = firstErrorTab;

        return hasErrors;
    };

    private onSubmit = (goToStep: (step: any) => {}) => () => {
        // TODO 
        if (!this.validateProposal()) {
            pdfCreator.createFile({ ...this.props.proposalStore.proposal }, true).then((file) => {
                this.props.proposalStore.proposal.pdfFileAsBase64 = file;
                this.onSaveProposal(true);
            })

        } else {
            // jump to first step with errors
            //const { validationFirstErrorTab } = this.state;
            this.setState({ currentStep: this.props.proposalStore.proposal.firstErrorTab });
            goToStep(this.props.proposalStore.proposal.firstErrorTab);
            window.setTimeout(function () { window.scrollTo(0, 0); }, 100);
        }
    };

    public render() {
        const { location, history } = this.props;

        //let navControl = <NavMenu key={this.props.proposalStore.proposal.errorTabs.join('--')} errors={this.props.proposalStore?.proposal?.errorTabs} />;

        return (
            <Stack className={ css("fullWidth", "proposalContainer") } verticalFill={true} tokens={{ childrenGap: 20 }}>
                {
                    !this.state.showOrderSuccessMessage &&
                    <>
                        {
                            this.state.message ?
                                <MessageBar
                                    onDismiss={() => {
                                        this.setState({
                                            message: null
                                        });
                                    }}
                                    messageBarType={this.state.messageType}
                                    isMultiline={true}
                                    truncated={true}
                                >
                                    {this.state.message}
                                </MessageBar>
                                : null
                        }
                        <Stack className={css("fullWidth", "stepWizardContainer") }>
                            <StepWizard
                                key={this.props.proposalStore?.proposal?.errorTabs?.join('-')}
                                className="stepWizard"
                                onStepChange={this.onStepChange}
                                isHashEnabled={false}
                                isLazyMount={true}
                                transitions={transitions}
                                instance={this.setInstance}
                                initialStep={this.state.currentStep}
                                nav={<NavMenu errors={this.props.proposalStore?.proposal?.errorTabs} proposal={this.props.proposalStore.proposal} />}
                            >
                                <StudyTeam fieldErrors={this.state.validationErrorFields} currentUser={this.state.currentUser} currentStep={1} proposal={this.props.proposalStore.proposal}
                                    showWizardNavigation={true} onSave={() => this.onSaveProposal()} ></StudyTeam>
                                <StudyDescription
                                    currentStep={2}
                                    proposal={this.props.proposalStore.proposal}
                                    typeOfStudyList={this.props.proposalStore.typeOfStudyList}
                                    studyDesignAList={this.props.proposalStore.studyDesignAList}
                                    studyDesignBList={this.props.proposalStore.studyDesignBList}
                                    studyDesignCList={this.props.proposalStore.studyDesignCList}
                                    fieldsList={this.props.proposalStore.fieldsList}
                                    showWizardNavigation={true} onSave={() => this.onSaveProposal()}></StudyDescription>
                                <StudyPower currentStep={3} proposal={this.props.proposalStore.proposal}
                                    showWizardNavigation={true} onSave={() => this.onSaveProposal()}></StudyPower>
                                <StudyIntervensions currentStep={4} proposal={this.props.proposalStore.proposal}
                                    showWizardNavigation={true} onSave={() => this.onSaveProposal()}></StudyIntervensions>
                                <MaterialsAndMethodes currentStep={5} proposal={this.props.proposalStore.proposal}
                                    showWizardNavigation={true} onSave={() => this.onSaveProposal()}></MaterialsAndMethodes>
                                <RequestedSupport currentStep={6} proposal={this.props.proposalStore.proposal} proposalStore={this.props.proposalStore}
                                    showWizardNavigation={true} onSave={() => this.onSaveProposal()}></RequestedSupport>
                                <ProjectPlan currentStep={7} proposal={this.props.proposalStore.proposal}
                                    typeOfStudyList={this.props.proposalStore.typeOfStudyList}
                                    showWizardNavigation={true} onSave={() => this.onSaveProposal()}></ProjectPlan>
                                <Attachments currentStep={8} proposal={this.props.proposalStore.proposal} proposalStore={this.props.proposalStore}
                                    showWizardNavigation={true} onSave={() => this.onSaveProposal()}></Attachments>
                                <Summary currentStep={9} proposal={this.props.proposalStore.proposal} proposalStore={this.props.proposalStore}
                                    showWizardNavigation={true} onSave={() => this.onSaveProposal()}
                                    onSubmit={this.onSubmit}></Summary>

                            </StepWizard>
                        </Stack>
                    </>
                }
                <Dialog
                    hidden={!this.state.showWaitingSpinner}
                    dialogContentProps={{
                        type: DialogType.normal,
                    }}
                    modalProps={{ isBlocking: true, className: "busyOverlay" }}
                >
                    <Spinner size={SpinnerSize.large} label="Working on it..." ariaLive="assertive" />
                </Dialog>

                {
                    this.state.showOrderSuccessMessage &&
                    <SuccessBox location={location} history={history} message={String(this.state.message)} stepSizeClass="subscriptionContainerInnerWidth50"></SuccessBox>
                }
            </Stack>
        );

    }
}