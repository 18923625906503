import { IDataProvider } from "./IDataProvider";
import HttpService from "./HttpService";
import { IProposal } from "../components/Proposal/Models/IProposal";
import { IApplicationSettings } from "../components/Proposal/Models/IApplicationSettings";
import { IAuthenticatedUser, IUserAccount } from "../components/LoginComponent/LoginComponent";
import { Common } from "../components/LoginComponent/Common";
import { IFileItem } from "../components/Proposal/Models/IFileItem";

export default class DataProvider implements IDataProvider {

    public apiEndpointSP = `/api/SPInterface`;
    public userAccountEndpointSP = `/api/UserAccountSPInterface`;

    public async getApplicationSettings(): Promise<IApplicationSettings> {
        const applicationSettings: IApplicationSettings = await HttpService.get(`${this.apiEndpointSP}/ApplicationSettings`);
        //console.log(applicationSettings);
        return applicationSettings;
    }

    public async getProposal(proposalId: string): Promise<IProposal> {       
        return HttpService.get(`${this.apiEndpointSP}/Proposal/${encodeURIComponent(proposalId)}`).then((proposal) => {

            proposal.projectTimeline?.timelines?.map((x:any) => {
                x.startDate = x.startDate ? new Date(x.startDate.toString()) : undefined;
                x.endDate = x.endDate ? new Date(x.endDate.toString()) : undefined;
            });
            proposal.created = proposal.created ? new Date(proposal.created.toString()) : undefined;
            proposal.modified = proposal.modified ? new Date(proposal.modified.toString()) : undefined;

            return proposal;
        });
    }

    public async getAttachments(proposalId: string): Promise<IFileItem[]> {
        const attachments: IFileItem[] = await HttpService.get(`${this.apiEndpointSP}/Proposal/Attachments/${encodeURIComponent(proposalId)}`);

        return attachments;
    }

    public async createProposal(proposal: IProposal): Promise<any> {

        const response = await HttpService.post(`${this.apiEndpointSP}/CreateProposal`, proposal);
        return response;
    }

    public async deleteProposal(id: number): Promise<boolean> {
        return HttpService.post(`${this.apiEndpointSP}/DeleteProposal`, id).then(() => {
            return true;
        }).catch(() => { return false; });
    }

    public getAllProposals(userId: string): Promise<any> {
        try {
            console.log("getAllProposals: " + userId);
            return HttpService.post(`${this.apiEndpointSP}/Proposals`, userId);
        } catch (ex) {
            return Promise.reject((<any>ex).message);
        }
    }

    public async uploadISOCertificate(proposal: IProposal, files: Array<IFileItem | File>): Promise<any> {
        var formData = new FormData();
        formData.append(`id`, proposal.id.toString());
        formData.append(`proposalId`, proposal.proposalId);

        let fileNeedToDelete: string[] = [];
        files.forEach((file, index) => {
            if (file instanceof File)
                formData.append(`file${index}`, file);
            else if (file.isDeleted)
                fileNeedToDelete.push(file.uniqueId);
        });
        formData.append(`filesToDelete`, JSON.stringify(fileNeedToDelete));

        let authToken = window.sessionStorage.authToken ? JSON.parse(window.sessionStorage.authToken) : null;
        let response = await fetch(`${this.apiEndpointSP}/UploadISOCertificate`, {
            method: 'POST',
            body: formData,
            headers: {
                'Authorization': authToken ? `Bearer ${authToken.token}` : ""
            }
        });

        if (response.ok)
            return response.json();
        else if (response.status == 401) {
            throw "Upload ISO Certificate Error: Unauthorized. Please sign out and sign back in and re-try.";
        }
        else
            throw await response.json();
    }

    public async uploadCV(proposal: IProposal, files: Array<IFileItem | File>): Promise<any> {

        var formData = new FormData();
        formData.append(`id`, proposal.id.toString());
        formData.append(`proposalId`, proposal.proposalId);

        let fileNeedToDelete: string[] = [];
        files.map((file, index) => {
            if (file instanceof File)
                formData.append(`file${index}`, file);
            else if (file.isDeleted)
                fileNeedToDelete.push(file.uniqueId);
        });
        formData.append(`filesToDelete`, JSON.stringify(fileNeedToDelete));

        let authToken = window.sessionStorage.authToken ? JSON.parse(window.sessionStorage.authToken) : null;
        let response = await fetch(`${this.apiEndpointSP}/UploadCV`, {
            method: 'POST',
            body: formData,
            headers: {
                'Authorization': authToken ? `Bearer ${authToken.token}` : ""
            }
        });

        if (response.ok)
            return response.json();
        else if (response.status == 401) {
            throw "Upload CV Error: Unauthorized. Please sign out and sign back in and re-try.";
        }
        else
            throw await response.json();
    }

    public async uploadOthers(proposal: IProposal, files: Array<IFileItem | File>): Promise<any> {
        var formData = new FormData();
        formData.append(`id`, proposal.id.toString());
        formData.append(`proposalId`, proposal.proposalId);
        let fileNeedToDelete: string[] = [];
        files.map((file, index) => {
            if (file instanceof File)
                formData.append(`file${index}`, file);
            else if (file.isDeleted)
                fileNeedToDelete.push(file.uniqueId);
        });
        formData.append(`filesToDelete`, JSON.stringify(fileNeedToDelete));

        let authToken = window.sessionStorage.authToken ? JSON.parse(window.sessionStorage.authToken) : null;
        let response = await fetch(`${this.apiEndpointSP}/UploadOthers`, {
            method: 'POST',
            body: formData,
            headers: {
                'Authorization': authToken ? `Bearer ${authToken.token}` : ""
            }
        });

        if (response.ok)
            return response.json();
        else if (response.status == 401) {
            throw "Upload Other Documents: Unauthorized. Please sign out and sign back in and re-try.";
        }
        else
            throw await response.json();
    }

    public logErrorInConsole(errorArea: string, errorObj: any) {
        this.logError(errorObj.message);
        console.log(`Error message ${errorArea}: `, errorObj.message);
        console.log(`Error Stack Trace ${errorArea}: `, errorObj.stack);
    }

    private logError(msg: any) {
        if (console) {
            console.log(`%cERROR:`, "color:red;font-weight:bold;font-size:16px");
            console.log(msg);
        }
    }

    public extractErrorMessageFromErrorObject(error: any): string {

        let errorMessage = error.message ? error.message as string :( error.title ? error.title as string : error.toString() );
        console.dir(error);
        if (errorMessage  &&  errorMessage.indexOf("::>") >= 0) {
            try {
                let errorObj = JSON.parse(errorMessage.substring(errorMessage.indexOf("::>") + 3).trim());

                if (errorObj["odata.error"] && errorObj["odata.error"].message && errorObj["odata.error"].message.value)
                    errorMessage = errorObj["odata.error"].message.value;

            } catch (error) {
                errorMessage = errorMessage.substring(errorMessage.indexOf("::>") + 3).trim();
            }

        }

        return errorMessage;
    }

    public addParam(param: string, sourceURL: string): string {
        var rtn = sourceURL.split("?")[0],
            params_arr = [],
            queryString = (sourceURL.indexOf("?") !== -1) ? sourceURL.split("?")[1] : "";

        if (queryString !== "") {
            params_arr = queryString.split("&");
            params_arr.push(param);
            if (params_arr.length > 0)
                rtn = rtn + "?" + params_arr.join("&");
        }
        else {
            rtn = rtn + "?" + param;
        }
        return rtn;
    }

    public ensureExternalUserAccount(user: IUserAccount, token: string, isRSMToken: boolean): Promise<IAuthenticatedUser> {
        return HttpService.post(`${this.userAccountEndpointSP}/EnsureExternalUserAccounts`, { "Account": user, "Token": token, "IsRSMToken": isRSMToken });
    }
    public registerInternalUserAccount(user: IUserAccount, reCaptchaToken: string): Promise<boolean> {
        return HttpService.post(`${this.userAccountEndpointSP}/RegisterUserAccount`, { "Account": user, "BaseUrl": Common.getCurrentServerUrl(), "reCaptchaToken": reCaptchaToken });
    }
    public loginInternalUserAccount(user: IUserAccount, reCaptchaToken: string): Promise<IAuthenticatedUser> {
        return HttpService.post(`${this.userAccountEndpointSP}/CustomUserLogin`, { "Account": user, "reCaptchaToken": reCaptchaToken});
    }
    public getUserByActivateParam(activationGuid: string, a: boolean): Promise<boolean> {
        return HttpService.post(`${this.userAccountEndpointSP}/GetUserByActivateParam`, { "activateParameter": activationGuid, "activate": a });
    }
    public resetPWByActivateParam(activationGuid: string, pw: string): Promise<boolean> {
        return HttpService.post(`${this.userAccountEndpointSP}/ResetPWByActivateParam`, { "activateParameter": activationGuid, "password": pw });
    }
    public getNewActivationCode(email: string, baseUrl: string): Promise<string> {
        return HttpService.post(`${this.userAccountEndpointSP}/GetActivationCode`, { "email": email, "baseUrl": baseUrl });
    }
    public resendActivationCode(email: string, baseUrl: string): Promise<string> {
        return HttpService.post(`${this.userAccountEndpointSP}/ResendActivationCode`, { "email": email, "baseUrl": baseUrl });
    }
    public documentUpload(Id: string, base64File: string, applicantName:string): Promise<string> {
        return HttpService.post(`${this.apiEndpointSP}/UploadFileByBase64`, { "id": Id, "base64File": base64File, "applicantName": applicantName  });
    }
}