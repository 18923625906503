import { action, makeObservable, observable, configure } from "mobx";
import { IFileItem } from "./Models/IFileItem";
import { IProposal } from './Models/IProposal';

export default class ProposalStore {

    constructor() {
        makeObservable(this);

        configure({enforceActions: "never"});
    }

    @observable public proposal: IProposal = { status: "Draft", fieldErrors: null, errorTabs: [], userId:null };

    @observable public typeOfStudyList: string[] = [];

    @observable public studyDesignAList: string[] = [];
    @observable public studyDesignBList: string[] = [];
    @observable public studyDesignCList: string[] = [];

    @observable public fieldsList: string[] = [];    

    @observable public isoCertificateAttachments: Array<IFileItem | File> = [];
    @observable public cvAttachments: Array<IFileItem | File> = [];
    @observable public otherAttachments: Array<IFileItem | File> = [];

    @action public calculateFunds() {
        if (this.proposal.funds?.costItems && this.proposal.funds?.costItems?.length > 0) {
            let percent = 0;

            if (!this.proposal.funds)
                this.proposal.funds = {};

            if (!this.proposal.funds.overheadCosts)
                this.proposal.funds.overheadCosts = {};            

            if (this.proposal.funds?.overheadCosts?.percent !== null && this.proposal.funds?.overheadCosts?.percent !== undefined) {
                percent = this.proposal.funds?.overheadCosts?.percent;
            } else {
                // initialize percent with 0 if there is no value yet
                this.proposal.funds.overheadCosts.percent = 0;
            }
            let totalcosts = this.proposal.funds?.costItems.filter(x => x.budget).reduce((sum, current) => sum + Number(current.budget), 0);
            this.proposal.funds.overheadCosts.amount = totalcosts * percent * 0.01;
            this.proposal.funds.overheadCosts.total = totalcosts + this.proposal.funds.overheadCosts?.amount;
        }
    }

}