import React, { Component } from "react";
import { AccountType, IAuthenticatedUser, IUserAccount } from './LoginComponent';
import Button from "react-bootstrap/Button";
import "./GoogleComponent.css"
import { Redirect } from 'react-router';
import { Dialog, DialogType, Label, Spinner, SpinnerSize } from "office-ui-fabric-react";

import { GoogleLogin, GoogleLoginProps, googleLogout, useGoogleLogin } from '@react-oauth/google';
import jwt_decode from "jwt-decode";

import { useState, useRef } from 'react';

export interface IGoogleLoginComponentProps {
    currentUser: IAuthenticatedUser;
    //signedIn: boolean;
    callback: (userInformation: IUserAccount, token: string, isRSMToken: boolean) => Promise<IAuthenticatedUser>;
    showError: (error: string) => void;
    setAuthStatus: (loggedIn: boolean, user: IAuthenticatedUser) => Promise<void>;
}

export default function GoogleLoginComponent(props: IGoogleLoginComponentProps) {
    const [redirectConsent, setRedirectConsent] = useState<boolean>(false);
    const [showWaitingSpinner, setShowWaitingSpinner] = useState<boolean>(false);
    const [showGoogleError, setShowGoogleError] = useState<boolean>(false);
    const [googleErrorMessage, setGoogleErrorMessage] = useState<string>(null);

    const btnRef = useRef<HTMLDivElement>(null);

    let isLoggedIn = Boolean(props.currentUser);
    let userName = props.currentUser ? `${props.currentUser.firstName} ${props.currentUser.lastName}` : "";

    const responseGoogleSuccess = (response: any) => {
        console.log("GOOGLE RESPONSE", response);
        try {
            let decoded: any = jwt_decode(response.credential);
            let user: IUserAccount = {
                firstName: decoded.given_name,
                lastName: decoded.family_name,
                email: decoded.email,
                accountType: AccountType.Google,
                name: decoded.name,
                userId: null,
                activateGuid: null,
                id: null,
                password: null
            } as IUserAccount;

            setShowWaitingSpinner(true);

            if (!props.currentUser || props.currentUser.email !== user.email) {
                props.callback(user, response.credential, false).then((spUser) => {
                    if (spUser.isNewUser) {

                        // keep user information for consent screen
                        window.sessionStorage.rsmConsentTarget = JSON.stringify(spUser);

                        // redirect user to consent page first                    
                        setShowWaitingSpinner(false);
                        setRedirectConsent(true);

                        // for now, don't sign user in yet
                        window.sessionStorage.removeItem("authToken");
                        window.sessionStorage.removeItem("rsmUser");
                    } else {
                        // directly authenticate user
                        setShowWaitingSpinner(false);
                        props.setAuthStatus(true, spUser);
                    }
                }).catch(() => {
                    setShowWaitingSpinner(false);
                    props.showError("Google");
                    forceLogout();
                });
            }
        } catch (err) {
            console.log("Error trying to decode google token", err);
        }
    };

    // Error Handler
    const responseGoogleError = () => {
        setGoogleErrorMessage(`Google Sign-in failed`);
        setShowGoogleError(true);
    };

    //const login = useGoogleLogin({
    //    onSuccess: responseGoogleSuccess,
    //    onError: responseGoogleError
    //  });

    // const login = () => {
    //     const gid = (window as any).google as any;
    //     gid.accounts.id.initialize({
    //         client_id: "653718434571-o0evjrj6esk9ekfh1k3ndihvuttfn1pk.apps.googleusercontent.com",
    //         callback: (response: any) => { console.dir(response) }
    //     });
    //     //gid.accounts.id.prompt();

    // };

    const forceLogout = () => {
        console.log("Forcing logout...");

        googleLogout();

        window.sessionStorage.removeItem("authToken");
        window.sessionStorage.removeItem("rsmUser");

        props.setAuthStatus(false, null);
    }

    return (
        <div >
            {redirectConsent && <Redirect to="consent" />}
            <div >
                {isLoggedIn ? (
                    <div>
                        Signed in as:<br />{userName}<br />
                        <Button variant="secondary" className="googleButton" style={{ width: '120px !important', marginTop: '10px' }} onClick={() => forceLogout()}>Logout</Button>
                    </div>

                ) : (
                    //<Button onClick={()=>login()} style={{ width: '200px', fontSize: '14px', fontFamily: 'Roboto, sans-serif' }} variant="secondary" className="googleButton">Sign in with Google</Button>
                    //<PrimaryButton text={"Google Signin"} onClick={()=>login()}></PrimaryButton>
                      <GoogleLogin
                          locale="en-us"
                          size="large"
                          width={200}
                          onSuccess={responseGoogleSuccess}
                          onError={responseGoogleError}
                    />                    
                )}
            </div>
            <Dialog
                hidden={!showWaitingSpinner}
                dialogContentProps={{
                    type: DialogType.normal,
                }}
                modalProps={{ isBlocking: true, className: "busyOverlay" }}
            >
                <Spinner size={SpinnerSize.large} label="Checking Google Account..." ariaLive="assertive" />
            </Dialog>
            <Dialog
                hidden={!showGoogleError}
                dialogContentProps={{
                    type: DialogType.normal,
                }}
                modalProps={{ isBlocking: true, className: "busyOverlay" }}
                onDismiss={() => { setShowGoogleError(false); setGoogleErrorMessage(null); }}
            >
                <Label style={{ color: 'red' }}>{googleErrorMessage}</Label>
            </Dialog>
        </div>
    );
}
