import { IUserAccount } from "./LoginComponent";

export class LoginHelper {
    
}
export class GeneralHelper {
    public static mandatoryValidation: string = "this field is mandatory";
    public static emailValidation: string = "please enter a valid emailaddress";
    public static confirmationPasswordInvalid: string = "'Password' and 'Cofirm Password' must match";
    public static parseError(error: any) {
        switch (error.status) {
            case 409:
                {
                    //return "A user with this emailaddress already exists";
                    return 
                }
            case 400:
            case 404:
            default:
                return error.detail;

        }

    }

    public static replacePlaceholder(content: string, user: IUserAccount) {

    }

    public static resetPWMail: string = `<h2>Hi {{Firstname}} {{Lastname}}</h2><p>Please click on this {{resetLink}} to reset your Passwort</p><p>Best regards</p>`
    public static activateUserMail: string = `<h2>Hi</h2><p>Please click on this {{activateLink}} to activate your user.</p>`

}