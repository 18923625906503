import React, { Component } from 'react';
import { Redirect, Route, RouteComponentProps, RouterProps, StaticContext } from 'react-router';
import { IDataProvider } from '../dataprovider/IDataProvider';
import { ProposalForm } from './Proposal/Proposal';
import ProposalStore from './Proposal/ProposalStore';

export interface IPrivateRouteProps extends RouterProps {
    isAuthenticated: boolean;
    dataProvider: IDataProvider;
    proposalStore: ProposalStore;
}

export interface IPrivateRouteState {
    isAuthenticated: boolean;
}

export class PrivateRoute extends React.Component<IPrivateRouteProps, IPrivateRouteState> {
    constructor(props: any) {
        super(props);
        this.state = {
            isAuthenticated: props.isAuthenticated
        };
        console.log("PrivateRoute-Constructor: Authenticated=" + props.isAuthenticated)
    }

    public componentWillReceiveProps(newProps: IPrivateRouteProps) {
        if (newProps.isAuthenticated !== this.props.isAuthenticated) {
            this.setState({
                isAuthenticated: newProps.isAuthenticated
            });
            console.log("PrivateRoute-componentWillReceiveProps: Authenticated=" + newProps.isAuthenticated)
        }
    }

    public render(): React.ReactElement<IPrivateRouteProps> {
        console.log("PrivateRoute-Render: Authenticated=" + this.state.isAuthenticated);
        let routeProps = this.props;
        return (
            <Route {...this.props}
                render={(props): React.ReactNode => {
                    console.log("PrivateRoute-Render-Route: Authenticated=" + this.state.isAuthenticated);
                    console.dir(routeProps);
                    return this.state.isAuthenticated ? this.props.children : null;
                    // return this.state.isAuthenticated ? <Route                        
                    //     path={"/proposal"}
                    //     render={(props) => (
                    //         <ProposalForm location={null} history={null} isLoggedIn={this.state.isAuthenticated} dataProvider={this.props.dataProvider} proposalStore={this.props.proposalStore} />
                    //     )}
                    // /> : null;
                }}
            />
        );
    }
}