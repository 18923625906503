import * as React from 'react';
import { css, Stack, PrimaryButton, Text, getTheme, Link, TextField, DefaultButton, MessageBarType, MessageBar, Alignment, Label } from 'office-ui-fabric-react';
import { IDataProvider } from '../../dataprovider/IDataProvider';
import { GeneralHelper } from './LoginHelper';
import { IUserAccount } from './LoginComponent';
import { Spinner } from 'office-ui-fabric-react';
import { Common } from './Common';
import { Redirect, Router } from 'react-router';
import { Badge } from 'react-bootstrap';

require('./LoginComponent.css');

export interface IPWResetProps {
    location: Location;
    history: any;
    stepSizeClass: string;
    dataProvider: IDataProvider;
}

export interface IPWResetState {
    userReadyForPWReset: boolean;
    informationMessage: string;
    informationType?: MessageBarType;
    password: string;
    email?: string;
    activationCode: string;
    confirmationPassword?: string;
    passwordErrorMessage?: string;
    emailErrorMessage?: string;

    emailWasSent: boolean;
    isProcessing: boolean;
    resetSuccessful: boolean;
    // redirectToLogin: boolean;

    pwdBadgeVariant: string;
    pwdBadgeLabel: string;

    confirmMode: boolean;
}

export class ResetPWPage extends React.Component<IPWResetProps, IPWResetState> {



    constructor(props: IPWResetProps) {
        super(props);

        this.state = {
            userReadyForPWReset: false,
            informationMessage: "",
            activationCode: "",
            password: "",

            emailWasSent: false,
            isProcessing: false,
            resetSuccessful: false,

            pwdBadgeVariant: null,
            pwdBadgeLabel: null,

            confirmMode: false
            // redirectToLogin: false
        };

    }
    public componentDidMount() {        
        console.log("Reset PW  componentDidMount");
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        // switch mode for confirmation
        let confirmMode = false;
        if (urlParams.get('confirmOnly') === "true") {
            this.setState({ confirmMode: true });
            confirmMode = true;
        }

        const code = urlParams.get('activationGuid');
        if (code) {
            this.setState({ activationCode: code });

            if (!confirmMode) {
                this.props.dataProvider.getUserByActivateParam(code, false).then(userReadyForPWReset => {
                    if (userReadyForPWReset) {
                        this.setState({ userReadyForPWReset: userReadyForPWReset })
                    }
                }).catch(err => {
                    console.dir(err);
                    let errormessage = err;
                    if (err.detail) {
                        errormessage = err.detail;
                    }
                    Common.removeUrlParam("activationGuid");
                    this.setInformationMessage(`${errormessage} Your link might have expired. Please do another Password Reset.`, MessageBarType.error, true);
                    //let errormessage = GeneralHelper.parseError(err);
                    //alert("Error on getUserByActivateParam: " + errormessage);
                });
            }
        } else {
            // this.setState({ informationMessage: "No valid reset PW link. Please click again on the reset PW link"})
        }
    }

    private _resetBtnClicked = () => {
        try {
            console.time("_resetBtnClicked");
            if (this.inputsAreaValid(true)) {
                // check if email allready exist
                this.resetPw();

            } else {
                this.setInformationMessage("Please fix the validation issues", MessageBarType.error);
            }
        } catch (err) {
            alert("Error: " + err);
        }
    }

    private _getResetLink = () => {
        let validEmail = Boolean(this.state.email) && this.state.email.search(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/) !== -1;
        
        if (!validEmail) {
            this.setState({ emailErrorMessage: `please enter a valid E-Mail address` });
        } else {
            this.setState({ isProcessing: true });
            let baseUrl = Common.getCurrentServerUrl();

            if (this.state.confirmMode) {
                this.props.dataProvider.resendActivationCode(this.state.email, baseUrl).then(activationCode => {
                    this.setState({
                        emailWasSent: true,
                        isProcessing: false
                    }, () => {
                        this.setInformationMessage("Confirmation E-mail was sent to you. Please check your E-mail.", MessageBarType.success, true);
                    })
                }).catch(err => {
                    let errormessage = err;
                    if (err.detail) {
                        errormessage = err.detail;
                    }
                    //let errormessage = GeneralHelper.parseError(err);
                    this.setInformationMessage(errormessage, MessageBarType.error);
                }).finally(() => {
                    this.setState({ isProcessing: false });
                });
            } else {
                this.props.dataProvider.getNewActivationCode(this.state.email, baseUrl).then(activationCode => {

                    this.setState({
                        emailWasSent: true,
                        isProcessing: false
                    }, () => {
                        this.setInformationMessage("Password Reset Email was sent to you. Please check your E-mail.", MessageBarType.success, true);
                    })
                }).catch(err => {
                    let errormessage = err;
                    if (err.detail) {
                        errormessage = err.detail;
                    }
                    //let errormessage = GeneralHelper.parseError(err);
                    this.setInformationMessage(errormessage, MessageBarType.error);
                }).finally(() => {
                    this.setState({ isProcessing: false });
                });
            }
        }
    }

    private resetPw = () => {
        this.setState({ isProcessing: true });
        this.props.dataProvider.resetPWByActivateParam(this.state.activationCode, this.state.password).then(result => {
            this.setInformationMessage("Reset Password successfully", MessageBarType.success, true);
            this.setState({ password: "", confirmationPassword: "", isProcessing: false, resetSuccessful: true });
        }).catch(err => {
            console.dir(err);
            let errormessage = err;
            if(err.detail){
                errormessage = err.detail;
            }
            //let errormessage = GeneralHelper.parseError(err);
            this.setInformationMessage(errormessage, MessageBarType.info);

        });
    }
    private inputsAreaValid = (register: boolean): boolean => {

        this.setState({ passwordErrorMessage: "" });
        let invalid = false;
        if (this.state.password == "") {
            invalid = true;
            this.setState({ passwordErrorMessage: GeneralHelper.mandatoryValidation });
        }
        if (this.state.confirmationPassword == "") {
            invalid = true;
            this.setState({ passwordErrorMessage: GeneralHelper.mandatoryValidation });
        } else if (this.state.confirmationPassword != this.state.password) {
            invalid = true;
            this.setState({ passwordErrorMessage: GeneralHelper.confirmationPasswordInvalid });
        }
        if (!this.checkPasswordStrength(this.state?.password)) {
            invalid = true;
            // no errorMessage necessary, it's displayed already with the password strength badge
        }
        return !invalid;


    }

    private setInformationMessage = (message: string, type?: MessageBarType, keepMessage = false): void => {
        this.setState({ informationMessage: message, informationType: type || MessageBarType.warning },
            () => {
                if (!keepMessage) {
                    setTimeout(() => { this.setState({ informationMessage: "" }); }, 4000);
                }
            });
    }

    private checkPasswordStrength = (pwd: string): boolean => {
        let strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})');
        let mediumPassword = new RegExp('((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))');
        let badgeVariant = null;
        let badgeLabel = null;
        let result: boolean = true;
        if (strongPassword.test(pwd)) {
            badgeVariant = "success";
            badgeLabel = "Strong Password";
            result = true;
        } else if (mediumPassword.test(pwd)) {
            badgeVariant = "warning";
            badgeLabel = "Too short";
            result = false;
        } else {
            badgeVariant = "danger";
            badgeLabel = "Weak Password";
            result = false;
        }
        this.setState({
            pwdBadgeVariant: badgeVariant,
            pwdBadgeLabel: badgeLabel
        });
        return result;
    }

    public render(): React.ReactElement<IPWResetProps> {

        return (
            <Stack horizontal verticalAlign="center" className="fullWidth" horizontalAlign="center" verticalFill={true} tokens={{ childrenGap: 20 }}>
                <Stack style={{ width: '500px' }} verticalAlign="space-between" className={css("subscriptionContainerInner", this.props.stepSizeClass, "animate", "popIn")} tokens={{ childrenGap: 5 }}>
                    {this.state.confirmMode ? <h2>Confirmation Email</h2> : <h2>Reset Password</h2>}
                    {!this.state.emailWasSent &&
                        <>
                            {
                                this.state.userReadyForPWReset ? !this.state.resetSuccessful && <>

                                        <TextField autoComplete="off" label="Password" type="password" canRevealPassword placeholder="Enter your password" errorMessage={this.state.passwordErrorMessage}
                                            onChange={(event, newValue?) => {
                                                this.setState({ password: newValue || "" });

                                                this.checkPasswordStrength(newValue);
                                            }}></TextField>
                                        {this.state?.pwdBadgeVariant && this.state?.pwdBadgeLabel && <Stack style={{marginTop:'5px'}}><Badge variant={this.state.pwdBadgeVariant} style={{ width: '100px' }}>{this.state.pwdBadgeLabel}</Badge>{(this.state?.pwdBadgeVariant == "danger" || this.state?.pwdBadgeVariant == "warning") && <Label style={{ color: 'red', maxWidth: '410px'}}>Password must be at least 8 characters long and contain: lowercase letters, capital letters, numbers and special characters</Label> }</Stack>}
                                        <TextField autoComplete="off" label="Confirm Password" type="password" canRevealPassword placeholder="Confirm Password" errorMessage={this.state.passwordErrorMessage}
                                            onChange={(event, newValue?) => {
                                                this.setState({ confirmationPassword: newValue });
                                            }}></TextField>
                                        <div style={{ display: 'flex', gap: '10px' }}><DefaultButton disabled={this.state.isProcessing} className={"customButton"} onClick={this._resetBtnClicked}>Save new Password</DefaultButton>{this.state.isProcessing && <Spinner />}</div>


                                    </> :
                                    <>
                                        <TextField label="E-Mail Address" placeholder="Enter your mail" errorMessage={this.state.emailErrorMessage}
                                            onChange={(event, newValue?) => {
                                                if(newValue && newValue.trim().length !== 0){
                                                    this.setState({ email: newValue.trim() });
                                                } else {
                                                    this.setState({ email: null });
                                                }
                                            }}></TextField>
                                    <div style={{ display: 'flex', gap: '10px' }}><DefaultButton disabled={this.state.isProcessing} className={"customButton"} onClick={this._getResetLink}>{this.state.confirmMode ? "Send Confirmation Email" : "Reset Password" }</DefaultButton>{this.state.isProcessing && <Spinner />}</div>

                                    </>
                            }
                        </>}
                    {
                        this.state.informationMessage ?
                            <MessageBar
                                //className={styles.messageBar}
                                messageBarType={this.state.informationType || MessageBarType.warning}
                                isMultiline={true}
                                dismissButtonAriaLabel="Close"
                                onDismiss={(ev?: any) => { 
                                    this.setState({ emailWasSent: false, informationMessage: null}, ()=>{
                                        window.location.href = `${Common.getCurrentServerUrl()}/`;
                                    });
                                }}
                            >{this.state.informationMessage}</MessageBar> : ""
                    }                    

                </Stack>

            </Stack>
        );
    }
}