import * as React from 'react';
import { css, Stack, PrimaryButton, Text, getTheme, INavLinkGroup, Nav, IStackProps, INavLink, TextField, Label, IconButton, StackItem } from 'office-ui-fabric-react';
import '../Proposal.scss';
import { IFormProps } from '../Models/IFormProps';
import { AttachmentComponent } from '../../AttachmentComponent/AttachmentComponent';
import { observer } from 'mobx-react';
import { WizardNavigationButtons } from './WizardNavigationFooter';
import { IFileItem } from '../Models/IFileItem';
import ProposalFormValidation from './ProposalFormValidation';

export interface IAttachmentsProps extends IFormProps {
}

export interface IAttachmentsState {
}

@observer
export class Attachments extends React.Component<IAttachmentsProps, IAttachmentsState> {

    constructor(props: any) {
        super(props);
        this.state = {
        }
    }

    public componentDidMount() {
    }

    private onISOAttachmentUploadAdded = (files: File[]): Promise<void> => {
        if (this.props.proposalStore) {
            if (!this.props.proposalStore?.isoCertificateAttachments)
                this.props.proposalStore.isoCertificateAttachments = [];

            let delayedAttachments = [...this.props.proposalStore?.isoCertificateAttachments];
            files.forEach((file) => {
                delayedAttachments.push(file);
            });
            this.props.proposalStore.isoCertificateAttachments = delayedAttachments;
        }
        return Promise.resolve();
    }
    private onISOAttachmentDeleted = async (file: IFileItem | File): Promise<void> => {
        if (this.props.proposalStore) {
            if (file instanceof File) {
                let delayedAttachments = [...this.props.proposalStore.isoCertificateAttachments];
                let idx = delayedAttachments.indexOf(file);
                if (idx !== -1) {
                    delayedAttachments.splice(idx, 1);

                    this.props.proposalStore.isoCertificateAttachments = delayedAttachments;
                }
            }
            else {
                file.isDeleted = true;
            }
        }

        return Promise.resolve();
    }

    private onCVAttachmentUploadAdded = (files: File[]): Promise<void> => {
        if (this.props.proposalStore) {
            if (!this.props.proposalStore?.cvAttachments)
                this.props.proposalStore.cvAttachments = [];

            let delayedAttachments = [...this.props.proposalStore?.cvAttachments];
            files.forEach((file) => {
                delayedAttachments.push(file);
            });
            this.props.proposalStore.cvAttachments = delayedAttachments;
        }
        return Promise.resolve();
    }
    private onCVAttachmentDeleted = async (file: IFileItem | File): Promise<void> => {
        if (this.props.proposalStore) {
            if (file instanceof File) {
                let delayedAttachments = [...this.props.proposalStore.cvAttachments];
                let idx = delayedAttachments.indexOf(file);
                if (idx !== -1) {
                    delayedAttachments.splice(idx, 1);

                    this.props.proposalStore.cvAttachments = delayedAttachments;
                }
            } else {
                file.isDeleted = true;
            }
        }

        return Promise.resolve();
    }

    private onOtherAttachmentUploadAdded = (files: File[]): Promise<void> => {
        if (this.props.proposalStore) {
            if (!this.props.proposalStore?.otherAttachments)
                this.props.proposalStore.otherAttachments = [];

            let delayedAttachments = [...this.props.proposalStore?.otherAttachments];
            files.forEach((file) => {
                delayedAttachments.push(file);
            });
            this.props.proposalStore.otherAttachments = delayedAttachments;
        }
        return Promise.resolve();
    }
    private onOtherAttachmentDeleted = async (file: IFileItem | File): Promise<void> => {
        if (this.props.proposalStore) {
            if (file instanceof File) {
                let delayedAttachments = [...this.props.proposalStore.otherAttachments];
                let idx = delayedAttachments.indexOf(file);
                if (idx !== -1) {
                    delayedAttachments.splice(idx, 1);

                    this.props.proposalStore.otherAttachments = delayedAttachments;
                }
            } else {
                file.isDeleted = true;
            }
        }

        return Promise.resolve();
    }

    public render() {
        const extensionFilters = [".txt", ".pdf", ".xls", ".xlsx", ".doc", ".docx", ".ppt", "pptx", ".rar", ".zip", ".png", ".jpg", ".jpeg", ".bmp", ".gif"];
        return (
            <Stack verticalAlign="center" className={css('fullWidth', 'animate', 'stepPopIn')} verticalFill={true} tokens={{ childrenGap: 20 }}>
                <Stack verticalAlign="space-between" tokens={{ childrenGap: 5 }}>
                    {
                        this.props.isReadonly && <Label className="heading">Attachments</Label>
                    }
                    {
                        !this.props.isReadonly && <h1>Attachments</h1>
                    }
                </Stack>
                <Stack className="fullWidth">
                    <ProposalFormValidation {...this.props} fieldErrors={this.props.proposal.fieldErrors} ></ProposalFormValidation>
                </Stack>
                <Stack className="fullWidth" tokens={{ childrenGap: 10 }}>
                    <fieldset>
                        <legend>CV for applicants *</legend>
                        <AttachmentComponent
                            apiEndpointSP={`/api/SPInterface`}
                            attachments={this.props.proposalStore?.cvAttachments}
                            readonly={this.props.isReadonly ? this.props.isReadonly : false}
                            deletePermissions="All"
                            enableDropZone={true}
                            attachmentClickBehaviour="Download"
                            extensionFilters={extensionFilters}
                            fileMode="MultiFile"
                            onFilesAdded={this.onCVAttachmentUploadAdded}
                            onFileDeleted={this.onCVAttachmentDeleted}
                            maxFileNameWidth={"700px"}
                            errorMessage={this.props.proposal.fieldErrors ? this.props.proposal.fieldErrors[7]["cvAttachments"]?.error : null}
                        />
                    </fieldset>
                    <fieldset>
                        <legend>ISO Certificate</legend>
                        <Stack tokens={{ childrenGap: 10 }}>
                            <Label>For clinical stuides: GCP and / or ISO 14155 training is expected</Label>
                            <AttachmentComponent
                                apiEndpointSP={`/api/SPInterface`}
                                attachments={this.props.proposalStore?.isoCertificateAttachments}
                                readonly={this.props.isReadonly ? this.props.isReadonly : false}
                                deletePermissions="All"
                                enableDropZone={true}
                                attachmentClickBehaviour="Download"
                                extensionFilters={extensionFilters}
                                fileMode="MultiFile"
                                onFilesAdded={this.onISOAttachmentUploadAdded}
                                onFileDeleted={this.onISOAttachmentDeleted}
                                maxFileNameWidth={"700px"}
                                errorMessage={this.props.proposal.fieldErrors ? this.props.proposal.fieldErrors[7]["isoCertificateAttachments"]?.error : null}
                            />
                        </Stack>
                    </fieldset>
                    <fieldset>
                        <legend>Other attachments</legend>
                        <AttachmentComponent
                            apiEndpointSP={`/api/SPInterface`}
                            attachments={this.props.proposalStore?.otherAttachments}
                            readonly={this.props.isReadonly ? this.props.isReadonly : false}
                            deletePermissions="All"
                            enableDropZone={true}
                            attachmentClickBehaviour="Download"
                            extensionFilters={extensionFilters}
                            fileMode="MultiFile"
                            onFilesAdded={this.onOtherAttachmentUploadAdded}
                            onFileDeleted={this.onOtherAttachmentDeleted}
                            maxFileNameWidth={"700px"}
                        />
                    </fieldset>
                </Stack>
                <Label>Please do not provide directly identifiable patient data such as names in the field below.</Label>

                {
                    this.props.showWizardNavigation && <StackItem className={css("wizardNavigationContainer")}>
                        <WizardNavigationButtons {...this.props} step={this.props.currentStep} save={this.props.onSave}></WizardNavigationButtons>
                    </StackItem>
                }
            </Stack>
        );

    }
}