import * as React from 'react';
import { Stack, ActionButton, Checkbox, Link, Label } from 'office-ui-fabric-react';
import { observer } from 'mobx-react';
import { IProposal } from '../Models/IProposal';
const pdf = require("../../../files/Terms_and_Condition_ of_ Submission.pdf");


function _renderLabelWithLink() {
    return (
        <span>
            I acknowledge to have read and agree to the <Link href={pdf} target="_blank" underline>
                Clinical Research Agreement
            </Link>
            
        </span>
    );
}


export interface WizardNavigationButtonsProps {
    proposal: IProposal;
    currentStep?: any;
    firstStep?: any;
    goToStep?: any;
    lastStep?: any;
    nextStep?: any;
    previousStep?: any;
    totalSteps?: any;
    step?: any;
    save?: any;
    submit?: any;
    isUserAuthenticated?: boolean;
}

export interface WizardNavigationButtonsState {
    clinicalAgreementChecked: boolean;
    legalConfirmationChecked: boolean;
}

@observer
export class WizardNavigationButtons extends React.Component<WizardNavigationButtonsProps, WizardNavigationButtonsState> {

    constructor(props: any) {
        super(props);
        this.state = {
            clinicalAgreementChecked: false,
            legalConfirmationChecked:false
        }
    }

    public componentDidMount() {
    }

    public render() {
        return <div>
            <Stack horizontal>
                <Stack horizontal tokens={{ childrenGap: 20 }} wrap={true} className="fullWidth">
                    <ActionButton className="straumannBlueButton" iconProps={{ iconName: 'Save' }} onClick={this.props.save}>Save Proposal</ActionButton>
                    <Label className="lineHeight" >No autosave, save before exit</Label>
                </Stack>
                <Stack horizontalAlign="end" horizontal tokens={{ childrenGap: 20 }} wrap={true} className="fullWidth">

                    {
                        this.props.step > 1 || this.props.step < this.props.totalSteps ?
                            <div className="actionButtonsNavigationBar">
                                {
                                    this.props.step > 1 && <ActionButton className="wizardButton" iconProps={{ iconName: 'ChevronLeft' }} onClick={
                                        () => {
                                            this.props.previousStep();
                                            //this.props.save().then(this.props.previousStep);
                                        }
                                    }>Go Back</ActionButton>
                                }
                                {
                                    this.props.step < this.props.totalSteps && <ActionButton className="wizardButton" iconProps={{ iconName: 'ChevronRight' }} onClick={() => {
                                        this.props.nextStep();
                                        //this.props.save().then(this.props.nextStep);
                                    }}>Next</ActionButton>
                                }
                            </div>
                            : null
                    }

                </Stack>

            </Stack>
            {
                this.props.step === this.props.totalSteps &&
                <Stack horizontal tokens={{ childrenGap: 20 }} wrap={true} className="submitWrapper">

                    <Checkbox label="I acknowledge to have read and agree to the Clinical Research Agreement." onRenderLabel={_renderLabelWithLink} onChange={(e, newChecked) => {
                        this.props.proposal.agreeClinicalResearchConfirmed = newChecked;
                        this.setState({ clinicalAgreementChecked: newChecked });
                    }} />
                    <Checkbox label="I warrant to comply with all applicable information and consents legal requirements regarding the data of the third-party individuals I submit. " onChange={(e, newChecked) => {
                        this.props.proposal.complyLegalRequirementsConfirmed = newChecked;
                        this.setState({ legalConfirmationChecked: newChecked });
                    }} />
                    <ActionButton className="straumannBlueButton" disabled={!this.state.legalConfirmationChecked || !this.state.clinicalAgreementChecked } iconProps={{ iconName: 'Assign' }} onClick={this.props.submit(this.props.goToStep)}>Submit Proposal</ActionButton>
                </Stack>
            }
        </div>
    }
}

