import * as React from 'react';
import { css, Stack, PrimaryButton, Text, getTheme, INavLinkGroup, Nav, IStackProps, INavLink, TextField, Label, IconButton, IDropdownOption, Dropdown, IDropdownStyles, ChoiceGroup, IChoiceGroupOption, Checkbox, StackItem, mergeStyles } from 'office-ui-fabric-react';
import '../Proposal.scss';
import { IFormProps } from '../Models/IFormProps';
import { observer } from 'mobx-react';
import { WizardNavigationButtons } from './WizardNavigationFooter';
import { IStudyDesign } from '../Models/IProposal';
import ProposalFormValidation from './ProposalFormValidation';

export interface IStudyDescriptionProps extends IFormProps {
    typeOfStudyList: string[];
    studyDesignAList: string[];
    studyDesignBList: string[];
    studyDesignCList: string[];
    fieldsList: string[];
}

export interface IStudyDescriptionState {
    showTypeOfStudyOther: boolean;
    showStudyDesign: boolean;
    showFieldOthers: boolean;
}

@observer
export class StudyDescription extends React.Component<IStudyDescriptionProps, IStudyDescriptionState> {

    constructor(props: any) {
        super(props);
        this.state = {
            showTypeOfStudyOther: false,
            showStudyDesign: false,
            showFieldOthers: false
        }
    }

    public componentDidMount() {
        this.setState({
            showTypeOfStudyOther: this.props.proposal.typeOfStudy == "Other",
            showStudyDesign: (this.props.proposal.typeOfStudy == "Animal" || this.props.proposal.typeOfStudy == "Clinical"),
            showFieldOthers: this.props.proposal.fields ? this.props.proposal.fields.some(x => x == "Others") : false
        });
    }

    private onDropdownChange = (event: any, option?: IDropdownOption, index?: number): void => {
        if (option) {
            this.props.proposal.typeOfStudy = option?.text;
            this.props.proposal.typeOfStudyOther = "";
            this.props.proposal.studyDesign = {};
            this.props.proposal.studyDesign.groupA = this.props.studyDesignAList[0];
            this.props.proposal.studyDesign.groupB = this.props.studyDesignBList[0];
            this.props.proposal.studyDesign.groupC = this.props.studyDesignCList[0];
            this.setState({
                showTypeOfStudyOther: this.props.proposal.typeOfStudy == "Other",
                showStudyDesign: (this.props.proposal.typeOfStudy == "Animal" || this.props.proposal.typeOfStudy == "Clinical"),
            });
        }
    }

    private onChoiceGroupAChange = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption): void => {
        if (option) {
            let studyDesign: IStudyDesign = { ...this.props.proposal.studyDesign };
            if (!studyDesign)
                studyDesign = {};
            studyDesign.groupA = option.key;
            this.props.proposal.studyDesign = studyDesign;
        }
        if (option?.key == "Retrospective") {
            this.props.proposal.studyDesign.groupB = "";
            this.props.proposal.studyDesign.groupC = "";
        }
    }

    private onChoiceGroupBChange = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption): void => {
        if (option) {
            let studyDesign: IStudyDesign = { ...this.props.proposal.studyDesign };
            if (!studyDesign)
                studyDesign = {};
            studyDesign.groupB = option.key;
            this.props.proposal.studyDesign = studyDesign;
        }
        if (option?.key == "Single-arm") {
            // no 
            this.props.proposal.studyDesign.groupC = "";
            // no nTotal if Single arm
            if (this.props.proposal.sampleSize) {
                delete this.props.proposal.sampleSize.nPerGroup;
            }
        } 
    }

    private onChoiceGroupCChange = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption): void => {
        if (option) {
            let studyDesign: IStudyDesign = { ...this.props.proposal.studyDesign };
            if (!studyDesign)
                studyDesign = {};
            studyDesign.groupC = option.key;
            this.props.proposal.studyDesign = studyDesign;
        }
    }

    public render() {
        const columnProps1: Partial<IStackProps> = {
            tokens: { childrenGap: 10 },
            styles: { root: { width: "20%" } },
        };

        const columnProps2: Partial<IStackProps> = {
            tokens: { childrenGap: 10 },
            styles: { root: { width: "30%" } },
        };

        const columnProps3: Partial<IStackProps> = {
            tokens: { childrenGap: 10 },
            styles: { root: { width: "50%" } },
        };

        const columnProps4: Partial<IStackProps> = {
            tokens: { childrenGap: 10 },
            styles: { root: { width: "70%" } },
        };

        const columnProps5: Partial<IStackProps> = {
            tokens: { childrenGap: 10 },
            styles: { root: { width: "80%" } },
        };

        const dropdownStyles: Partial<IDropdownStyles> = {
            dropdown: { width: 300 },            
        };

        const optionsForTypeOfStudy: IDropdownOption[] = [];
        this.props.typeOfStudyList.map((type) =>
            optionsForTypeOfStudy.push({ key: type, text: type })
        );

        const optionsForStudyDesignA: IChoiceGroupOption[] = [];
        this.props.studyDesignAList.map((type) =>
            optionsForStudyDesignA.push({ key: type, text: type })
        );
        const optionsForStudyDesignB: IChoiceGroupOption[] = [];
        this.props.studyDesignBList.map((type) =>
            optionsForStudyDesignB.push({ key: type, text: type })
        );
        const optionsForStudyDesignC: IChoiceGroupOption[] = [];
        this.props.studyDesignCList.map((type) =>
            optionsForStudyDesignC.push({ key: type, text: type })
        );

        const styleError:string =  mergeStyles({
            fontFamily: 'Segoe UI, Segoe UI Web (West European), Segoe UI, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif',            
            fontSize: '12px',
            fontWeight: 400,
            color: 'rgb(164, 38, 44)',
            margin: '0px',
            paddingTop: '5px',
            display: 'flex',
            alignItems: 'center'
        });        

        return (
            <Stack verticalAlign="center" className={css('fullWidth', 'animate', 'stepPopIn')} verticalFill={true} tokens={{ childrenGap: 20 }}>
                <Stack verticalAlign="space-between" tokens={{ childrenGap: 5 }}>
                    {
                        this.props.isReadonly && <Label className="heading">Study Description</Label>
                    }
                    {
                        !this.props.isReadonly && <h1>Study Description</h1>
                    }
                </Stack>
                <Stack className="fullWidth">
                    <ProposalFormValidation {...this.props} fieldErrors={this.props.proposal.fieldErrors} ></ProposalFormValidation>
                </Stack>
                <TextField maxLength={255} label="Study Title" required value={this.props.proposal.studyTitle} errorMessage={this.props.proposal.fieldErrors? this.props.proposal.fieldErrors[1]["studyTitle"]?.error : null} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                    onChange={(_event: any, newValue?: string) => {
                        this.props.proposal.studyTitle = newValue;
                    }}></TextField>

                <TextField label="Summary" maxLength={1000} errorMessage={this.props.proposal.fieldErrors ? this.props.proposal.fieldErrors[1]["summary"]?.error : null} placeholder="max. 1000 characters, should contain aim, material and methods" multiline rows={3} value={this.props.proposal.summary} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                    onChange={(_event: any, newValue?: string) => {
                        this.props.proposal.summary = newValue;
                    }}></TextField>

                <TextField label="Background and rationale" maxLength={2000} required placeholder="max. 2000 characters" errorMessage={this.props.proposal.fieldErrors? this.props.proposal.fieldErrors[1]["backgroundRatione"]?.error : null} multiline rows={5} value={this.props.proposal.backgroundRatione} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                    onChange={(_event: any, newValue?: string) => {
                        this.props.proposal.backgroundRatione = newValue;
                    }}></TextField>

                <TextField label="Primary study hypothesis" maxLength={2000} required placeholder="(Formulate only one hypothesis, we recommend using the PICO(S) method)" errorMessage={this.props.proposal.fieldErrors? this.props.proposal.fieldErrors[1]["studyHypothesis"]?.error : null} multiline rows={5} value={this.props.proposal.studyHypothesis} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                    onChange={(_event: any, newValue?: string) => {
                        this.props.proposal.studyHypothesis = newValue;
                    }}></TextField>

                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    {
                        this.props.isReadonly &&
                        <TextField label="Type of study" maxLength={255} value={this.props.proposal.typeOfStudy} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}></TextField>
                    }
                    {
                        !this.props.isReadonly &&
                        <Dropdown
                            errorMessage={this.props.proposal.fieldErrors? this.props.proposal.fieldErrors[1]["typeOfStudy"]?.error : null}
                            required
                            placeholder="Select an option"
                            label="Type of study"
                            options={optionsForTypeOfStudy}
                            styles={dropdownStyles}
                            onChange={this.onDropdownChange}
                            selectedKey={this.props.proposal.typeOfStudy} />
                    }
                    {
                        this.state.showTypeOfStudyOther &&
                        <TextField label="Other"  maxLength={255} required placeholder="Add custom type of study" className="fullWidth" errorMessage={this.props.proposal.fieldErrors? this.props.proposal.fieldErrors[1]["typeOfStudyOther"]?.error : null} value={this.props.proposal.typeOfStudyOther} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                            onChange={(_event: any, newValue?: string) => {
                                this.props.proposal.typeOfStudyOther = newValue;
                            }}></TextField>
                    }
                </Stack>

                {
                    this.state.showStudyDesign &&
                    <fieldset>
                        <legend>Study Design</legend>
                        {
                            this.props.isReadonly &&
                            <Label>{this.props.proposal.studyDesign?.groupA}</Label>
                        }
                        {
                            !this.props.isReadonly &&
                            <ChoiceGroup
                                className="inlineflex"
                                options={optionsForStudyDesignA}
                                onChange={this.onChoiceGroupAChange}
                                selectedKey={this.props.proposal.studyDesign?.groupA} disabled={this.props.isReadonly}
                                defaultSelectedKey={this.props.studyDesignAList[0]}
                            />
                        }
                        <hr className="fullWidth" />
                        {
                            this.props.isReadonly &&
                            <><Label>{this.props.proposal.studyDesign?.groupB}</Label><hr className="fullWidth" /></>
                        }
                        {
                            !this.props.isReadonly && this.props.proposal.studyDesign?.groupA != "Retrospective" &&
                            <><ChoiceGroup
                                className="inlineflex"
                                options={optionsForStudyDesignB}
                                onChange={this.onChoiceGroupBChange}
                                selectedKey={this.props.proposal.studyDesign?.groupB} disabled={this.props.isReadonly}
                                defaultSelectedKey={this.props.studyDesignBList[0]}
                            /><hr className="fullWidth" /></>
                        }
                        
                        {
                            this.props.isReadonly &&
                            <Label>{this.props.proposal.studyDesign?.groupC}</Label>
                        }
                        {
                            !this.props.isReadonly && this.props.proposal.studyDesign?.groupB != "Single-arm" && !this.props.isReadonly && this.props.proposal.studyDesign?.groupA != "Retrospective" &&
                            <><ChoiceGroup
                                className="inlineflex"
                                options={optionsForStudyDesignC}
                                onChange={this.onChoiceGroupCChange}
                                selectedKey={this.props.proposal.studyDesign?.groupC} disabled={this.props.isReadonly}
                                defaultSelectedKey={this.props.studyDesignCList[0]}
                            /></>
                        }
                    </fieldset>
                }


                <fieldset>
                    <legend>Fields *</legend>
                    {
                        this.props.isReadonly &&
                        <Stack verticalAlign="space-between" tokens={{ childrenGap: 10 }}>
                            {
                                this.props.fieldsList.map((field, idx) => {
                                    if (field != "All above") {
                                        let checked = this.props.proposal.fields ? this.props.proposal.fields.some(x => x == field) : false;
                                        if (checked === true)
                                            return <Label key={`${idx}_${field}`}>{field}</Label>;
                                    }
                                })
                            }
                            {
                                this.props.proposal.fields?.map((field, index) => {
                                    if (field === "Others") {
                                        return <Label key={`others_${index}`} >{`${field}: ${this.props.proposal.fieldOthers}`}</Label>;
                                    }
                                })
                            }
                        </Stack>
                    }
                    {
                        !this.props.isReadonly &&
                        <Stack verticalAlign="space-between" tokens={{ childrenGap: 10 }}>
                            {
                                this.props.fieldsList.map((field, idx) => {
                                    if (field != "All above") {
                                        let checked = this.props.proposal.fields ? this.props.proposal.fields.some(x => x == field) : false;
                                        return <Checkbox key={`${field}_${idx}`} label={field} checked={checked} disabled={this.props.isReadonly} onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) => {
                                            if (!this.props.proposal.fields)
                                                this.props.proposal.fields = [];

                                            if (!isChecked) {
                                                this.props.proposal.fields = this.props.proposal.fields.filter(x => x != field);
                                            }
                                            else {
                                                this.props.proposal.fields.push(field)
                                            }

                                        }} />;
                                    }
                                })
                            }
                            <Stack verticalAlign="center" horizontal tokens={{ childrenGap: 10 }} styles={{root:{alignItems:'flex-start'}}}>
                                <Checkbox label={"Others"} checked={this.state.showFieldOthers} onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) => {
                                    if (!this.props.proposal.fields)
                                        this.props.proposal.fields = [];

                                    if (!isChecked) {
                                        this.props.proposal.fieldOthers = "";
                                        this.props.proposal.fields = this.props.proposal.fields.filter(x => x != "Others");
                                    }
                                    else {
                                        this.props.proposal.fields.push("Others")
                                    }
                                    this.setState({ showFieldOthers: isChecked ? isChecked : false });

                                }} />
                                {
                                    this.state.showFieldOthers &&
                                    <TextField placeholder="Add others" maxLength={255} errorMessage={this.props.proposal.fieldErrors? this.props.proposal.fieldErrors[1]["fieldOthers"]?.error : null} className="fullWidth" value={this.props.proposal.fieldOthers}
                                        onChange={(_event: any, newValue?: string) => {
                                            this.props.proposal.fieldOthers = newValue;
                                        }}></TextField>
                                }
                            </Stack>
                        </Stack>
                    }
                    <Label className={css(styleError, 'rsmError')}>{this.props.proposal.fieldErrors? this.props.proposal.fieldErrors[1]["fields"]?.error : null}</Label>
                </fieldset>
                {
                    this.props.showWizardNavigation && <StackItem className={css("wizardNavigationContainer")}>
                        <WizardNavigationButtons {...this.props} step={this.props.currentStep} save={this.props.onSave}></WizardNavigationButtons>
                    </StackItem>
                }
            </Stack>
        );

    }
}