export default class HttpService {

    public static async get(url: string, body?: any, headers?: any): Promise<any> {
        return new Promise(async (resolve, reject)=>{
            let response: Response;

            let baseHeaders = {
                'Accept': 'application/json'
            };

            try{
                // add bearer if present
                if (window.sessionStorage.authToken) {
                    let authToken = JSON.parse(window.sessionStorage.authToken);
                    (<any>baseHeaders)["Authorization"] = `Bearer ${authToken.token}`;
                }

                if (headers) {
                    baseHeaders = { ...baseHeaders, ...headers };
                }

                if (body)
                    response = await fetch(url, {
                        method: 'GET',
                        cache: 'no-cache',
                        headers: baseHeaders,
                        body: JSON.stringify(body)
                    });
                else
                    response = await fetch(url, {
                        method: 'GET',
                        cache: 'no-cache',
                        headers: baseHeaders,
                    });

                if (response.ok)
                    return response.json().then((result)=>{
                        resolve(result);
                    });
                else if (response.status == 401) {
                    window.sessionStorage.removeItem("authToken");
                    window.sessionStorage.removeItem("rsmUser");
                    reject("Authentication Token Expired. Please sign in again.");
                }
                else if(response.status == 403){
                    reject("Unauthorized");
                }
                else{
                    try{
                        return response.json().then((err)=>{
                            reject(`Http Get Error: ${err?.title}`);
                        }).catch((ierr)=>{
                            console.dir(ierr);
                            reject(ierr); 
                        });
                    }catch(ex){
                        reject("Unauthorized");
                    }
                }
            }catch(ex){
                reject((<any>ex).message);
            }
        });
    }

    public static async post(url: string, body?: any, headers?: any): Promise<any> {
        return new Promise(async (resolve, reject) => { 
            let baseHeaders = {
                'Accept': 'application/json'
            };
            if (headers) {
                baseHeaders = { ...baseHeaders, ...headers };
            }

            // only add an authorization header if it doesn't exist already
            if(!(<any>baseHeaders)["Authorization"] || (<any>baseHeaders)["Authorization"].length==0){
                // add bearer if present
                if (window.sessionStorage.authToken) {
                    let authToken = JSON.parse(window.sessionStorage.authToken);
                    (<any>baseHeaders)["Authorization"] = `Bearer ${authToken.token}`;
                }
            }

            let response: Response;
            try {
                if (body) {
                    response = await fetch(url, {
                        method: 'POST',
                        cache: 'no-cache',
                        headers: {
                            'Content-Type': 'application/json',
                            ...baseHeaders,
                        },
                        body: JSON.stringify(body)
                    });
                } else {
                    response = await fetch(url, {
                        method: 'POST',
                        cache: 'no-cache',
                        headers: baseHeaders
                    });
                }

                if (response.ok)
                    return response.json().then((result)=>{
                        resolve(result);
                    });
                else if (response.status == 401) {
                    window.sessionStorage.removeItem("authToken");
                    window.sessionStorage.removeItem("rsmUser");
                    reject("Authentication Token Expired. Please sign in again.");
                }
                else
                    return response.json().then((err) => {                        
                        let errorMessage = err;
                        if(err?.detail){
                            errorMessage = err?.detail;
                        }
                        reject(errorMessage);
                    });
            } catch (err) {
                let ex = err as any;
                if (err && ex.message && ex.message.indexOf("Unauthorized") !== -1) {
                    window.sessionStorage.removeItem("authToken");
                    window.sessionStorage.removeItem("rsmUser");
                    reject("Authentication Token Expired. Please sign in again.");
                } else{
                    reject(ex.message);
                }
            }
        });
    }
}