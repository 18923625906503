import * as React from 'react';
import { css, Stack, PrimaryButton, Text, getTheme, INavLinkGroup, Nav, IStackProps, INavLink, TextField, Label, IconButton, IDropdownOption, Dropdown, IDropdownStyles, StackItem } from 'office-ui-fabric-react';
import '../Proposal.scss';
import { IFormProps } from '../Models/IFormProps';
import { observer } from 'mobx-react';
import { WizardNavigationButtons } from './WizardNavigationFooter';
import ProposalFormValidation from './ProposalFormValidation';

export interface IMaterialsAndMethodesProps extends IFormProps {
}

export interface IMaterialsAndMethodesState {
    isAnimalOrClinical: boolean;
}

@observer
export class MaterialsAndMethodes extends React.Component<IMaterialsAndMethodesProps, IMaterialsAndMethodesState> {

    constructor(props: any) {
        super(props);
        this.state = {
            isAnimalOrClinical: false
        }
    }

    public componentDidMount() {
        

        this.setState({
            isAnimalOrClinical: (this.props.proposal.typeOfStudy == "Animal" || this.props.proposal.typeOfStudy == "Clinical")
        }, () => {
            if (this.props.proposal.materialMethode) {
                if (this.state.isAnimalOrClinical) {
                    this.props.proposal.materialMethode.materialExperimentalSetup = undefined;
                }
                else {
                    this.props.proposal.materialMethode.populationIndication = undefined;
                    this.props.proposal.materialMethode.treatments = undefined;
                    this.props.proposal.materialMethode.experimentalProcedures = undefined;
                }
            }
        });
    }

    public render() {
        const columnProps1: Partial<IStackProps> = {
            tokens: { childrenGap: 10 },
            styles: { root: { width: "20%" } },
        };

        const columnProps2: Partial<IStackProps> = {
            tokens: { childrenGap: 10 },
            styles: { root: { width: "30%" } },
        };

        const columnProps3: Partial<IStackProps> = {
            tokens: { childrenGap: 10 },
            styles: { root: { width: "50%" } },
        };

        const columnProps4: Partial<IStackProps> = {
            tokens: { childrenGap: 10 },
            styles: { root: { width: "70%" } },
        };

        const columnProps5: Partial<IStackProps> = {
            tokens: { childrenGap: 10 },
            styles: { root: { width: "80%" } },
        };

        const columnProps6: Partial<IStackProps> = {
            tokens: { childrenGap: 10 },
            styles: { root: { width: "40%" } },
        };

        if (!this.props.proposal.secondaryOutcomeParam || this.props.proposal.secondaryOutcomeParam.length == 0) {
            this.props.proposal.secondaryOutcomeParam = [];
            this.props.proposal.secondaryOutcomeParam.push({});
        }

        return (
            <Stack verticalAlign="center" className={css('fullWidth', 'animate', 'stepPopIn')} verticalFill={true} tokens={{ childrenGap: 20 }}>
                <Stack verticalAlign="space-between" tokens={{ childrenGap: 5 }}>
                    {
                        this.props.isReadonly && <Label className="heading">Materials and Methods</Label>
                    }
                    {
                        !this.props.isReadonly && <h1>Materials and Methods</h1>
                    }
                </Stack>
                <Stack className="fullWidth">
                    <ProposalFormValidation {...this.props} fieldErrors={this.props.proposal.fieldErrors} ></ProposalFormValidation>
                </Stack>
                <Stack className="fullWidth" tokens={{ childrenGap: 10 }}>
                    <Label>Material and Methods</Label>
                    {
                        this.state.isAnimalOrClinical &&
                        <>
                            <TextField placeholder="Describe population and indication" required errorMessage={this.props.proposal.fieldErrors? this.props.proposal.fieldErrors[4]["materialMethode.populationIndication"]?.error : null} value={this.props.proposal.materialMethode?.populationIndication} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                onChange={(_event: any, newValue?: string) => {
                                    if (!this.props.proposal.materialMethode)
                                        this.props.proposal.materialMethode = {};
                                    this.props.proposal.materialMethode.populationIndication = newValue;
                                }}></TextField>
                            <TextField placeholder="Describe treatments" required errorMessage={this.props.proposal.fieldErrors? this.props.proposal.fieldErrors[4]["materialMethode.treatments"]?.error : null} value={this.props.proposal.materialMethode?.treatments} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                onChange={(_event: any, newValue?: string) => {
                                    if (!this.props.proposal.materialMethode)
                                        this.props.proposal.materialMethode = {};
                                    this.props.proposal.materialMethode.treatments = newValue;
                                }}></TextField>
                            <TextField placeholder="Describe experimental procedures and follow-ups" required errorMessage={this.props.proposal.fieldErrors? this.props.proposal.fieldErrors[4]["materialMethode.experimentalProcedures"]?.error : null} multiline rows={3} value={this.props.proposal.materialMethode?.experimentalProcedures} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                onChange={(_event: any, newValue?: string) => {
                                    if (!this.props.proposal.materialMethode)
                                        this.props.proposal.materialMethode = {};
                                    this.props.proposal.materialMethode.experimentalProcedures = newValue;
                                }}></TextField>
                        </>
                    }
                    {
                        !this.state.isAnimalOrClinical &&
                        <TextField placeholder="Please describe study procedures, including patient population, measurements, time-points of assessments, etc" required errorMessage={this.props.proposal.fieldErrors? this.props.proposal.fieldErrors[4]["materialMethode.materialExperimentalSetup"]?.error : null} multiline rows={3} value={this.props.proposal.materialMethode?.materialExperimentalSetup} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                            onChange={(_event: any, newValue?: string) => {
                                if (!this.props.proposal.materialMethode)
                                    this.props.proposal.materialMethode = {};
                                this.props.proposal.materialMethode.materialExperimentalSetup = newValue;
                            }}></TextField>
                    }

                </Stack>

                <Stack className="fullWidth" tokens={{ childrenGap: 10 }}>
                    <Label>Statistical methods</Label>
                    <TextField maxLength={2000} placeholder="Describe statistical methods" required errorMessage={this.props.proposal.fieldErrors? this.props.proposal.fieldErrors[4]["statisticalMethods"]?.error : null} multiline rows={3} value={this.props.proposal.statisticalMethods} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                        onChange={(_event: any, newValue?: string) => {
                            this.props.proposal.statisticalMethods = newValue;
                        }}></TextField>
                </Stack>
                <Stack>
                    <Label required>Primary outcome parameter</Label>
                    <Stack className="fullWidth" tokens={{ childrenGap: 10 }}>
                        <TextField placeholder="Parameter name (e.g. Marginal Bone Level, Implant Survival, etc.)" errorMessage={this.props.proposal.fieldErrors ? this.props.proposal.fieldErrors[3]["primaryOutcomeParam.name"]?.error : null} value={this.props.proposal.primaryOutcomeParam?.name} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                            onChange={(_event: any, newValue?: string) => {
                                if (!this.props.proposal.primaryOutcomeParam)
                                    this.props.proposal.primaryOutcomeParam = {};
                                this.props.proposal.primaryOutcomeParam.name = newValue;
                            }}></TextField>
                        <TextField placeholder="Describe parameter here (e.g. reliability, scale, etc.)" errorMessage={this.props.proposal.fieldErrors ? this.props.proposal.fieldErrors[3]["primaryOutcomeParam.description"]?.error : null} value={this.props.proposal.primaryOutcomeParam?.description} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                            onChange={(_event: any, newValue?: string) => {
                                if (!this.props.proposal.primaryOutcomeParam)
                                    this.props.proposal.primaryOutcomeParam = {};
                                this.props.proposal.primaryOutcomeParam.description = newValue;
                            }}></TextField>
                        <TextField placeholder="Baseline measurement (e.g. at loading)" errorMessage={this.props.proposal.fieldErrors ? this.props.proposal.fieldErrors[3]["primaryOutcomeParam.baseline"]?.error : null} value={this.props.proposal.primaryOutcomeParam?.baseline} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                            onChange={(_event: any, newValue?: string) => {
                                if (!this.props.proposal.primaryOutcomeParam)
                                    this.props.proposal.primaryOutcomeParam = {};
                                this.props.proposal.primaryOutcomeParam.baseline = newValue;
                            }}></TextField>
                        <TextField placeholder="Endpoint (e.g. 12 months after loading)" errorMessage={this.props.proposal.fieldErrors ? this.props.proposal.fieldErrors[3]["primaryOutcomeParam.endpoint"]?.error : null} value={this.props.proposal.primaryOutcomeParam?.endpoint} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                            onChange={(_event: any, newValue?: string) => {
                                if (!this.props.proposal.primaryOutcomeParam)
                                    this.props.proposal.primaryOutcomeParam = {};
                                this.props.proposal.primaryOutcomeParam.endpoint = newValue;
                            }}></TextField>
                    </Stack>
                </Stack>

                <fieldset>
                    <legend>Secondary outcome parameters</legend>
                    <Stack tokens={{ childrenGap: 10 }}>
                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                            <Stack className="fullWidth" tokens={{ childrenGap: 10 }}>
                                <TextField placeholder="Parameter name (e.g. Marginal Bone Level, Implant Survival, etc.)" value={this.props.proposal.secondaryOutcomeParam[0].name} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                    errorMessage={this.props.proposal.fieldErrors && this.props.proposal.fieldErrors[3]["secondaryOutcomeParam"]?.childErrors ? this.props.proposal.fieldErrors[3]["secondaryOutcomeParam"]?.childErrors[`secondaryOutcomeParam_name_0`]?.error : null}
                                    onChange={(_event: any, newValue?: string) => {
                                        if (this.props.proposal.secondaryOutcomeParam)
                                            this.props.proposal.secondaryOutcomeParam[0].name = newValue;
                                    }}></TextField>
                                <TextField placeholder="Describe parameter here (e.g. reliability, scale, etc.)" value={this.props.proposal.secondaryOutcomeParam[0].description} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                    errorMessage={this.props.proposal.fieldErrors && this.props.proposal.fieldErrors[3]["secondaryOutcomeParam"]?.childErrors ? this.props.proposal.fieldErrors[3]["secondaryOutcomeParam"]?.childErrors[`secondaryOutcomeParam_description_0`]?.error : null}
                                    onChange={(_event: any, newValue?: string) => {
                                        if (this.props.proposal.secondaryOutcomeParam)
                                            this.props.proposal.secondaryOutcomeParam[0].description = newValue;
                                    }}></TextField>
                                <TextField placeholder="Baseline measurement (e.g. at loading)" value={this.props.proposal.secondaryOutcomeParam[0].baseline} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                    errorMessage={this.props.proposal.fieldErrors && this.props.proposal.fieldErrors[3]["secondaryOutcomeParam"]?.childErrors ? this.props.proposal.fieldErrors[3]["secondaryOutcomeParam"]?.childErrors[`secondaryOutcomeParam_baseline_0`]?.error : null}
                                    onChange={(_event: any, newValue?: string) => {
                                        if (this.props.proposal.secondaryOutcomeParam)
                                            this.props.proposal.secondaryOutcomeParam[0].baseline = newValue;
                                    }}></TextField>
                                <TextField placeholder="Endpoint (e.g. 12 months after loading)" value={this.props.proposal.secondaryOutcomeParam[0].endpoint} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                    errorMessage={this.props.proposal.fieldErrors && this.props.proposal.fieldErrors[3]["secondaryOutcomeParam"]?.childErrors ? this.props.proposal.fieldErrors[3]["secondaryOutcomeParam"]?.childErrors[`secondaryOutcomeParam_endpoint_0`]?.error : null}
                                    onChange={(_event: any, newValue?: string) => {
                                        if (this.props.proposal.secondaryOutcomeParam)
                                            this.props.proposal.secondaryOutcomeParam[0].endpoint = newValue;
                                    }}></TextField>
                            </Stack>
                            {
                                !this.props.isReadonly &&
                                <IconButton iconProps={{ iconName: 'Add' }}
                                    title="Add" ariaLabel="Add" onClick={() => {
                                        this.props.proposal.secondaryOutcomeParam?.push({});
                                    }} />
                            }
                        </Stack>
                        {
                            this.props.proposal.secondaryOutcomeParam?.map((item, index) => {
                                if (index != 0) {
                                    return <div key={`secOutcomeParam_${index}`}>
                                        <hr className="fullWidth" />
                                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                                            <Stack className="fullWidth" tokens={{ childrenGap: 10 }}>
                                                <TextField placeholder="Parameter name (e.g. Marginal Bone Level, Implant Survival, etc.)" value={item.name || ''} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                                    errorMessage={this.props.proposal.fieldErrors && this.props.proposal.fieldErrors[3]["secondaryOutcomeParam"]?.childErrors ? this.props.proposal.fieldErrors[3]["secondaryOutcomeParam"]?.childErrors[`secondaryOutcomeParam_name_${index}`]?.error : null}
                                                    onChange={(_event: any, newValue?: string) => {
                                                        item.name = newValue;
                                                    }}></TextField>
                                                <TextField placeholder="Describe parameter here (e.g. reliability, scale, etc.)" value={item.description || ''} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                                    errorMessage={this.props.proposal.fieldErrors && this.props.proposal.fieldErrors[3]["secondaryOutcomeParam"]?.childErrors ? this.props.proposal.fieldErrors[3]["secondaryOutcomeParam"]?.childErrors[`secondaryOutcomeParam_description_${index}`]?.error : null}
                                                    onChange={(_event: any, newValue?: string) => {
                                                        item.description = newValue;
                                                    }}></TextField>
                                                <TextField placeholder="Baseline measurement (e.g. at loading)" value={item.baseline || ''} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                                    errorMessage={this.props.proposal.fieldErrors && this.props.proposal.fieldErrors[3]["secondaryOutcomeParam"]?.childErrors ? this.props.proposal.fieldErrors[3]["secondaryOutcomeParam"]?.childErrors[`secondaryOutcomeParam_baseline_${index}`]?.error : null}
                                                    onChange={(_event: any, newValue?: string) => {
                                                        item.baseline = newValue;
                                                    }}></TextField>
                                                <TextField placeholder="Endpoint (e.g. 12 months after loading)" value={item.endpoint || ''} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                                    errorMessage={this.props.proposal.fieldErrors && this.props.proposal.fieldErrors[3]["secondaryOutcomeParam"]?.childErrors ? this.props.proposal.fieldErrors[3]["secondaryOutcomeParam"]?.childErrors[`secondaryOutcomeParam_endpoint_${index}`]?.error : null}
                                                    onChange={(_event: any, newValue?: string) => {
                                                        item.endpoint = newValue;
                                                    }}></TextField>
                                            </Stack>
                                            {
                                                !this.props.isReadonly &&
                                                <IconButton iconProps={{ iconName: 'Remove' }}
                                                    title="Remove" ariaLabel="Remove" onClick={() => {
                                                        this.props.proposal.secondaryOutcomeParam?.splice(index, 1);
                                                    }} />
                                            }
                                        </Stack>
                                    </div>;
                                }
                            })
                        }
                    </Stack>
                </fieldset>

                {
                    this.props.showWizardNavigation && <StackItem className={css("wizardNavigationContainer")}>
                        <WizardNavigationButtons {...this.props} step={this.props.currentStep} save={this.props.onSave}></WizardNavigationButtons>
                    </StackItem>
                }
            </Stack>
        );

    }
}