import * as React from 'react';
import { css, Stack, PrimaryButton, Text, getTheme, INavLinkGroup, Nav, IStackProps, INavLink, TextField, Label, IconButton, StackItem, ActionButton } from 'office-ui-fabric-react';
import '../Proposal.scss';
import { IFormProps } from '../Models/IFormProps';
import { observer } from 'mobx-react';
import { WizardNavigationButtons } from './WizardNavigationFooter';
import { StudyTeam } from './StudyTeam';
import { StudyDescription } from './StudyDescription';
import { StudyPower } from './StudyPower';
import { StudyIntervensions } from './StudyIntervensions';
import { MaterialsAndMethodes } from './MaterialsAndMethodes';
import { RequestedSupport } from './RequestedSupport';
import { ProjectPlan } from './ProjectPlan';
import { Attachments } from './Attachments';
import { pdfCreator } from '../../pdfGenerator/pdfCreator';
import { IProposal } from '../Models/IProposal';

export interface ISummaryProps extends IFormProps {
    onSubmit: (par:any) => any | void | Promise<any>;
}

export interface ISummaryState {
}

@observer
export class Summary extends React.Component<ISummaryProps, ISummaryState> {

    constructor(props: any) {
        super(props);
        this.state = {
        }
    }

    public componentDidMount() {
    }

    public render() {

        return (
            <Stack verticalAlign="center" className={css('fullWidth', 'animate', 'stepPopIn', 'RSMSummary')} verticalFill={true} tokens={{ childrenGap: 20 }}>
                <Stack verticalAlign="space-between" tokens={{ childrenGap: 5 }}>
                    <h1>Summary</h1>
                </Stack>



                {this.props.proposalStore &&
                    <Stack className="fullWidth" tokens={{ childrenGap: 10 }}>
                        <StudyTeam currentStep={1} proposal={this.props.proposalStore.proposal}
                            isReadonly={true}
                            showWizardNavigation={false}></StudyTeam>
                        <hr className="fullWidth" />
                        <StudyDescription
                            currentStep={2}
                            proposal={this.props.proposalStore.proposal}
                            isReadonly={true}
                            typeOfStudyList={this.props.proposalStore.typeOfStudyList}
                            studyDesignAList={this.props.proposalStore.studyDesignAList}
                            studyDesignBList={this.props.proposalStore.studyDesignBList}
                            studyDesignCList={this.props.proposalStore.studyDesignCList}
                            fieldsList={this.props.proposalStore.fieldsList}
                            showWizardNavigation={false}></StudyDescription>
                        <hr className="fullWidth" />
                        <StudyPower currentStep={3} proposal={this.props.proposalStore.proposal}
                            isReadonly={true}
                            showWizardNavigation={false}></StudyPower>
                        <hr className="fullWidth" />
                        <StudyIntervensions currentStep={4} proposal={this.props.proposalStore.proposal}
                            isReadonly={true}
                            showWizardNavigation={false}></StudyIntervensions>
                        <hr className="fullWidth" />
                        <MaterialsAndMethodes currentStep={5} proposal={this.props.proposalStore.proposal}
                            isReadonly={true}
                            showWizardNavigation={false}></MaterialsAndMethodes>
                        <hr className="fullWidth" />
                        <RequestedSupport currentStep={6} proposal={this.props.proposalStore.proposal}
                            isReadonly={true} proposalStore={this.props.proposalStore}
                            showWizardNavigation={false}></RequestedSupport>
                        <hr className="fullWidth" />
                    <ProjectPlan typeOfStudyList={this.props.proposalStore.typeOfStudyList} currentStep={7} proposal={this.props.proposalStore.proposal}
                            isReadonly={true}
                            showWizardNavigation={false}></ProjectPlan>
                        <hr className="fullWidth" />
                        <Attachments currentStep={8} proposal={this.props.proposalStore.proposal}
                            isReadonly={true} proposalStore={this.props.proposalStore}
                            showWizardNavigation={false}></Attachments>
                    </Stack>
                }
                <div>
                    <ActionButton className="straumannBlueButton" iconProps={{ iconName: 'DownloadDocument' }} onClick={() => {
                        console.log("click");
                        //alert("click");
                        let copy = JSON.parse(JSON.stringify(this.props.proposalStore.proposal));
                        this.props.onSave().then(() => {
                            pdfCreator.createFile(copy, false);
                        //window.location.reload();

                        });
                    }}>Download pdf</ActionButton>
                </div>
                {
                    this.props.showWizardNavigation && <Stack>
                        <StackItem className={css("wizardNavigationContainer")}>
                            <WizardNavigationButtons {...this.props} step={this.props.currentStep} save={this.props.onSave} submit={this.props.onSubmit}></WizardNavigationButtons>
                        </StackItem>
                    </Stack>
                }
            </Stack>
        );

    }
}