import * as React from 'react';
import { css, Stack, PrimaryButton, Text, getTheme, INavLinkGroup, Nav, IStackProps, INavLink, TextField, Label, IconButton, IDropdownOption, Dropdown, IDropdownStyles, Toggle, ChoiceGroup, IChoiceGroupOption, StackItem, Dialog, Button, Modal, List, DetailsList, Selection, SelectionMode, DetailsListLayoutMode, Spinner, ISelection, IObjectWithKey, ISelectionOptions, IRenderFunction, IDetailsRowProps, DetailsRow, Checkbox, ScrollablePane, ScrollbarVisibility, IDetailsHeaderProps, IDetailsColumnRenderTooltipProps, TooltipHost, Sticky, StickyPositionType, DefaultButton, IRefObject, ITextField, mergeStyles } from 'office-ui-fabric-react';
import '../Proposal.scss';
import { IFormProps } from '../Models/IFormProps';
import { observer } from 'mobx-react';
import { WizardNavigationButtons } from './WizardNavigationFooter';
import { MaterialSearch } from '../../../dataprovider/MaterialSearch';
import { IMaterial } from '../Models/IMaterial';
import { IMaterialSupportItem } from '../Models/IProposal';
import { FilterBox } from './FilterComponent/FilterBox';
import ProposalFormValidation from './ProposalFormValidation';

export interface IRequestedSupportProps extends IFormProps {
}

export interface IRequestedSupportState {
    isFundsRequested?: boolean;
    isStudyMaterialRequested?: boolean;
    showDisc: boolean;
    isSearchDialogOpen: boolean;
    showSearchButton: boolean[];

    txtMatSearchValue: string;
    matSearchResults: IMaterial[];
    isSearching: boolean;
    selectedMaterial: any[];
    searchItemIndex: number;
    resultFilter: string;
    filteredSearchResults: IMaterial[];
    isFulltextSearch: boolean;

    searchError: string;
}

@observer
export class RequestedSupport extends React.Component<IRequestedSupportProps, IRequestedSupportState> {
    private materialSelection: ISelection<IObjectWithKey>;
    private matSearchField: IRefObject<ITextField>;

    constructor(props: any) {
        super(props);
        this.state = {
            isFundsRequested: false,
            isStudyMaterialRequested: false,
            showDisc: false,
            isSearchDialogOpen: false,
            showSearchButton: [true],

            txtMatSearchValue: null,
            matSearchResults: null,
            isSearching: false,
            selectedMaterial: null,
            searchItemIndex: null,
            resultFilter: null,
            filteredSearchResults: null,
            isFulltextSearch: false,

            searchError: null
        }

        this.materialSelection = new Selection({
            canSelectItem: (item: IObjectWithKey, index?: number) => {
                return true;
            },
            onSelectionChanged: () => {
                console.log("Selection changed");
                this.setState({ selectedMaterial: this.materialSelection.getSelection() });
                console.dir(this.materialSelection.getSelection());
            }
        });

        this.matSearchField = React.createRef<ITextField>();
    }

    public componentDidMount() {
        this.setState({
            isFundsRequested: this.props.proposal.fundsRequested ? this.props.proposal.fundsRequested : false,
            isStudyMaterialRequested: this.props.proposal.materialSupportRequested ? this.props.proposal.materialSupportRequested : false,
            showDisc: this.props.proposal.typeOfStudy === "In-vitro"
        });
    }

    private onFundsRequestedChange = (ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
        this.setState({ isFundsRequested: checked }, () => {
            this.props.proposal.fundsRequested = this.state.isFundsRequested;
        });
    }

    private onStudyMaterialRequestedChange = (ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
        this.setState({ isStudyMaterialRequested: checked }, () => {
            this.props.proposal.materialSupportRequested = this.state.isStudyMaterialRequested;
        });
    }

    private onChoiceGroupChange = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption): void => {
        if (option) {
            if (!this.props.proposal.funds)
                this.props.proposal.funds = {};
            this.props.proposal.funds.currency = option.key;
        }
    }

    private setShowSearch = (idx: number, value: boolean) => {
        this.setState((prevState, props) => {
            let newShowSearch = [...prevState.showSearchButton];
            newShowSearch[idx] = value;
            return {
                showSearchButton: newShowSearch
            };
        });
    };

    private onKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            this.searchMaterial();
        }
    }

    public render() {
        const columnProps0: Partial<IStackProps> = {
            tokens: { childrenGap: 10 },
            styles: { root: { width: "6%" } },
        };
        const columnProps1: Partial<IStackProps> = {
            tokens: { childrenGap: 10 },
            styles: { root: { width: "20%" } },
        };

        const columnProps2: Partial<IStackProps> = {
            tokens: { childrenGap: 10 },
            styles: { root: { width: "30%" } },
        };

        const columnProps3: Partial<IStackProps> = {
            tokens: { childrenGap: 10 },
            styles: { root: { width: "50%" } },
        };

        const columnProps4: Partial<IStackProps> = {
            tokens: { childrenGap: 10 },
            styles: { root: { width: "70%" } },
        };

        const columnProps5: Partial<IStackProps> = {
            tokens: { childrenGap: 10 },
            styles: { root: { width: "80%" } },
        };

        const columnProps6: Partial<IStackProps> = {
            tokens: { childrenGap: 10 },
            styles: { root: { width: "10%" } },
        };

        const columnProps7: Partial<IStackProps> = {
            tokens: { childrenGap: 10 },
            styles: { root: { width: "60%" } },
        };
        const columnPropsbtn: Partial<IStackProps> = {
            tokens: { childrenGap: 10 },
            styles: { root: { width: '32px' } },
        };

        if (!this.props.proposal.funds) {
            this.props.proposal.funds = {};
        }
        if (!this.props.proposal.funds?.costItems || this.props.proposal.funds.costItems.length == 0) {
            this.props.proposal.funds.costItems = [];
            this.props.proposal.funds.costItems.push({});
        }

        if (!this.props.proposal.materialSupport || this.props.proposal.materialSupport.length == 0) {
            this.props.proposal.materialSupport = [];
            this.props.proposal.materialSupport.push({});
        }

        if (!this.props.proposal.disc || this.props.proposal.disc.length == 0) {
            this.props.proposal.disc = [];
            this.props.proposal.disc.push({});
        }

        const styleError: string = mergeStyles({
            fontFamily: 'Segoe UI, Segoe UI Web (West European), Segoe UI, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif',
            fontSize: '12px',
            fontWeight: 400,
            color: 'rgb(164, 38, 44)',
            margin: '0px',
            paddingTop: '5px',
            display: 'flex',
            alignItems: 'center'
        });

        return (
            <Stack verticalAlign="center" className={css('fullWidth', 'animate', 'stepPopIn')} verticalFill={true} tokens={{ childrenGap: 20 }}>
                <Stack verticalAlign="space-between" tokens={{ childrenGap: 5 }}>
                    {
                        this.props.isReadonly && <Label className="heading">Requested Support</Label>
                    }
                    {
                        !this.props.isReadonly && <h1>Requested Support</h1>
                    }
                </Stack>
                <Stack className="fullWidth">
                    <ProposalFormValidation {...this.props} fieldErrors={this.props.proposal.fieldErrors} ></ProposalFormValidation>
                </Stack>
                <Stack className="fullWidth" tokens={{ childrenGap: 10 }}>
                    {
                        this.props.isReadonly &&
                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                            <Label>Funds requested</Label>
                            <Label>{this.props.proposal.fundsRequested === true ? "Yes" : "No"}</Label>
                        </Stack>
                    }
                    {
                        !this.props.isReadonly && <Toggle label="Funds requested" inlineLabel onText="Yes" offText="No" checked={this.props.proposal.fundsRequested} onChange={this.onFundsRequestedChange} />
                    }

                    {
                        this.state.isFundsRequested &&
                        <>
                            <Stack horizontal tokens={{ childrenGap: 10 }}>
                                <Label>Please state your currency</Label>
                                {
                                    this.props.isReadonly &&
                                    <Label required>{this.props.proposal.funds?.currency}</Label>
                                }
                                <Stack>                                    
                                {
                                    !this.props.isReadonly && <ChoiceGroup
                                        selectedKey={this.props.proposal.funds?.currency}
                                        defaultSelectedKey="usd"
                                        className="inlineflex"
                                        options={[
                                            {
                                                key: 'USD',
                                                text: 'USD'
                                            },
                                            {
                                                key: 'CHF',
                                                text: 'CHF'
                                            },
                                            {
                                                key: 'EUR',
                                                text: 'EUR'
                                            }
                                        ]}
                                        onChange={this.onChoiceGroupChange}                
                                    />
                                }
                                    {
                                        !this.props.isReadonly && <Label className={css(styleError, 'rsmError')} style={{paddingLeft: '10px', marginTop: '-10px'}}>{this.props.proposal.fieldErrors ? this.props.proposal.fieldErrors[5]["funds.currency"]?.error : null}</Label>
                                    }
                                </Stack>
                            </Stack>                            
                            <fieldset>
                                <Stack className="fullWidth" tokens={{ childrenGap: 10 }}>
                                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                                        <Stack {...columnProps5}>
                                        <Label>Cost type / item</Label>
                                        </Stack>
                                        <Stack {...columnProps1}>
                                        <Label>Requested funds {this.props.proposal.funds?.currency ? "(" + this.props.proposal.funds?.currency + ")" : ""}</Label>
                                        </Stack>
                                        <Stack {...columnPropsbtn}></Stack>
                                    </Stack>
                                </Stack>
                                
                                <Stack className="fullWidth" tokens={{ childrenGap: 10 }}>
                                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                                        <Stack {...columnProps5}>
                                            <TextField placeholder="Cost type / item (e.g. CBCT, patient stipend, nournal costs, other direct costs)"
                                                value={this.props.proposal.funds.costItems[0].name}
                                                required errorMessage={this.props.proposal.fieldErrors && this.props.proposal.fieldErrors[5]["funds.costItems"]?.childErrors ? this.props.proposal.fieldErrors[5]["funds.costItems"]?.childErrors["costItems_name_0"]?.error : null}
                                                readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                                onChange={(_event: any, newValue?: string) => {
                                                    if (this.props.proposal.funds?.costItems)
                                                        this.props.proposal.funds.costItems[0].name = newValue;
                                                }}></TextField>
                                        </Stack>
                                        <Stack {...columnProps1}>
                                            <TextField placeholder={"Requested funds " + (this.props.proposal.funds?.currency ? "(" + this.props.proposal.funds?.currency + ")" : "" )} type="number"  value={this.props.proposal.funds.costItems[0].budget?.toString()}
                                                required errorMessage={this.props.proposal.fieldErrors && this.props.proposal.fieldErrors[5]["funds.costItems"]?.childErrors ? this.props.proposal.fieldErrors[5]["funds.costItems"]?.childErrors["costItems_budget_0"]?.error : null}
                                                readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                                onChange={(_event: any, newValue?: string) => {
                                                    if (this.props.proposal.funds?.costItems)
                                                        this.props.proposal.funds.costItems[0].budget = Number(newValue);
                                                    this.props.proposalStore?.calculateFunds();
                                                }}></TextField>
                                        </Stack>
                                        {
                                            !this.props.isReadonly &&
                                            <IconButton iconProps={{ iconName: 'Add' }}
                                                title="Add" ariaLabel="Add" onClick={() => {
                                                    this.props.proposal.funds?.costItems?.push({});
                                                }} />
                                        }
                                    </Stack>
                                    {
                                        this.props.proposal.funds.costItems?.map((item, index) => {
                                            if (index != 0) {
                                                return <Stack key={`CostItem_${index}`} horizontal tokens={{ childrenGap: 10 }}>
                                                    <Stack {...columnProps5}>
                                                        <TextField placeholder="Cost type / item (e.g. CBCT, patient stipend, nournal costs, other direct costs)" value={item.name || ''}
                                                            required errorMessage={this.props.proposal.fieldErrors && this.props.proposal.fieldErrors[5]["funds.costItems"]?.childErrors ? this.props.proposal.fieldErrors[5]["funds.costItems"]?.childErrors[`costItems_name_${index}`]?.error : null}
                                                            readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                                            onChange={(_event: any, newValue?: string) => {
                                                                item.name = newValue;
                                                            }}></TextField>
                                                    </Stack>
                                                    <Stack {...columnProps1}>
                                                        <TextField placeholder="Requested funds" type="number" value={item.budget?.toString() || ''}
                                                            required errorMessage={this.props.proposal.fieldErrors && this.props.proposal.fieldErrors[5]["funds.costItems"]?.childErrors ? this.props.proposal.fieldErrors[5]["funds.costItems"]?.childErrors[`costItems_budget_${index}`]?.error : null}
                                                            readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                                            onChange={(_event: any, newValue?: string) => {
                                                                item.budget = Number(newValue);
                                                                this.props.proposalStore?.calculateFunds();
                                                            }}></TextField>
                                                    </Stack>
                                                    {
                                                        !this.props.isReadonly &&
                                                        <IconButton iconProps={{ iconName: 'Remove' }}
                                                            title="Remove" ariaLabel="Remove" onClick={() => {
                                                                this.props.proposal.funds?.costItems?.splice(index, 1);
                                                                this.props.proposalStore?.calculateFunds();
                                                            }} />
                                                    }
                                                </Stack>;
                                            }
                                        })
                                    }
                                </Stack>
                            </fieldset>
                            

                            <Stack className="fullWidth" tokens={{ childrenGap: 10 }}>
                                <Stack horizontal tokens={{ childrenGap: 10 }}>
                                    <Stack {...columnProps5}>
                                        <Label className="titleLabel">Overhead costs</Label>
                                    </Stack>
                                    <Stack {...columnProps1}>
                                        <Label>Total {this.props.proposal.funds?.currency ? "(" + this.props.proposal.funds?.currency + ")" : ""}</Label>
                                    </Stack>
                                </Stack>
                            </Stack>

                            <Stack horizontal tokens={{ childrenGap: 10 }}>
                                <Stack {...columnProps3} horizontal>
                                    <Label>% of required overhead / administrative costs</Label>
                                </Stack>
                                <Stack {...columnProps3} horizontal horizontalAlign="end">
                                    <TextField placeholder="%" type="number" value={this.props.proposal.funds.overheadCosts?.percent?.toString()}
                                        //value={this.props.proposal.materialSupport[0].description}
                                        errorMessage={this.props.proposal.fieldErrors ? this.props.proposal.fieldErrors[5]["funds.overheadCosts.percent"]?.error : null}
                                        readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                        onChange={(_event: any, newValue?: string) => {
                                            if (!this.props.proposal.funds)
                                                this.props.proposal.funds = {};

                                            if (!this.props.proposal.funds.overheadCosts)
                                                this.props.proposal.funds.overheadCosts = {};

                                            this.props.proposal.funds.overheadCosts.percent = Number(newValue);
                                            this.props.proposalStore?.calculateFunds();
                                        }}></TextField>
                                    <TextField readOnly disabled value={this.props.proposal.funds.overheadCosts?.amount?.toString()}></TextField>
                                </Stack>

                            </Stack>
                            <Stack horizontal tokens={{ childrenGap: 10 }}>
                                <Stack {...columnProps3} horizontal>
                                    <Label className="titleLabel">Total requested funds</Label>
                                </Stack>
                                <Stack {...columnProps3} horizontal horizontalAlign="end">
                                    <TextField readOnly disabled value={this.props.proposal.funds.overheadCosts?.total?.toString()}></TextField>
                                </Stack>
                            </Stack>
                        </>
                    }
                </Stack>

                <Stack className="fullWidth" tokens={{ childrenGap: 10 }}>
                    {
                        this.props.isReadonly &&
                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                            <Label>Study Material requested</Label>
                            <Label>{this.props.proposal.materialSupportRequested === true ? "Yes" : "No"}</Label>
                        </Stack>
                    }
                    {
                        !this.props.isReadonly && <Toggle label="Study Material requested" inlineLabel onText="Yes" offText="No" checked={this.props.proposal.materialSupportRequested} onChange={this.onStudyMaterialRequestedChange} />
                    }

                    {
                        this.state.isStudyMaterialRequested &&
                        <>
                            <fieldset>
                                <legend>Requested material support</legend>
                                <Stack className="fullWidth" tokens={{ childrenGap: 10 }}>
                                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                                        {
                                            !this.props.isReadonly &&
                                            <Stack {...columnProps0}>
                                                <div style={{ width: '24px', height: '32px', marginLeft: '10px' }}>
                                                    <IconButton
                                                        // styles={{ root: { marginLeft: '10px' } }}
                                                        iconProps={{ iconName: "Search" }}
                                                        onClick={() => { this.setState({ isSearchDialogOpen: true, searchItemIndex: 0, txtMatSearchValue: this.props.proposal.materialSupport[0].description || this.props.proposal.materialSupport[0].articelNo || this.state.txtMatSearchValue }); }}
                                                        hidden={!this.state.showSearchButton[0]}
                                                    />
                                                </div>
                                            </Stack>
                                        }
                                        <Stack {...columnProps7}>
                                            <TextField placeholder="Article description" value={this.props.proposal.materialSupport[0].description}
                                                required errorMessage={this.props.proposal.fieldErrors && this.props.proposal.fieldErrors[5]["materialSupport"] && this.props.proposal.fieldErrors[5]["materialSupport"]?.childErrors ? this.props.proposal.fieldErrors[5]["materialSupport"]?.childErrors[`materialSupport_description_0`]?.error : null}
                                                autoComplete="off"
                                                readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                                onChange={(_event: any, newValue?: string) => {
                                                    if (this.props.proposal.materialSupport) {
                                                        this.props.proposal.materialSupport[0].description = newValue;
                                                        this.setShowSearch(0, !(newValue && newValue.length !== 0 && this.props.proposal.materialSupport[0].articelNo));
                                                    }
                                                }}></TextField>
                                        </Stack>
                                        <Stack {...columnProps1}>
                                            <TextField placeholder="Article number" value={this.props.proposal.materialSupport[0].articelNo}
                                                autoComplete="off"
                                                readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                                onChange={(_event: any, newValue?: string) => {
                                                    if (this.props.proposal.materialSupport) {
                                                        this.props.proposal.materialSupport[0].articelNo = newValue;
                                                        this.setShowSearch(0, !(newValue && newValue.length !== 0 && this.props.proposal.materialSupport[0].description));
                                                    }
                                                }}></TextField>
                                        </Stack>
                                        <Stack {...columnProps6}>
                                            <TextField placeholder="Qty" type="number" value={this.props.proposal.materialSupport[0].quantity}
                                                required errorMessage={this.props.proposal.fieldErrors && this.props.proposal.fieldErrors[5]["materialSupport"] && this.props.proposal.fieldErrors[5]["materialSupport"]?.childErrors ? this.props.proposal.fieldErrors[5]["materialSupport"]?.childErrors[`materialSupport_quantity_0`]?.error : null}
                                                autoComplete="off"
                                                readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                                onChange={(_event: any, newValue?: string) => {
                                                    if (this.props.proposal.materialSupport) {
                                                        this.props.proposal.materialSupport[0].quantity = newValue;
                                                    }
                                                }}></TextField>
                                        </Stack>
                                        {
                                            !this.props.isReadonly &&
                                            <IconButton className="hiddenButton" title="" onClick={() => { }} disabled={true} styles={{ root: { backgroundColor: 'white !important'}}} />
                                        }
                                        {
                                            !this.props.isReadonly &&
                                            <IconButton iconProps={{ iconName: 'Add' }}
                                                title="Add" ariaLabel="Add" onClick={() => {
                                                    this.props.proposal.materialSupport?.push({});
                                                    this.setState((prevState) => { return { showSearchButton: [...prevState.showSearchButton, true] }; })
                                                }} />
                                        }
                                    </Stack>
                                    {
                                        this.props.proposal.materialSupport?.map((item, index) => {
                                            if (index != 0) {
                                                return <Stack key={`Mat${index}`} horizontal tokens={{ childrenGap: 10 }}>
                                                    {!this.props.isReadonly &&
                                                        <Stack {...columnProps0}>
                                                            <div style={{ width: '24px', height: '32px', marginLeft: '10px' }}>
                                                                <IconButton
                                                                    // styles={{ root: { marginLeft: '10px' } }}
                                                                    iconProps={{ iconName: "Search" }}
                                                                    onClick={() => { this.setState({ isSearchDialogOpen: true, searchItemIndex: index, txtMatSearchValue: this.props.proposal.materialSupport[index].description || this.props.proposal.materialSupport[index].articelNo || this.state.txtMatSearchValue }); }}
                                                                    hidden={!this.state.showSearchButton[index]}
                                                                />
                                                            </div>
                                                        </Stack>
                                                    }
                                                    <Stack {...columnProps7}>
                                                        <TextField placeholder="Article description" value={item.description || ''}
                                                            required errorMessage={this.props.proposal.fieldErrors && this.props.proposal.fieldErrors[5]["materialSupport"] && this.props.proposal.fieldErrors[5]["materialSupport"]?.childErrors ? this.props.proposal.fieldErrors[5]["materialSupport"]?.childErrors[`materialSupport_description_${index}`]?.error : null}
                                                            autoComplete="off"
                                                            readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                                            onChange={(_event: any, newValue?: string) => {
                                                                item.description = newValue;
                                                                this.setShowSearch(index, !(newValue && newValue.length !== 0 && this.props.proposal.materialSupport[index].articelNo));
                                                            }}></TextField>
                                                    </Stack>
                                                    <Stack {...columnProps1}>
                                                        <TextField placeholder="Article number" value={item.articelNo || ''}
                                                            
                                                            autoComplete="off"
                                                            readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                                            onChange={(_event: any, newValue?: string) => {
                                                                item.articelNo = newValue;
                                                                this.setShowSearch(index, !(newValue && newValue.length !== 0 && this.props.proposal.materialSupport[index].description));
                                                            }}></TextField>
                                                    </Stack>
                                                    <Stack {...columnProps6}>
                                                        <TextField placeholder="Qty" value={item.quantity || ''}
                                                            required errorMessage={this.props.proposal.fieldErrors && this.props.proposal.fieldErrors[5]["materialSupport"] && this.props.proposal.fieldErrors[5]["materialSupport"]?.childErrors ? this.props.proposal.fieldErrors[5]["materialSupport"]?.childErrors[`materialSupport_quantity_${index}`]?.error : null}
                                                            autoComplete="off"
                                                            readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                                            onChange={(_event: any, newValue?: string) => {
                                                                item.quantity = newValue;
                                                            }}></TextField>
                                                    </Stack>
                                                    {
                                                        !this.props.isReadonly &&
                                                        <IconButton iconProps={{ iconName: 'Delete' }}
                                                            title="Remove" ariaLabel="Remove" onClick={() => {
                                                                this.props.proposal.materialSupport?.splice(index, 1);
                                                                this.state.showSearchButton?.splice(index, 1);
                                                            }} />
                                                    }
                                                    {
                                                        !this.props.isReadonly &&
                                                        <IconButton iconProps={{ iconName: 'Add' }}
                                                            title="Add" ariaLabel="Add" onClick={() => {
                                                                this.props.proposal.materialSupport?.push({});
                                                                this.setState((prevState) => { return { showSearchButton: [...prevState.showSearchButton, true] }; })
                                                            }} />
                                                    }
                                                </Stack>;
                                            }
                                        })
                                    }
                                </Stack>
                            </fieldset>

                            {
                                this.state.showDisc && <fieldset>
                                    <legend>Discs for in vitro studies</legend>
                                    <Stack className="fullWidth" tokens={{ childrenGap: 10 }}>
                                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                                            <Stack {...columnProps7}>
                                                <TextField placeholder="Type" value={this.props.proposal.disc[0].type}
                                                    readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                                    onChange={(_event: any, newValue?: string) => {
                                                        if (this.props.proposal.disc)
                                                            this.props.proposal.disc[0].type = newValue;
                                                    }}></TextField>
                                            </Stack>
                                            <Stack {...columnProps1}>
                                                <TextField placeholder="Dimensions" value={this.props.proposal.disc[0].dimention}
                                                    readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                                    onChange={(_event: any, newValue?: string) => {
                                                        if (this.props.proposal.disc)
                                                            this.props.proposal.disc[0].dimention = newValue;
                                                    }}></TextField>
                                            </Stack>
                                            <Stack {...columnProps1}>
                                                <TextField placeholder="Quantiy" value={this.props.proposal.disc[0].quantity}
                                                    readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                                    onChange={(_event: any, newValue?: string) => {
                                                        if (this.props.proposal.disc)
                                                            this.props.proposal.disc[0].quantity = newValue;
                                                    }}></TextField>
                                            </Stack>
                                            {
                                                !this.props.isReadonly &&
                                                <IconButton iconProps={{ iconName: 'Add' }}
                                                    title="Add" ariaLabel="Add" onClick={() => {
                                                        this.props.proposal.disc?.push({});
                                                    }} />
                                            }
                                        </Stack>
                                        {
                                            this.props.proposal.disc?.map((item, index) => {
                                                if (index != 0) {
                                                    return <Stack horizontal tokens={{ childrenGap: 10 }}>
                                                        <Stack {...columnProps7}>
                                                            <TextField placeholder="Type" value={item.type || ''}
                                                                readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                                                onChange={(_event: any, newValue?: string) => {
                                                                    item.type = newValue;
                                                                }}></TextField>
                                                        </Stack>
                                                        <Stack {...columnProps1}>
                                                            <TextField placeholder="Dimentions" value={item.dimention || ''}
                                                                readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                                                onChange={(_event: any, newValue?: string) => {
                                                                    item.dimention = newValue;
                                                                }}></TextField>
                                                        </Stack>
                                                        <Stack {...columnProps1}>
                                                            <TextField placeholder="Quantiy" value={item.quantity || ''}
                                                                readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                                                onChange={(_event: any, newValue?: string) => {
                                                                    item.quantity = newValue;
                                                                }}></TextField>
                                                        </Stack>
                                                        {
                                                            !this.props.isReadonly &&
                                                            <IconButton iconProps={{ iconName: 'Remove' }}
                                                                title="Remove" ariaLabel="Remove" onClick={() => {
                                                                    this.props.proposal.disc?.splice(index, 1);
                                                                }} />
                                                        }
                                                    </Stack>;
                                                }
                                            })
                                        }
                                    </Stack>
                                </fieldset>
                            }
                        </>
                    }
                </Stack>

                {
                    this.props.showWizardNavigation && <StackItem className={css("wizardNavigationContainer")}>
                        <WizardNavigationButtons {...this.props} step={this.props.currentStep} save={this.props.onSave}></WizardNavigationButtons>
                    </StackItem>
                }
                <Modal
                    isBlocking={true}
                    isOpen={this.state.isSearchDialogOpen}
                    onDismiss={() => { this.setState({ isSearchDialogOpen: false, selectedMaterial: null, searchItemIndex: null }); }}
                >
                    <div style={{ width: '600px', padding: '15px', position: 'relative' }}>
                        <IconButton styles={{ root: { position: 'absolute', top: '5px', right: '5px' } }} iconProps={{ iconName: 'ChromeClose' }} onClick={() => { this.setState({ isSearchDialogOpen: false }); }} />
                        <h2>Material Search</h2>
                        <div style={{ position: 'relative' }}>
                            <div style={{ display: 'flex' }}>
                                <TextField componentRef={this.matSearchField} autoFocus={true} onKeyUp={this.onKeyUp} styles={{ root: { width: '100%' }, field: { cursor: 'pointer' } }} placeholder="Type part of Material Number or Name" onChange={this.onMaterialSearchChange} value={this.state.txtMatSearchValue} /><DefaultButton styles={{ root: { marginLeft: '5px' } }} hidden={this.state.txtMatSearchValue === null} text="Clear" onClick={this.clearSearch} />
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '10px' }}>
                                <PrimaryButton disabled={this.state.isSearching} styles={{ root: { marginTop: '10px' } }} text="Search" onClick={this.searchMaterial} /> <Spinner styles={{ root: { position: 'relative', top: '5px' } }} hidden={!this.state.isSearching} />
                            </div>
                            <Checkbox styles={{ root: { position: 'absolute', right: '35px', top: '40px' } }} label="Fulltext Search (warning: very slow)" checked={this.state.isFulltextSearch} onChange={(event: any, checked?: boolean) => { this.setState({ isFulltextSearch: checked ? true : false }); }} />
                            <br />
                            {this.state?.searchError && <><Label style={{color:'red'}}>An error occured during search: {this.state?.searchError}.<br/>If this keeps happening, please inform the administrator. </Label></>}
                            {this.state.matSearchResults ?
                                <fieldset style={{ position: 'relative' }}>
                                    {/* style={{ position: 'relative' }} */}
                                    <legend>Search Results ({this.state.filteredSearchResults.length}{this.state.resultFilter ? ' filtered' : ''})</legend>
                                    {this.state.matSearchResults.length !== 0 ?
                                        <>
                                            <FilterBox placeHolder="filter search results" styles={{ root: { position: 'absolute', right: '0px', top: '-50px' } }} delay={800} filter={this.state.resultFilter} onFilterTriggered={this.onFilterTriggered} onFilterCleared={this.onClearFilter} />
                                            <div style={{ minHeight: '300px', maxHeight: '400px', position: 'relative' }}>
                                                <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto} styles={{ root: { position: 'absolute' } }} >
                                                    <DetailsList
                                                        columns={[
                                                            // { key: "Index", name: "Index", fieldName: 'Index', minWidth: 50},
                                                            { key: "ArticleNumber", name: "ArticleNumber", fieldName: 'MaterialNum', minWidth: 50, maxWidth: 80, isResizable: true },
                                                            { key: "Description", name: "Description", fieldName: 'Description', minWidth: 300, isResizable: true },
                                                        ]}
                                                        items={this.state.filteredSearchResults}
                                                        selectionMode={SelectionMode.single}
                                                        layoutMode={DetailsListLayoutMode.justified}
                                                        selectionPreservedOnEmptyClick={true}
                                                        selection={this.materialSelection}
                                                        onRenderDetailsHeader={this.renderSearchResultsHeader}
                                                    // onRenderRow={this.onRenderSearchResultRow}
                                                    />
                                                </ScrollablePane>
                                            </div>
                                            <div style={{ height: '40px', marginTop: '10px' }}>
                                                <PrimaryButton style={{ position: 'absolute', right: '5px' }} disabled={!this.state.selectedMaterial || this.state.selectedMaterial.length == 0} text="Select Material" onClick={this.selectMaterial} />
                                            </div>
                                        </>
                                        : <span>No Materials found, please try another search term</span>
                                    }

                                </fieldset>
                                : null
                            }

                        </div>
                    </div>
                </Modal>
            </Stack>
        );

    }

    private renderSearchResultsHeader = (props?: IDetailsHeaderProps, defaultRender?: (props?: IDetailsHeaderProps) => JSX.Element | null): JSX.Element | null => {
        if (!props) {
            return null;
        }
        const onRenderColumnHeaderTooltip: IRenderFunction<IDetailsColumnRenderTooltipProps> =
            tooltipHostProps => (
                <TooltipHost {...tooltipHostProps} />
            );
        return (
            <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
                {defaultRender!({
                    ...props,
                    onRenderColumnHeaderTooltip,
                })}
            </Sticky>
        );
    };

    private selectMaterial = () => {
        if (this.state.searchItemIndex !== null && this.state.searchItemIndex !== undefined) {
            let material = this.state.selectedMaterial[0] as IMaterial;
            this.props.proposal.materialSupport[this.state.searchItemIndex] = {
                articelNo: material.MaterialNum,
                description: material.Description,
                quantity: this.props.proposal.materialSupport[this.state.searchItemIndex].quantity
            };
        }
        this.setState({ isSearchDialogOpen: false, searchItemIndex: null, selectedMaterial: null });
        this.materialSelection.setAllSelected(false);
    };

    private onMaterialSearchChange = (event: any, newValue?: string | undefined): void => {
        this.setState({
            txtMatSearchValue: newValue
        });
    };

    private searchMaterial = (): Promise<void> => {
        const { txtMatSearchValue } = this.state;
        if (txtMatSearchValue && txtMatSearchValue.trim().length !== 0) {
            this.setState({ isSearching: true });

            return MaterialSearch.searchMaterial(txtMatSearchValue, this.state.isFulltextSearch).then((mats: IMaterial[]) => {
                this.setState({ matSearchResults: mats, filteredSearchResults: mats, resultFilter: null });
                return Promise.resolve();
            }).catch((err)=>{
                let errorMessage = err;
                if(err.ExceptionMessage){
                    errorMessage = err.ExceptionMessage;
                }
                this.setState({searchError: errorMessage});
            })
            .finally(() => {
                this.setState({ isSearching: false });
            });
        }
    };

    private clearSearch = (): void => {
        this.setState({
            txtMatSearchValue: null,
            matSearchResults: null,
            resultFilter: null
        });

        // tslint issue here
        (this.matSearchField as any).current.focus();
    };

    private onFilterTriggered = (filter: string) => {
        const { matSearchResults } = this.state;
        if (filter) {
            let lowerFilter = filter.toLowerCase();
            let filteredResults = matSearchResults.filter((entry) => { return entry.MaterialNum.toLowerCase().indexOf(lowerFilter) !== -1 || entry.Description.toLowerCase().indexOf(lowerFilter) !== -1 });
            this.setState({ resultFilter: filter, filteredSearchResults: filteredResults });
        } else {
            this.setState({ resultFilter: filter, filteredSearchResults: matSearchResults });
        }
    };

    private onClearFilter = () => {
        const { matSearchResults } = this.state;
        this.setState({ resultFilter: null, filteredSearchResults: matSearchResults });
    };

    private onRenderSearchResultRow = (props?: IDetailsRowProps, defaultRender?: (props?: IDetailsRowProps) => JSX.Element | null) => {
        return <DetailsRow {...props} styles={{ root: { cursor: 'pointer' } }} />;
    };
}