import * as React from 'react';
import { Stack, ActionButton, MessageBar, MessageBarType } from 'office-ui-fabric-react';

export interface IProposalFormValidation {
    currentStep?: any;
    firstStep?: any;
    goToStep?: any;
    lastStep?: any;
    nextStep?: any;
    previousStep?: any;
    totalSteps?: any;
    step?: any;
    save?: any;
    submit?: any;
    isUserAuthenticated?: boolean;
    fieldErrors?: any[];
    isReadonly?: boolean;
}

export default class ProposalFormValidation extends React.Component<IProposalFormValidation, {}> {
    constructor(props: any) {
        super(props);
        this.state = {
        }
    }

    public componentDidMount() {
    }

    public render() {
        let errors = this.props.fieldErrors && this.props.fieldErrors.length >= this.props.currentStep ? this.props.fieldErrors[this.props.currentStep - 1] : null;        

        return (
            <div key={errors ? Object.keys(errors).join("-") : "no-errors"}>
                {
                    errors && Object.keys(errors).length && !this.props.isReadonly ? <div>
                        <Stack horizontal>
                            <Stack horizontal tokens={{ childrenGap: 20 }} wrap={true} className="fullWidth">
                                <MessageBar messageBarType={MessageBarType.error} styles={{ root: { borderRadius: '6px' } }}>
                                    Please fix the following field errors on this form:
                                    <ul style={{ marginBottom: '0px' }}>
                                        {
                                            Object.keys(errors).map((fieldError, idx) => {
                                                if (errors[fieldError].childErrors && Object.keys(errors[fieldError].childErrors).length!==0) {
                                                    return <div key={`summaryFieldErr_${idx}_wrapper`}>
                                                        <li key={`summaryFieldErr_${idx}`}><span style={{ fontWeight: 'bold' }}>{errors[fieldError].display}:</span> {errors[fieldError].error}</li>
                                                        <ul key={`summaryFieldErr_${idx}_children`}>
                                                            {
                                                                Object.keys(errors[fieldError].childErrors).map((childErrorField, childIdx) => {
                                                                    let childError = errors[fieldError].childErrors[childErrorField];
                                                                    return <li key={`summaryFieldErr_${idx}_${childIdx}`}><span style={{ fontWeight: 'bold' }}>Row {childError.rowIdx + 1 } - {childError.display}:</span> {childError.error}</li>;
                                                                })
                                                            }
                                                        </ul>
                                                    </div>;
                                                } else {
                                                    return <li key={`summaryFieldErr_${idx}`}><span style={{ fontWeight: 'bold' }}>{errors[fieldError].display}:</span> {errors[fieldError].error}</li>;
                                                }
                                            })
                                        }
                                    </ul>
                                </MessageBar>
                            </Stack>
                        </Stack>
                    </div> : null
                }
            </div>
        )
    }
}