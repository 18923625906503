import * as React from 'react';
import './Layout.scss';
//import logo from '../../images/str-group-color.svg';
import { Link } from 'react-router-dom';
import { Stack } from 'office-ui-fabric-react';

const logo = require("../../images/str-group-white.svg")


export class Layout extends React.Component<any, any> {
    static displayName = Layout.name;
    private year = new Date().getFullYear();

    constructor(props: any) {
        super(props);
    }

    render() {
        return (
            <Stack className="fullWidth">
                <Stack horizontal horizontalAlign="space-between" verticalAlign="center" className="header">
                    <div className="appName"><a onClick={() => { window.location.href = window.location.origin; }}>Research Screening</a></div>
                    <div className="companyLogoContainer"><img className="companyLogo" src={logo} alt="Straumann Logo"></img></div>
                </Stack>
                <div className="mainContent">
                    {/* <Stack className="fullWidth"> */}
                    {/* <h2 className="pageHeading">Service Agreement</h2> */}
                    <div className="container">{this.props.children}</div>
                    {/* </Stack> */}

                </div>
                <Stack horizontal horizontalAlign="space-between" verticalAlign="center" className="footer">
                    <div>© {this.year} Institut Straumann AG</div>
                    <div>
                        
                        <button id="ot-sdk-btn" className="ot-sdk-show-settings" style={{height: '35px',fontSize: '12px',marginRight: '20px'}}>Cookie Settings</button>
                        
                        <a className="footerLink" target="_blank" href="https://www.straumann.com/en/dental-professionals/legal-notice.html">
                            Legal notice
                        </a>
                        <span className="footerseparater">|</span>
                        <a className="footerLink" target="_blank" href="https://www.straumann.com/group/en/discover/privacy/com/en.html">
                            Privacy notice
                        </a>
                        <span className="footerseparater">|</span>
                        <a className="footerLink" target="_blank" href="https://www.straumann.com/en/dental-professionals/imprint.html">
                            Imprint
                        </a>

                    </div>
                </Stack>
            </Stack>
        );
    }
}
