import * as React from 'react';
import { css, Stack, PrimaryButton, Text, getTheme, INavLinkGroup, Nav, IStackProps, INavLink, TextField, Label, IconButton, IDropdownOption, Dropdown, IDropdownStyles, StackItem } from 'office-ui-fabric-react';
import '../Proposal.scss';
import { IFormProps } from '../Models/IFormProps';
import { observer } from 'mobx-react';
import { WizardNavigationButtons } from './WizardNavigationFooter';
import ProposalFormValidation from './ProposalFormValidation';

export interface IStudyIntervensionsProps extends IFormProps {
}

export interface IStudyIntervensionsState {
    showControlArm: boolean;
}

@observer
export class StudyIntervensions extends React.Component<IStudyIntervensionsProps, IStudyIntervensionsState> {

    constructor(props: any) {
        super(props);
        this.state = {
            showControlArm: false
        }
    }

    public componentDidMount() {
        this.setState({
            showControlArm: this.props.proposal.studyDesign?.groupB == "Controlled"
        });
    }

    private onRenderGroupHeader(group: INavLinkGroup | undefined): JSX.Element {
        return <h3>{group?.name}</h3>;
    }

    private onLinkClick(ev?: React.MouseEvent<HTMLElement>, item?: INavLink) {
        alert(item?.name);
    }

    public render() {
        const columnProps1: Partial<IStackProps> = {
            tokens: { childrenGap: 10 },
            styles: { root: { width: "20%" } },
        };

        const columnProps2: Partial<IStackProps> = {
            tokens: { childrenGap: 10 },
            styles: { root: { width: "30%" } },
        };

        const columnProps3: Partial<IStackProps> = {
            tokens: { childrenGap: 10 },
            styles: { root: { width: "50%" } },
        };

        const columnProps4: Partial<IStackProps> = {
            tokens: { childrenGap: 10 },
            styles: { root: { width: "70%" } },
        };

        const columnProps5: Partial<IStackProps> = {
            tokens: { childrenGap: 10 },
            styles: { root: { width: "80%" } },
        };

        const columnProps6: Partial<IStackProps> = {
            tokens: { childrenGap: 10 },
            styles: { root: { width: "40%" } },
        };

        if (!this.props.proposal.additionalStudyArms || this.props.proposal.additionalStudyArms.length == 0) {
            this.props.proposal.additionalStudyArms = [];
            this.props.proposal.additionalStudyArms.push({});
        }



        return (
            <Stack verticalAlign="center" className={css('fullWidth', 'animate', 'stepPopIn')} verticalFill={true} tokens={{ childrenGap: 20 }}>
                <Stack verticalAlign="space-between" tokens={{ childrenGap: 5 }}>
                    {
                        this.props.isReadonly && <Label className="heading">Study Intervensions</Label>
                    }
                    {
                        !this.props.isReadonly && <h1>Study Intervensions</h1>
                    }
                </Stack>
                <Stack className="fullWidth">
                    <ProposalFormValidation {...this.props} fieldErrors={this.props.proposal.fieldErrors} ></ProposalFormValidation>
                </Stack>
                <Stack>
                    <Label>Study Intervention  - Test Arm</Label>
                    <Stack className="fullWidth" tokens={{ childrenGap: 10 }}>
                        <TextField placeholder="Device name" required value={this.props.proposal.studyIntTestArm?.device} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                            errorMessage={this.props.proposal.fieldErrors ? this.props.proposal.fieldErrors[3]["studyIntTestArm.device"]?.error : null}
                            onChange={(_event: any, newValue?: string) => {
                                if (!this.props.proposal.studyIntTestArm)
                                    this.props.proposal.studyIntTestArm = {};
                                this.props.proposal.studyIntTestArm.device = newValue;
                            }}></TextField>
                        <TextField placeholder="Treatment name (please add a short title to your test group)" required  value={this.props.proposal.studyIntTestArm?.name} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                            errorMessage={this.props.proposal.fieldErrors ? this.props.proposal.fieldErrors[3]["studyIntTestArm.name"]?.error : null}
                            onChange={(_event: any, newValue?: string) => {
                                if (!this.props.proposal.studyIntTestArm)
                                    this.props.proposal.studyIntTestArm = {};
                                this.props.proposal.studyIntTestArm.name = newValue;
                            }}></TextField>
                        <TextField placeholder="Treatment description" required multiline rows={3} value={this.props.proposal.studyIntTestArm?.description} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                            errorMessage={this.props.proposal.fieldErrors ? this.props.proposal.fieldErrors[3]["studyIntTestArm.description"]?.error : null}
                            onChange={(_event: any, newValue?: string) => {
                                if (!this.props.proposal.studyIntTestArm)
                                    this.props.proposal.studyIntTestArm = {};
                                this.props.proposal.studyIntTestArm.description = newValue;
                            }}></TextField>
                    </Stack>
                </Stack>

                {
                    this.state.showControlArm &&
                    <Stack>
                        <Label>Control Intervention – Control Arm</Label>
                        <Stack className="fullWidth" tokens={{ childrenGap: 10 }}>
                            <TextField placeholder="Device name, if applicable" value={this.props.proposal.controlIntControlArm?.device} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                errorMessage={this.props.proposal.fieldErrors ? this.props.proposal.fieldErrors[3]["controlIntControlArm.device"]?.error : null}
                                onChange={(_event: any, newValue?: string) => {
                                    if (!this.props.proposal.controlIntControlArm)
                                        this.props.proposal.controlIntControlArm = {};
                                    this.props.proposal.controlIntControlArm.device = newValue;
                                }}></TextField>
                            <TextField placeholder="Treatment name (please add a short title to your test group), if applicable" value={this.props.proposal.controlIntControlArm?.name} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                errorMessage={this.props.proposal.fieldErrors ? this.props.proposal.fieldErrors[3]["controlIntControlArm.name"]?.error : null}
                                onChange={(_event: any, newValue?: string) => {
                                    if (!this.props.proposal.controlIntControlArm)
                                        this.props.proposal.controlIntControlArm = {};
                                    this.props.proposal.controlIntControlArm.name = newValue;
                                }}></TextField>
                            <TextField placeholder="Treatment description, if applicable" multiline rows={3} value={this.props.proposal.controlIntControlArm?.description} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                errorMessage={this.props.proposal.fieldErrors ? this.props.proposal.fieldErrors[3]["controlIntControlArm.description"]?.error : null}
                                onChange={(_event: any, newValue?: string) => {
                                    if (!this.props.proposal.controlIntControlArm)
                                        this.props.proposal.controlIntControlArm = {};
                                    this.props.proposal.controlIntControlArm.description = newValue;
                                }}></TextField>
                        </Stack>
                    </Stack>
                }

                <fieldset>
                    <legend>Additional Study arms</legend>
                    <Stack tokens={{ childrenGap: 10 }}>
                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                            <Stack className="fullWidth" tokens={{ childrenGap: 10 }}>
                                <TextField placeholder="Device name, if applicable" value={this.props.proposal.additionalStudyArms[0].device} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                    errorMessage={this.props.proposal.fieldErrors && this.props.proposal.fieldErrors[3]["additionalStudyArms"]?.childErrors ? this.props.proposal.fieldErrors[3]["additionalStudyArms"]?.childErrors["additionalStudyArms_device_0"]?.error : null}
                                    onChange={(_event: any, newValue?: string) => {
                                        if (this.props.proposal.additionalStudyArms)
                                            this.props.proposal.additionalStudyArms[0].device = newValue;
                                    }}></TextField>
                                <TextField placeholder="Treatment name (please add a short title to your test group), if applicable" value={this.props.proposal.additionalStudyArms[0].name} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                    errorMessage={this.props.proposal.fieldErrors && this.props.proposal.fieldErrors[3]["additionalStudyArms"]?.childErrors ? this.props.proposal.fieldErrors[3]["additionalStudyArms"]?.childErrors["additionalStudyArms_name_0"]?.error : null}
                                    onChange={(_event: any, newValue?: string) => {
                                        if (this.props.proposal.additionalStudyArms)
                                            this.props.proposal.additionalStudyArms[0].name = newValue;
                                    }}></TextField>
                                <TextField placeholder="Treatment description, if applicable" multiline rows={3} value={this.props.proposal.additionalStudyArms[0].description} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                    errorMessage={this.props.proposal.fieldErrors && this.props.proposal.fieldErrors[3]["additionalStudyArms"]?.childErrors ? this.props.proposal.fieldErrors[3]["additionalStudyArms"]?.childErrors["additionalStudyArms_description_0"]?.error : null}
                                    onChange={(_event: any, newValue?: string) => {
                                        if (this.props.proposal.additionalStudyArms)
                                            this.props.proposal.additionalStudyArms[0].description = newValue;
                                    }}></TextField>
                            </Stack>
                            {
                                !this.props.isReadonly &&
                                <IconButton iconProps={{ iconName: 'Add' }}
                                    title="Add" ariaLabel="Add" onClick={() => {
                                        this.props.proposal.additionalStudyArms?.push({});
                                    }} />
                            }
                        </Stack>
                        {
                            this.props.proposal.additionalStudyArms?.map((item, index) => {
                                if (index != 0) {
                                    return <div key={`addStudyArms_${index}`}>
                                        <hr className="fullWidth" />
                                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                                            <Stack className="fullWidth" tokens={{ childrenGap: 10 }}>
                                                <TextField placeholder="Device name, if applicable" value={item.device || ''} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                                    errorMessage={this.props.proposal.fieldErrors && this.props.proposal.fieldErrors[3]["additionalStudyArms"]?.childErrors ? this.props.proposal.fieldErrors[3]["additionalStudyArms"]?.childErrors[`additionalStudyArms_device_${index}`]?.error : null}
                                                    onChange={(_event: any, newValue?: string) => {
                                                        item.device = newValue;
                                                    }}></TextField>
                                                <TextField placeholder="Treatment name (please add a short title to your test group), if applicable" value={item.name || ''} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                                    errorMessage={this.props.proposal.fieldErrors && this.props.proposal.fieldErrors[3]["additionalStudyArms"]?.childErrors ? this.props.proposal.fieldErrors[3]["additionalStudyArms"]?.childErrors[`additionalStudyArms_name_${index}`]?.error : null}
                                                    onChange={(_event: any, newValue?: string) => {
                                                        item.name = newValue;
                                                    }}></TextField>
                                                <TextField placeholder="Treatment description" multiline rows={3} value={item.description || ''} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                                    errorMessage={this.props.proposal.fieldErrors && this.props.proposal.fieldErrors[3]["additionalStudyArms"]?.childErrors ? this.props.proposal.fieldErrors[3]["additionalStudyArms"]?.childErrors[`additionalStudyArms_description_${index}`]?.error : null}
                                                    onChange={(_event: any, newValue?: string) => {
                                                        item.description = newValue;
                                                    }}></TextField>
                                            </Stack>
                                            {
                                                !this.props.isReadonly &&
                                                <IconButton iconProps={{ iconName: 'Remove' }}
                                                    title="Remove" ariaLabel="Remove" onClick={() => {
                                                        this.props.proposal.additionalStudyArms?.splice(index, 1);
                                                    }} />
                                            }
                                        </Stack>
                                    </div>;
                                }
                            })
                        }
                    </Stack>
                </fieldset>



                {
                    this.props.showWizardNavigation && <StackItem className={css("wizardNavigationContainer")}>
                        <WizardNavigationButtons {...this.props} step={this.props.currentStep} save={this.props.onSave}></WizardNavigationButtons>
                    </StackItem>
                }
            </Stack>
        );

    }
}