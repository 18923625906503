import * as React from 'react';
import { css, Stack, PrimaryButton, Text, getTheme, INavLinkGroup, Nav, IStackProps, INavLink, TextField, Label, IconButton, IDropdownOption, Dropdown, IDropdownStyles, StackItem } from 'office-ui-fabric-react';
import '../Proposal.scss';
import { IFormProps } from '../Models/IFormProps';
import { WizardNavigationButtons } from './WizardNavigationFooter';
import ProposalFormValidation from './ProposalFormValidation';
import { observer } from 'mobx-react';


export interface IStudyPowerProps extends IFormProps {
}

export interface IStudyPowerState {
}

@observer
export class StudyPower extends React.Component<IStudyPowerProps, IStudyPowerState> {

    constructor(props: any) {
        super(props);
        this.state = {

        }
    }

    public componentDidMount() {
    }

    private onRenderGroupHeader(group: INavLinkGroup | undefined): JSX.Element {
        return <h3>{group?.name}</h3>;
    }

    private onLinkClick(ev?: React.MouseEvent<HTMLElement>, item?: INavLink) {
        alert(item?.name);
    }

    public render() {
        const columnProps1: Partial<IStackProps> = {
            tokens: { childrenGap: 10 },
            styles: { root: { width: "20%" } },
        };

        const columnProps2: Partial<IStackProps> = {
            tokens: { childrenGap: 10 },
            styles: { root: { width: "30%" } },
        };

        const columnProps3: Partial<IStackProps> = {
            tokens: { childrenGap: 10 },
            styles: { root: { width: "50%" } },
        };

        const columnProps4: Partial<IStackProps> = {
            tokens: { childrenGap: 10 },
            styles: { root: { width: "70%" } },
        };

        const columnProps5: Partial<IStackProps> = {
            tokens: { childrenGap: 10 },
            styles: { root: { width: "80%" } },
        };

        const columnProps6: Partial<IStackProps> = {
            tokens: { childrenGap: 10 },
            styles: { root: { width: "40%" } },
        };

        let nTotalStyle = this.props.proposal.studyDesign?.groupB != "Single-arm" ? columnProps2 : columnProps3;
        let anticipatedDropOutRateStyle = this.props.proposal.studyDesign?.groupB != "Single-arm" ? columnProps6 : columnProps3;

        return (
            <Stack verticalAlign="center" className={css('fullWidth', 'animate', 'stepPopIn')} verticalFill={true} tokens={{ childrenGap: 20 }}>
                <Stack verticalAlign="space-between" tokens={{ childrenGap: 5 }}>
                    {
                        this.props.isReadonly && <Label className="heading">Study Power</Label>
                    }
                    {
                        !this.props.isReadonly && <h1>Study Power</h1>
                    }
                </Stack>
                <Stack className="fullWidth">
                    <ProposalFormValidation {...this.props} fieldErrors={this.props.proposal.fieldErrors} ></ProposalFormValidation>
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <Stack {...nTotalStyle}>
                        <TextField label="N total" type="number" required errorMessage={this.props.proposal.fieldErrors ? this.props.proposal.fieldErrors[2]["sampleSize.nTotal"]?.error : null} value={this.props.proposal.sampleSize?.nTotal?.toString()} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                            onChange={(_event: any, newValue?: string) => {
                                if (!this.props.proposal.sampleSize)
                                    this.props.proposal.sampleSize = {};
                                this.props.proposal.sampleSize.nTotal = Number(newValue);
                            }}></TextField>
                    </Stack>
                    {
                        this.props.proposal.studyDesign?.groupB != "Single-arm" &&
                        <Stack {...columnProps2}>
                            <TextField label="N per group" type="number" required errorMessage={this.props.proposal.fieldErrors ? this.props.proposal.fieldErrors[2]["sampleSize.nPerGroup"]?.error : null} value={this.props.proposal.sampleSize?.nPerGroup?.toString()} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                onChange={(_event: any, newValue?: string) => {
                                    if (!this.props.proposal.sampleSize)
                                        this.props.proposal.sampleSize = {};
                                    this.props.proposal.sampleSize.nPerGroup = Number(newValue);
                                }}></TextField>
                        </Stack>
                    }

                    <Stack {...anticipatedDropOutRateStyle}>
                        <TextField label="Anticipated drop-out rate %" required errorMessage={this.props.proposal.fieldErrors? this.props.proposal.fieldErrors[2]["sampleSize.anticipatedDropOutRate"]?.error : null} type="number" value={this.props.proposal.sampleSize?.anticipatedDropOutRate?.toString()} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                            onChange={(_event: any, newValue?: string) => {
                                if (!this.props.proposal.sampleSize)
                                    this.props.proposal.sampleSize = {};
                                this.props.proposal.sampleSize.anticipatedDropOutRate = Number(newValue);
                            }}></TextField>
                    </Stack>
                </Stack>
 
                <TextField label="Adequate power analysis and justification" multiline rows={3} required errorMessage={this.props.proposal.fieldErrors? this.props.proposal.fieldErrors[2]["sampleSize.powerAnalysis"]?.error : null} value={this.props.proposal.sampleSize?.powerAnalysis} readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                    onChange={(_event: any, newValue?: string) => {
                        if (!this.props.proposal.sampleSize) {
                            this.props.proposal.sampleSize = {};
                            console.log("Empty");
                        }
                        this.props.proposal.sampleSize.powerAnalysis = newValue;
                    }}></TextField>

                {
                    this.props.showWizardNavigation && <StackItem className={css("wizardNavigationContainer")}>
                        <WizardNavigationButtons {...this.props} step={this.props.currentStep} save={this.props.onSave}></WizardNavigationButtons>
                    </StackItem>
                }
            </Stack>
        );

    }
}