import HttpService from "./HttpService";
import { IMaterial } from "../components/Proposal/Models/IMaterial";

export interface IMaterialSearchResult {
    result: IMaterial[],
    error: any
}

export class MaterialSearch {

    private static readonly AZURE_MATERIAL_SEARCH_URL = `https://stgtheobald.servicebus.windows.net/ecs/ws/custom/SearchMaterialWebService/SearchMaterial/`;    

    public static searchMaterial(keywords: string, isLongTextSearch:boolean=false): Promise<IMaterial[]> {
        return HttpService.post(`${MaterialSearch.AZURE_MATERIAL_SEARCH_URL}?keyword=${keywords}&longtext=${isLongTextSearch?'X':''}`, null, {
            'Authorization': 'Apikey 79B4163144F74D11A7B0236480F0DB39'
        }).then((results: IMaterialSearchResult) => {
            //return results?.result.map((entry, idx)=>{ return { Index: idx, ...entry}; });
            return results?.result;
        });
    }    

    public static logErrorInConsole(errorArea: string, errorObj: any) {
        this.logError(errorObj.message);
        console.log(`Error message ${errorArea}: `, errorObj.message);
        console.log(`Error Stack Trace ${errorArea}: `, errorObj.stack);
    }

    private static logError(msg: any) {
        if (console) {
            console.log(`%cERROR:`, "color:red;font-weight:bold;font-size:16px");
            console.log(msg);
        }
    }    
}

