import * as React from 'react';
import styles from './AttachmentComponent.module.scss';
import { IconButton, Link, Label, Spinner, ActionButton, Dialog, DialogType, DialogFooter, PrimaryButton, DefaultButton, css, mergeStyles, Stack } from 'office-ui-fabric-react';
import { IFileItem } from '../Proposal/Models/IFileItem';
import HttpService from '../../dataprovider/HttpService';

declare type AttachmentClickBehaviour = "EditInBrowser" | "Download" | "SimpleOpen";

declare type DeletePermissions = "All" | "Own" | "None";

export interface IAttachmentComponentProps {
    title?: string;

    apiEndpointSP: string;

    attachments?: Array<IFileItem | File>;
    attachment?: IFileItem | File;

    readonly: boolean;
    deletePermissions: DeletePermissions;
    extensionFilters: string[];
    attachmentClickBehaviour: AttachmentClickBehaviour;
    enableDropZone: boolean;
    fileMode: "SingleFile" | "MultiFile";
    required?: boolean;

    maxHeightFileList?: number;

    isLoading?: boolean;

    borderStyle?: string;
    displayStyle?: "block" | "inline-block";

    maxFileNameWidth?: string;

    //Added for file compatibility, Purpose is to remove dependence of file name for file download and access
    useFileIdToDownloadFile?: string;
    // events
    onFilesAdded: (files: File[]) => Promise<void>;
    onFileReplaced?: (file: File) => Promise<void>;
    onFileDeleted: (file: IFileItem | File) => Promise<void>;

    errorMessage?: string;
}

export interface IAttachmentComponentState {
    title: string;
    attachments: Array<IFileItem | File>;
    attachment: IFileItem | File;
    readonly: boolean;
    deletePermissions: DeletePermissions;
    attachmentClickBehaviour: AttachmentClickBehaviour;
    enableDropZone: boolean;
    fileMode: "SingleFile" | "MultiFile";
    required: boolean;

    maxHeightFileList: number;

    isLoading: boolean;
    isAddingFile: boolean;
    isExchangingFile: boolean;
    isDeletingFile: boolean;

    draggingOver: boolean;
    //dragCounter: number;
    isDeleteConfirmationOpen: boolean;
    deleteConfirmationAttachment: IFileItem | File;

    isBadFilesDialogOpen: boolean;
    // badFiles: File[];
    badFiles: {
        invalidExtensionFiles: File[];
        invalidNameFiles: File[];
    };
    badFilesDialogCallback: () => void;

    errorMessage?: string;
}

export class AttachmentComponent extends React.Component<IAttachmentComponentProps, IAttachmentComponentState> {

    // tslint:disable-next-line:member-access
    static defaultProps = {
        useFileIdToDownloadFile: false
    };

    private fileInputRef: React.RefObject<HTMLInputElement> = null;
    //private dragCounter: number = 0;

    constructor(props: IAttachmentComponentProps) {
        super(props);

        this.state = {
            title: props.title ? props.title : null,
            attachments: props.attachments ? props.attachments : [],
            attachment: props.attachment ? props.attachment : null,
            readonly: props.readonly,
            deletePermissions: props.deletePermissions,
            attachmentClickBehaviour: props.attachmentClickBehaviour,
            enableDropZone: props.enableDropZone,
            fileMode: props.fileMode,
            required: !!props.required,

            maxHeightFileList: props.maxHeightFileList ? props.maxHeightFileList : 105,

            isLoading: props.isLoading ? props.isLoading : false,
            isAddingFile: false,
            isExchangingFile: false,
            isDeletingFile: false,

            draggingOver: false,
            //dragCounter: 0,
            isDeleteConfirmationOpen: false,
            deleteConfirmationAttachment: null,
            isBadFilesDialogOpen: false,
            badFiles: {
                invalidExtensionFiles: [],
                invalidNameFiles: []
            },
            badFilesDialogCallback: null,

            errorMessage: props.errorMessage
        };

        this.fileInputRef = React.createRef<HTMLInputElement>();
    }

    public componentWillReceiveProps(newProps: IAttachmentComponentProps) {
        if (this.state.title != newProps.title) {
            this.setState({ title: newProps.title });
        }

        if ((this.state.attachments != newProps.attachments && newProps.attachments) || (this.state.attachments && newProps.attachments && this.state.attachments.length != newProps.attachments.length)) {
            this.setState({ attachments: [...newProps.attachments] });
        }

        if (this.state.attachment != newProps.attachment) {
            this.setState({ attachment: newProps.attachment });
        }

        if (this.state.readonly != newProps.readonly) {
            this.setState({ readonly: newProps.readonly });
        }

        if (this.state.deletePermissions != newProps.deletePermissions) {
            this.setState({ deletePermissions: newProps.deletePermissions });
        }

        if (this.state.attachmentClickBehaviour != newProps.attachmentClickBehaviour) {
            this.setState({ attachmentClickBehaviour: newProps.attachmentClickBehaviour });
        }

        if (this.state.enableDropZone != newProps.enableDropZone) {
            this.setState({ enableDropZone: newProps.enableDropZone });
        }

        if (this.state.fileMode != newProps.fileMode) {
            this.setState({ fileMode: newProps.fileMode });
        }

        if (this.state.required != newProps.required) {
            this.setState({ required: newProps.required });
        }

        if (this.state.isLoading != newProps.isLoading) {
            this.setState({ isLoading: newProps.isLoading });
        }

        if (newProps.maxHeightFileList && this.state.maxHeightFileList != newProps.maxHeightFileList) {
            this.setState({ maxHeightFileList: newProps.maxHeightFileList });
        }

        if (this.state.errorMessage != newProps.errorMessage) {
            this.setState({ errorMessage: newProps.errorMessage });
        }
    }

    private triggerFileUpload = (files: FileList, mode: 'add' | 'exchange'): void => {
        if (files.length === 0) {
            return;
        }

        //let filteredFiles = this.filterAllowedFiles(files);
        //let rawFiles = Common.convertFileListToArray(files);
        //let badFiles = rawFiles.filter((file) => { return filteredFiles.indexOf(file) == -1; });

        let categorizedFiles = this.categorizeValidInvalidFiles(files);

        if (categorizedFiles.correctFiles.length != files.length) {
            // there are some invalid file types. Upload the valid ones, and report the invalid ones (via badFilesDialog)
            this.setState({
                badFilesDialogCallback: () => {
                    if (categorizedFiles.correctFiles.length !== 0) {
                        if (mode == "add") {
                            this.props.onFilesAdded(categorizedFiles.correctFiles).then(() => {
                                this.setState({
                                    isAddingFile: false,
                                    isExchangingFile: false,
                                    badFiles: {
                                        invalidExtensionFiles: [],
                                        invalidNameFiles: []
                                    }
                                });
                            });
                        } else {
                            this.props.onFileReplaced(categorizedFiles.correctFiles[0]).then(() => {
                                this.setState({
                                    isAddingFile: false,
                                    isExchangingFile: false,
                                    badFiles: {
                                        invalidExtensionFiles: [],
                                        invalidNameFiles: []
                                    }
                                });
                            });
                        }
                    }
                },
                isBadFilesDialogOpen: true,
                badFiles: {
                    invalidExtensionFiles: categorizedFiles.invalidExtensionFiles,
                    invalidNameFiles: categorizedFiles.invalidNameFiles
                }
                // badFiles: {badFiles}
            });
        } else {
            if (mode == "add") {
                this.props.onFilesAdded(categorizedFiles.correctFiles).then(() => {
                    this.setState({ isAddingFile: false, isExchangingFile: false });
                });
            } else {
                this.props.onFileReplaced(categorizedFiles.correctFiles[0]).then(() => {
                    this.setState({ isAddingFile: false, isExchangingFile: false });
                });
            }
        }
    }

    private onFileDrop = (event: React.DragEvent<HTMLDivElement>): void => {
        if (!this.state.enableDropZone || this.state.readonly) {
            return;
        }

        event.preventDefault();
        event.stopPropagation();

        if (this.state.fileMode == "MultiFile") {
            //console.log("DataTransfer:", event.dataTransfer);
            //console.log("Files:", event.dataTransfer.files);
            this.setState({ draggingOver: false });

            if (event.dataTransfer && event.dataTransfer.files && event.dataTransfer.files.length !== 0) {
                this.setState({ isAddingFile: true });

                this.triggerFileUpload(event.dataTransfer.files, 'add');
            }
        } else if (this.state.fileMode == "SingleFile") {
            this.setState({ draggingOver: false });

            if (event.dataTransfer && event.dataTransfer.files && event.dataTransfer.files.length !== 0) {

                let isExchange = this.state.attachment && this.props.onFileReplaced;
                if (isExchange) {
                    this.setState({ isExchangingFile: true });
                } else {
                    this.setState({ isAddingFile: true });
                }

                this.triggerFileUpload(event.dataTransfer.files, isExchange ? "exchange" : "add");
            }
        }
    }

    private onDraggingOver = (event: React.DragEvent<HTMLDivElement>): void => {
        if (!this.state.enableDropZone || this.state.readonly) {
            return;
        }

        event.preventDefault();
        event.stopPropagation();

        //this.dragCounter++;
        //console.log("Entering... " + this.dragCounter);

        this.setState({ draggingOver: true });
    }

    private onDragEnd = (event: React.DragEvent<HTMLDivElement>): void => {
        if (!this.state.enableDropZone || this.state.readonly) {
            return;
        }

        this.setState({ draggingOver: false });
    }

    private onDragExit = (event: React.DragEvent<HTMLDivElement>): void => {
        if (!this.state.enableDropZone || this.state.readonly) {
            return;
        }

        event.preventDefault();
        event.stopPropagation();

        this.setState({ draggingOver: false });
    }

    private onDelete = (attachment: IFileItem | File): void => {
        if (this.state.readonly) {
            return;
        }

        if (attachment instanceof File) {
            console.log(`Deleting ${attachment.name}`, attachment);
        } else {
            console.log(`Deleting ${attachment.name}`, attachment);
        }
        this.setState({ isDeleteConfirmationOpen: true, deleteConfirmationAttachment: attachment });
    }

    private onDownload = (attachment: IFileItem): void => {
        window.open(`${window.location.origin}${this.props.apiEndpointSP}/Attachment/${encodeURIComponent(attachment.uniqueId)}`);
    }

    private onDeleteConfirmationDismiss = (action?: string): void => {
        if (action == "Delete") {
            this.setState({ isDeletingFile: true });
            if (this.state.deleteConfirmationAttachment instanceof File) {
                // pass file (that is not uploaded yet)
                this.props.onFileDeleted(this.state.deleteConfirmationAttachment).then(() => {
                    this.setState({ isDeletingFile: false });
                });
            } else {
                // pass serverRelUrl of file on server
                this.props.onFileDeleted(this.state.deleteConfirmationAttachment).then(() => {
                    this.setState({ isDeletingFile: false });
                });
            }
        }
        this.setState({ isDeleteConfirmationOpen: false }, () => { this.setState({ deleteConfirmationAttachment: null }); });
    }

    private onAddFileClicked = () => {
        if (this.state.readonly) {
            return;
        }

        this.fileInputRef.current.click();
    }

    private onInputFileChanged = (isExchange: boolean = false) => {
        if (isExchange) {
            this.setState({ isExchangingFile: true });
        } else {
            this.setState({ isAddingFile: true });
        }

        this.triggerFileUpload(this.fileInputRef.current.files, isExchange ? "exchange" : "add");
    }

    private filterAllowedFiles = (files: FileList): File[] => {
        let result: File[] = [];
        for (var i = 0; i < files.length; i++) {
            let file = files.item(i);

            if (this.props.extensionFilters && this.props.extensionFilters.length) {
                let extension = file.name.toLocaleLowerCase().replace(/.*(\.\S*?)/gm, "$1");
                let match = this.props.extensionFilters.some(filter => { return filter.toLocaleLowerCase().indexOf(extension) == 0; });
                if (match) {
                    result.push(file);
                }
            }
            else {
                result.push(file);
            }
        }
        return result;
    }

    private categorizeValidInvalidFiles = (files: FileList): {
        correctFiles: File[];
        invalidExtensionFiles: File[];
        invalidNameFiles: File[];
    } => {
        let result: {
            correctFiles: File[];
            invalidExtensionFiles: File[];
            invalidNameFiles: File[];
        } = {
            correctFiles: [],
            invalidExtensionFiles: [],
            invalidNameFiles: []
        };

        let invalidFileNameCharacters = new RegExp(/[\\/:*?\"<>|]+/, "g");

        for (var i = 0; i < files.length; i++) {
            let file = files.item(i);
            let isFileNameInvalid = invalidFileNameCharacters.test(file.name);

            if (this.props.extensionFilters && this.props.extensionFilters.length) {
                let extension = file.name.toLocaleLowerCase().replace(/.*(\.\S*?)/gm, "$1");
                let match = this.props.extensionFilters.some(filter => { return filter.toLocaleLowerCase().indexOf(extension) == 0; });

                if (isFileNameInvalid)
                    result.invalidNameFiles.push(file);

                if (match) {
                    if (!isFileNameInvalid)
                        result.correctFiles.push(file);
                }
                else {
                    result.invalidExtensionFiles.push(file);
                }
            }
            else {
                if (isFileNameInvalid)
                    result.invalidNameFiles.push(file);
                else
                    result.correctFiles.push(file);
            }
        }

        return result;
    }

    public render(): React.ReactElement<IAttachmentComponentProps> {

        let listItemStyle: React.CSSProperties = { height: '20px' };
        //let linkStyle: React.CSSProperties = { color: 'inherit', overflowX:'hidden', textOverflow:'ellipsis', wordBreak:'break-all' };
        let linkStyle: React.CSSProperties = { color: 'blue', cursor: 'pointer' };
        let deleteButtonStyle = { color: 'inherit', height: '20px' };
        let addButtonStyle = { color: 'inherit' };
        let spinnerStyle = { color: 'inherit' };

        /**
         * Default styles:
         * - Single File: no border, inline-block style
         * - Multi file: border, block display style
         */
        let borderStyle = this.props.borderStyle || this.state.fileMode == 'SingleFile' ? "0px" : '1px solid rgb(255, 255, 255, 0.4)';
        let displayStyle = this.props.displayStyle || this.state.fileMode == 'SingleFile' ? "inline-block" : "block";

        let placeholderWidth = this.state.deletePermissions == "None" || this.state.readonly ? "0px" : "32px";
        let deletePlaceholder = <span style={{ width: placeholderWidth, display: 'inline-block' }}></span>;
        let makeAttachmentHyperlink = (attachment: IFileItem | File) => {
            if (attachment instanceof File) {
                return <span style={linkStyle} data-interception="off" title={attachment.name}>{attachment.name}</span>;
            }
            if (this.state.attachmentClickBehaviour == "EditInBrowser") {
                // office webapp links open office files directly in office web apps, other documents are opened with browser if possible
                return <span style={linkStyle} data-interception="off" title={attachment.name} onClick={() => { window.open(`${attachment.serverRelativeUrl}?web=1`); }}>{attachment.name}</span>;
            } else if (this.state.attachmentClickBehaviour == "Download") {
                // otherwise...if file should download directly
                return <span style={linkStyle} data-interception="off" title={attachment.name} onClick={this.onDownload.bind(this, attachment)}>{attachment.name}</span>;
            } else {
                // last option, just open 1:1 in browser
                return <span style={linkStyle} data-interception="off" title={attachment.name} onClick={() => { window.open(`${attachment.serverRelativeUrl}`); }}>{attachment.name}</span>;
            }
        };

        let userHasDeletePermissions = (attachment: IFileItem | File) => {
            if (attachment instanceof File) {
                // these are pending uploads. I just added it, so I'm allowed to also remove it
                return true;
            }
            return !this.state.readonly && (this.state.deletePermissions == "All" || this.state.deletePermissions == "Own");
        };

        let getFileName = (attachment: IFileItem | File) => {
            if (attachment instanceof File) {
                return attachment.name;
            }
            return (attachment as IFileItem).name;
        };

        let dropZoneTooltip = !this.state.readonly ? "Drag/Drop file to upload" : "";

        let spinnerIcon: React.ReactElement = this.state.isLoading ?
            <Spinner label="Loading" styles={{ root: spinnerStyle, label: spinnerStyle }}></Spinner>
            :
            this.state.isAddingFile ?
                <Spinner label="Adding File" styles={{ root: spinnerStyle, label: spinnerStyle }}></Spinner>
                :
                this.state.isDeletingFile ?
                    <Spinner label="Deleting File" styles={{ root: spinnerStyle, label: spinnerStyle }}></Spinner>
                    :
                    this.state.isExchangingFile ?
                        <Spinner label="Exchanging File" styles={{ root: spinnerStyle, label: spinnerStyle }}></Spinner>
                        :
                        null;

        let isExchangeMode = this.state.fileMode == "MultiFile" ? false : this.state.attachment ? true : false;

        const styleError: string = mergeStyles({
            fontFamily: 'Segoe UI, Segoe UI Web (West European), Segoe UI, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif',
            fontSize: '12px',
            fontWeight: 400,
            color: 'rgb(164, 38, 44)',
            margin: '0px',
            paddingTop: '5px',
            display: 'flex',
            alignItems: 'center'
        });

        return (
            <div className="attachmentComponent" onDrop={this.onFileDrop} onDragEnter={this.onDraggingOver} onDragEnd={this.onDragEnd} style={{ display: displayStyle, backgroundColor: this.state.draggingOver ? 'darkgrey' : 'initial' }} >
                <div title={dropZoneTooltip} style={{ position: 'relative', paddingBottom: '0px', border: borderStyle, width: '100%' }}>
                    {this.state.title ? <legend>{this.state.title} {this.state.required ? <span style={{ color: 'red', fontWeight: 'bold' }}>*</span> : null}</legend> : null}
                    {
                        this.state.isLoading || this.state.isAddingFile || this.state.isDeletingFile || this.state.isExchangingFile ?
                            spinnerIcon
                            :
                            <>
                                {this.state.fileMode == "MultiFile" ?
                                    <div className="ccsAttachmentScrollSection" style={{ maxHeight: `${this.state.maxHeightFileList}px`, border: `${this.state.errorMessage && !this.props.readonly  ? '1px solid rgb(164, 38, 44)' : '1px solid #aaa'}`, padding: '2px', overflowY: 'scroll', margin: '0px 0px 10px 0px', width: '100%', minHeight: '60px' }}>
                                        {
                                            // show list of all attachments
                                            this.state.attachments ?
                                                this.state.attachments.map((attachment, i) => {
                                                    if (attachment instanceof File) { }
                                                    else if (attachment.isDeleted)
                                                        return;
                                                    return <div key={`delete_${i}`}
                                                        style={
                                                            {
                                                                width: '100%',
                                                                maxWidth: this.props.maxFileNameWidth ? this.props.maxFileNameWidth : '100%',
                                                                overflowX: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                                whiteSpace: 'nowrap',
                                                                padding: '2px'
                                                            }
                                                        }>
                                                        {
                                                            userHasDeletePermissions(attachment) ?
                                                                <IconButton style={deleteButtonStyle} iconProps={{ iconName: "Delete" }} onClick={this.onDelete.bind(this, attachment)} />
                                                                : deletePlaceholder
                                                        }
                                                        {makeAttachmentHyperlink(attachment)}
                                                    </div>;
                                                })
                                                : null
                                        }
                                    </div>
                                    :
                                    <>
                                        {
                                            // show single attachment
                                            this.state.attachment ?
                                                <>
                                                    {userHasDeletePermissions(this.state.attachment) ?
                                                        <IconButton style={deleteButtonStyle} iconProps={{ iconName: "Delete" }} onClick={this.onDelete.bind(this, this.state.attachment)} />
                                                        :
                                                        deletePlaceholder}
                                                    {makeAttachmentHyperlink(this.state.attachment)}
                                                </>
                                                :
                                                null
                                        }
                                    </>
                                }
                                {
                                    !this.state.readonly && (this.state.fileMode == 'MultiFile' || (displayStyle == 'inline-block' && !this.state.attachment) || displayStyle == 'block') ?
                                        <div>
                                            <Stack horizontal tokens={{ childrenGap: 20 }}>
                                                <ActionButton className="addButton" iconProps={{ iconName: "CircleAdditionSolid" }} styles={{ root: addButtonStyle, icon: addButtonStyle }} onClick={this.onAddFileClicked} label="Add File">{isExchangeMode ? "Replace File" : "Add File"}</ActionButton>
                                                {!this.props.readonly && <Label className={css(styleError, 'rsmError')}>{this.props.errorMessage}</Label>}
                                            </Stack>
                                            <input ref={this.fileInputRef} type="file" multiple={true} style={{ display: 'none' }} onChange={this.onInputFileChanged.bind(this, isExchangeMode)} accept={this.props.extensionFilters && this.props.extensionFilters.length ? this.props.extensionFilters.join(",") : "*"} />
                                        </div> : null
                                }
                            </>
                    }
                    <div onDrop={this.onFileDrop} onDragOver={this.onDraggingOver} onDragEnd={this.onDragEnd} onDragExit={this.onDragExit} onDragLeave={this.onDragExit} style={{ position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%', display: this.state.draggingOver ? 'flex' : 'none', justifyContent: 'center', alignItems: 'center', color: 'black', backgroundColor: '#ddd', zIndex: 10000 }}>
                        Drag and drop file(s) here to upload
                    </div>
                </div>
                {
                    this.state.deleteConfirmationAttachment ?
                        <Dialog
                            isOpen={this.state.isDeleteConfirmationOpen}
                            dialogContentProps={{
                                type: DialogType.normal,
                                title: 'Delete Confirmation',
                                subText: `Are you sure you want to delete ${getFileName(this.state.deleteConfirmationAttachment)}?`
                            }}
                            onDismiss={() => this.onDeleteConfirmationDismiss}
                        >
                            <DialogFooter>
                                <PrimaryButton onClick={() => this.onDeleteConfirmationDismiss("Delete")} text="Delete" />
                                <DefaultButton onClick={() => this.onDeleteConfirmationDismiss()} text="Cancel" />
                            </DialogFooter>
                        </Dialog> : null
                }
                {
                    this.state.isBadFilesDialogOpen ?
                        <Dialog
                            isOpen={this.state.isBadFilesDialogOpen}
                            dialogContentProps={{
                                type: DialogType.normal,
                                title: 'Wrong File type'
                            }}
                            onDismiss={() => {
                                this.setState({ isBadFilesDialogOpen: false, isAddingFile: false, isExchangingFile: false }, () => {
                                    if (this.state.badFilesDialogCallback)
                                        this.state.badFilesDialogCallback();
                                });
                            }}
                        >
                            {
                                this.state.badFiles.invalidExtensionFiles && this.state.badFiles.invalidExtensionFiles.length &&
                                <div>Only files with following extensions are allowed:
                                    <ul>
                                        {this.props.extensionFilters.map(ext => <li>{ext}</li>)}
                                    </ul>
                                    The following files will not be uploaded:
                                    <ul>
                                        {this.state.badFiles.invalidExtensionFiles.map((badFile) => { return <li>{badFile.name}</li>; })}
                                    </ul>
                                </div>
                            }
                            {
                                this.state.badFiles.invalidNameFiles && this.state.badFiles.invalidNameFiles.length &&
                                <div> Characters that aren't allowed in file name
                                    <ul>
                                        <li><b>{`" * : < > ? / \ |`}</b></li>
                                    </ul>
                                    The following files will not be uploaded:
                                    <ul>
                                        {this.state.badFiles.invalidNameFiles.map((badFile) => { return <li>{badFile.name}</li>; })}
                                    </ul>
                                </div>
                            }
                            <DialogFooter>
                                <PrimaryButton onClick={() => this.setState({ isBadFilesDialogOpen: false, isAddingFile: false, isExchangingFile: false }, () => {
                                    if (this.state.badFilesDialogCallback)
                                        this.state.badFilesDialogCallback();
                                })} text="OK" />
                            </DialogFooter>
                        </Dialog> :
                        null
                }
            </div>
        );
    }
}