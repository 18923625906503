import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
//import { useIsAuthenticated } from "@azure/msal-react";
import Button from "react-bootstrap/Button";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { AccountType, IAuthenticatedUser, IUserAccount } from './LoginComponent';
import { Redirect } from 'react-router';
import { Dialog, DialogType, Spinner, SpinnerSize } from "office-ui-fabric-react";

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: ["User.Read"],
    //prompt: 'select_account',
};

function parseMicrosoftUser(idToken:any):IUserAccount {
    return {
        firstName: idToken.idTokenClaims?.given_name,
        lastName: idToken.idTokenClaims?.family_name,
        email: idToken.idTokenClaims?.email,
        accountType: AccountType.Microsoft,
        userId: null,
        activateGuid: null,
        id: null,
        password: null
    } as IUserAccount;
}

function handleLogin(instance: any, callback: (userInformation: IUserAccount, token: string) => Promise<IAuthenticatedUser>, setAuthenticated: (a: boolean) => void, showError: (e: string) => void, setAuthStatus: (loggedIn: boolean, user: IAuthenticatedUser) => Promise<void>, setRedirect: (a: boolean) => void, setShowWaitingSpinner:(a:boolean)=>void) {
    return instance.loginPopup(loginRequest).then((result:any) => {

        console.log("MICROSOFT ACCOUNT", result);

        let user = parseMicrosoftUser(result?.account);

        setShowWaitingSpinner(true);

        callback(user, result.idToken).then((spUser: IAuthenticatedUser) => {
            if (spUser.isNewUser) {
                window.sessionStorage.rsmConsentTarget = JSON.stringify(spUser);

                setShowWaitingSpinner(false);

                // redirect user to consent page first
                setRedirect(true);

                // for now, don't sign user in yet
                window.sessionStorage.removeItem("authToken");
                window.sessionStorage.removeItem("rsmUser");
            } else {
                setShowWaitingSpinner(false);
                setAuthStatus(true, spUser);
                setAuthenticated(true);
            }
        }).catch((err) => {            
            setShowWaitingSpinner(false);
            
            console.log("EnsureUser: "+err);
            instance.logoutRedirect({
                onRedirectNavigate: (url:string) => {
                    // Return false if you would like to stop navigation after local logout
                    return false;
                }
            });
            setAuthStatus(false, null);
            setAuthenticated(false);

            window.sessionStorage.removeItem("authToken");
            window.sessionStorage.removeItem("rsmUser");

            showError("Microsoft");
        });


    }).catch((err:string) => {
        console.error(err);
    });
}

function handleLogout(instance: any, setAuthenticated: (a: boolean) => void, setAuthStatus: (loggedIn: boolean, user: IAuthenticatedUser) => Promise<void>) {
    instance.logoutPopup().then((result:any) => {
        setAuthenticated(false);
        setAuthStatus(false, null);
    }).catch((err:string) => {
        console.error(err);
    });
}

export interface IMicrosoftComponentProps {
    currentUser: IAuthenticatedUser;
    signedIn: boolean;
    callback: (userInformation: IUserAccount, token: string, isRSMToken: boolean) => Promise<IAuthenticatedUser>;
    showError: (error:string)=>void;
    setAuthStatus: (loggedIn:boolean, user:IAuthenticatedUser) => Promise<void>;
}


export const MicrosoftComponent = (props:IMicrosoftComponentProps) => {
    const { instance, accounts } = useMsal();

    //console.log("MS Accounts", accounts);

    const [authenticated, setAuthenticated] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [showWaitingSpinner, setShowWaitingSpinner] = useState(false);

    const [userName, setUserName] = useState(props.currentUser ? `${props.currentUser.firstName} ${props.currentUser.lastName}` : "");

    useEffect(() => {
        if (props.signedIn) {
            setAuthenticated(true);
        } else {
            setAuthenticated(false);
        }

        if (props.currentUser) {
            setUserName(props.currentUser ? `${props.currentUser.firstName} ${props.currentUser.lastName}` : "");
        }

        //if (accounts.length > 0) {
        //    console.log("MSAL Accounts", accounts);

        //    let user = parseUser(accounts[0]);
        //    if (!props.currentUser || props.currentUser.email !== user.email) {
        //        props.setAuthStatus(true, user);
        //    }
        //} else {
        //    if (props.currentUser) {
        //        props.setAuthStatus(false, null);
        //    }
        //}
    });    

    return (
        <>
            {redirect && <Redirect to={"consent"}/> }
            {authenticated ?
                <><div>Signed in as:<br />{`${userName}`}<br /><Button variant="secondary" className="microsoftButton" style={{ width: '120px', marginTop: '10px' }} onClick={() => handleLogout(instance, setAuthenticated, props.setAuthStatus)}>Logout</Button></div></>
                :
                <Button style={{ position:'relative', top:'2px', width: '200px', fontSize: '14px', fontFamily: 'Roboto, sans-serif', fontWeight:500, color:'black' }} variant="secondary" className="microsoftButton" onClick={() => handleLogin(instance, (user:IUserAccount, token:string) => {
                    return props.callback(user, token, false);
                }, setAuthenticated, props.showError, props.setAuthStatus, setRedirect, setShowWaitingSpinner)}>Sign in with Microsoft</Button>
            }
            <Dialog
                    hidden={!showWaitingSpinner}
                    dialogContentProps={{
                        type: DialogType.normal,
                    }}
                    modalProps={{ isBlocking: true, className: "busyOverlay" }}
                >
                    <Spinner size={SpinnerSize.large} label="Checking Microsoft Account..." ariaLive="assertive" />
                </Dialog>             
        </>
    );
}