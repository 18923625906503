import * as React from 'react';
import { css, Stack, PrimaryButton, Text, getTheme, INavLinkGroup, Nav, IStackProps, INavLink, TextField, Label, IconButton, DatePicker, StackItem, mergeStyles, IStyle, IDropdownOption, Dropdown } from 'office-ui-fabric-react';
import '../Proposal.scss';
import { IFormProps } from '../Models/IFormProps';
import { observer } from 'mobx-react';
import { WizardNavigationButtons } from './WizardNavigationFooter';
import ProposalFormValidation from './ProposalFormValidation';

require('../../globalcss.css');

export interface IProjectPlanProps extends IFormProps {
    typeOfStudyList: string[];
}

export interface IProjectPlanState {
}

@observer
export class ProjectPlan extends React.Component<IProjectPlanProps, IProjectPlanState> {

    constructor(props: any) {
        super(props);
        this.state = {
        }
    }

    public componentDidMount() {
    }

    public render() {

        const optionsForTypeOfStudy: IDropdownOption[] = [{key:"",text:""}];
        this.props.typeOfStudyList?.map((type) =>
            optionsForTypeOfStudy.push({ key: type, text: type })
        );

        const columnProps1: Partial<IStackProps> = {
            tokens: { childrenGap: 10 },
            styles: { root: { width: "20%" } },
        };

        const columnProps2: Partial<IStackProps> = {
            tokens: { childrenGap: 10 },
            styles: { root: { width: "30%" } },
        };

        const columnProps3: Partial<IStackProps> = {
            tokens: { childrenGap: 10 },
            styles: { root: { width: "50%" } },
        };

        const columnProps4: Partial<IStackProps> = {
            tokens: { childrenGap: 10 },
            styles: { root: { width: "70%" } },
        };

        const columnProps5: Partial<IStackProps> = {
            tokens: { childrenGap: 10 },
            styles: { root: { width: "80%" } },
        };

        const columnProps6: Partial<IStackProps> = {
            tokens: { childrenGap: 10 },
            styles: { root: { width: "60%" } },
        };

        const columnProps7: Partial<IStackProps> = {
            tokens: { childrenGap: 10 },
            styles: { root: { width: "10%" } },
        };

        const columnProps8: Partial<IStackProps> = {
            tokens: { childrenGap: 10 },
            styles: { root: { width: "40%" } },
        };

        const onFormatDate = (date?: Date): string => {
            return !date ? '' : (date.getDate()) + '.' + (date.getMonth()+1) + '.' + date.getFullYear();
        };

        if (!this.props.proposal.projectTimeline) {
            this.props.proposal.projectTimeline = {};
        }
        if (!this.props.proposal.projectTimeline?.timelines || this.props.proposal.projectTimeline?.timelines.length == 0) {
            this.props.proposal.projectTimeline.timelines = [];
            this.props.proposal.projectTimeline?.timelines.push({});
        }

        if (!this.props.proposal.experienceOfPI) {
            this.props.proposal.experienceOfPI = {};
        }

        if (!this.props.proposal.experienceOfPI?.studies || this.props.proposal.experienceOfPI?.studies.length == 0) {
            this.props.proposal.experienceOfPI.studies = [];
            this.props.proposal.experienceOfPI?.studies.push({});
            this.props.proposal.experienceOfPI?.studies.push({});
            this.props.proposal.experienceOfPI?.studies.push({});
        }

        const styleError: string = mergeStyles({
            fontFamily: 'Segoe UI, Segoe UI Web (West European), Segoe UI, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif',
            fontSize: '12px',
            fontWeight: 400,
            color: 'rgb(164, 38, 44)',
            margin: '0px',
            paddingTop: '5px',
            display: 'flex',
            alignItems: 'center'
        });

        const placeholderStyle: IStyle = {
            fontStyle: 'italic !important',
            color: '#c7c7c7!important'
        };

        return (
            <Stack verticalAlign="center" className={css('fullWidth', 'animate', 'stepPopIn')} verticalFill={true} tokens={{ childrenGap: 20 }}>
                <Stack verticalAlign="space-between" tokens={{ childrenGap: 5 }}>
                    {
                        this.props.isReadonly && <Label className="heading">Project Plan</Label>
                    }
                    {
                        !this.props.isReadonly && <h1>Project Plan</h1>
                    }
                </Stack>
                <Stack className="fullWidth">
                    <ProposalFormValidation {...this.props} fieldErrors={this.props.proposal.fieldErrors} ></ProposalFormValidation>
                </Stack>
                <Stack className="fullWidth" tokens={{ childrenGap: 10 }}>
                    <TextField label="Publication and Presentation plan" maxLength={62000} placeholder="Please describe where you plan to present your research (congress and/or journal)" multiline rows={3} value={this.props.proposal.publicationAndPresentationPlan}
                        readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                        onChange={(_event: any, newValue?: string) => {
                            this.props.proposal.publicationAndPresentationPlan = newValue;
                        }}></TextField>
                    <fieldset>
                        <legend>Project timetable</legend>
                        <Stack className="fullWidth" tokens={{ childrenGap: 10 }}>
                            <Stack tokens={{ childrenGap: 10 }}>
                                <Stack horizontal tokens={{ childrenGap: 10 }}>
                                    <Stack {...columnProps1}>
                                        <TextField placeholder="No of milestone" value={this.props.proposal.projectTimeline.timelines[0].no}
                                            readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                            errorMessage={this.props.proposal.fieldErrors && this.props.proposal.fieldErrors[6]["projectTimeline.timelines"] && this.props.proposal.fieldErrors[6]["projectTimeline.timelines"]?.childErrors ? this.props.proposal.fieldErrors[6]["projectTimeline.timelines"]?.childErrors[`timelines_no_0`]?.error : null}
                                            onChange={(_event: any, newValue?: string) => {
                                                if (this.props.proposal.projectTimeline?.timelines)
                                                    this.props.proposal.projectTimeline.timelines[0].no = newValue;
                                            }}></TextField>
                                    </Stack>
                                    <Stack {...columnProps6}>
                                        <TextField placeholder="Milestones" value={this.props.proposal.projectTimeline.timelines[0].milestone}
                                            errorMessage={this.props.proposal.fieldErrors && this.props.proposal.fieldErrors[6]["projectTimeline.timelines"] && this.props.proposal.fieldErrors[6]["projectTimeline.timelines"]?.childErrors ? this.props.proposal.fieldErrors[6]["projectTimeline.timelines"]?.childErrors[`timelines_milestone_0`]?.error : null}
                                            readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                            onChange={(_event: any, newValue?: string) => {
                                                if (this.props.proposal.projectTimeline?.timelines)
                                                    this.props.proposal.projectTimeline.timelines[0].milestone = newValue;
                                            }}></TextField>
                                    </Stack>
                                    <Stack {...columnProps1}>
                                        {
                                            this.props.isReadonly && <Label className="text">{onFormatDate(this.props.proposal.projectTimeline.timelines[0].startDate)}</Label>
                                        }
                                        {
                                            !this.props.isReadonly && <Stack>
                                                <DatePicker
                                                formatDate={onFormatDate}
                                                placeholder="Start date"
                                                styles={{ readOnlyPlaceholder: placeholderStyle}}
                                                value={this.props.proposal.projectTimeline.timelines[0].startDate}
                                                onSelectDate={(date: Date | null | undefined): void => {
                                                    if (this.props.proposal.projectTimeline?.timelines && date)
                                                        this.props.proposal.projectTimeline.timelines[0].startDate = date;
                                                }}
                                            />
                                                <Label className={css(styleError, 'rsmError')}>{this.props.proposal.fieldErrors && this.props.proposal.fieldErrors[6]["projectTimeline.timelines"] && this.props.proposal.fieldErrors[6]["projectTimeline.timelines"]?.childErrors ? this.props.proposal.fieldErrors[6]["projectTimeline.timelines"]?.childErrors[`timelines_startDate_0`]?.error : null}</Label>
                                            </Stack>
                                        }
                                    </Stack>
                                    <Stack {...columnProps1}>
                                        {
                                            this.props.isReadonly && <Label className="text">{onFormatDate(this.props.proposal.projectTimeline.timelines[0].endDate)}</Label>
                                        }
                                        {
                                            !this.props.isReadonly && <Stack>
                                                <DatePicker
                                                formatDate={onFormatDate}
                                                placeholder="End date"
                                                styles={{ readOnlyPlaceholder: placeholderStyle }}
                                                value={this.props.proposal.projectTimeline.timelines[0].endDate}
                                                onSelectDate={(date: Date | null | undefined): void => {
                                                    if (this.props.proposal.projectTimeline?.timelines && date)
                                                        this.props.proposal.projectTimeline.timelines[0].endDate = date;
                                                }}
                                                />
                                                <Label className={css(styleError, 'rsmError')}>{this.props.proposal.fieldErrors && this.props.proposal.fieldErrors[6]["projectTimeline.timelines"] && this.props.proposal.fieldErrors[6]["projectTimeline.timelines"]?.childErrors ? this.props.proposal.fieldErrors[6]["projectTimeline.timelines"]?.childErrors[`timelines_endDate_0`]?.error : null}</Label>
                                            </Stack>
                                        }
                                    </Stack>
                                    {
                                        !this.props.isReadonly &&
                                        <IconButton iconProps={{ iconName: 'Add' }}
                                            title="Add" ariaLabel="Add" onClick={() => {
                                                this.props.proposal.projectTimeline?.timelines?.push({});
                                            }} />
                                    }
                                </Stack>
                                {
                                    this.props.proposal.projectTimeline?.timelines?.map((item, index) => {
                                        if (index != 0) {
                                            return <Stack key={`projTimeline_${index}`} horizontal tokens={{ childrenGap: 10 }}>
                                                <Stack {...columnProps1}>
                                                    <TextField placeholder="No of milestone" value={item.no || ''}
                                                        readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                                        errorMessage={this.props.proposal.fieldErrors && this.props.proposal.fieldErrors[6]["projectTimeline.timelines"] && this.props.proposal.fieldErrors[6]["projectTimeline.timelines"]?.childErrors ? this.props.proposal.fieldErrors[6]["projectTimeline.timelines"]?.childErrors[`timelines_no_${index}`]?.error : null}
                                                        onChange={(_event: any, newValue?: string) => {
                                                            item.no = newValue;
                                                        }}></TextField>
                                                </Stack>
                                                <Stack {...columnProps6}>
                                                    <TextField placeholder="Milesonte" value={item.milestone || ''}
                                                        readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                                        errorMessage={this.props.proposal.fieldErrors && this.props.proposal.fieldErrors[6]["projectTimeline.timelines"] && this.props.proposal.fieldErrors[6]["projectTimeline.timelines"]?.childErrors ? this.props.proposal.fieldErrors[6]["projectTimeline.timelines"]?.childErrors[`timelines_milestone_${index}`]?.error : null}
                                                        onChange={(_event: any, newValue?: string) => {
                                                            item.milestone = newValue;
                                                        }}></TextField>
                                                </Stack>
                                                <Stack {...columnProps1}>
                                                    {
                                                        this.props.isReadonly && <Label className="text">{onFormatDate(item.startDate)}</Label>
                                                    }
                                                    {
                                                        !this.props.isReadonly && <Stack>
                                                            <DatePicker
                                                            formatDate={onFormatDate}
                                                            placeholder="Start date"
                                                            value={item.startDate}
                                                            onSelectDate={(date: Date | null | undefined): void => {
                                                                if (date)
                                                                    item.startDate = date;
                                                            }}
                                                        />
                                                            <Label className={css(styleError, 'rsmError')}>{this.props.proposal.fieldErrors && this.props.proposal.fieldErrors[6]["projectTimeline.timelines"] && this.props.proposal.fieldErrors[6]["projectTimeline.timelines"]?.childErrors ? this.props.proposal.fieldErrors[6]["projectTimeline.timelines"]?.childErrors[`timelines_startDate_${index}`]?.error : null}</Label>
                                                        </Stack>
                                                    }
                                                </Stack>
                                                <Stack {...columnProps1}>
                                                    {
                                                        this.props.isReadonly && <Label className="text">{onFormatDate(item.endDate)}</Label>
                                                    }
                                                    {
                                                        !this.props.isReadonly && <Stack><DatePicker
                                                            formatDate={onFormatDate}
                                                            placeholder="End date"
                                                            value={item.endDate}
                                                            onSelectDate={(date: Date | null | undefined): void => {
                                                                if (date)
                                                                    item.endDate = date;
                                                            }}
                                                        />
                                                            <Label className={css(styleError, 'rsmError')}>{this.props.proposal.fieldErrors && this.props.proposal.fieldErrors[6]["projectTimeline.timelines"] && this.props.proposal.fieldErrors[6]["projectTimeline.timelines"]?.childErrors ? this.props.proposal.fieldErrors[6]["projectTimeline.timelines"]?.childErrors[`timelines_endDate_${index}`]?.error : null}</Label>
                                                        </Stack>
                                                    }
                                                </Stack>
                                                {
                                                    !this.props.isReadonly &&
                                                    <IconButton iconProps={{ iconName: 'Remove' }}
                                                        title="Remove" ariaLabel="Remove" onClick={() => {
                                                            this.props.proposal.projectTimeline?.timelines?.splice(index, 1);
                                                        }} />
                                                }
                                            </Stack>;
                                        }
                                    })
                                }
                                <TextField placeholder="Comments" multiline rows={3} value={this.props.proposal.projectTimeline.comments}
                                    readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                    onChange={(_event: any, newValue?: string) => {
                                        if (this.props.proposal.projectTimeline)
                                            this.props.proposal.projectTimeline.comments = newValue;
                                    }}></TextField>
                            </Stack>
                        </Stack>
                    </fieldset>
                    <fieldset>
                        <legend>Experience of PI</legend>
                        <Stack tokens={{ childrenGap: 10 }}>
                            <Stack horizontal tokens={{ childrenGap: 10 }}>
                                <Label>Number of published articles in international peer-reviewed journals (as PI):</Label>
                                <TextField type="number" value={this.props.proposal.experienceOfPI?.numberOfPulication?.toString()}
                                    readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                    onChange={(_event: any, newValue?: string) => {
                                        if (this.props.proposal.experienceOfPI)
                                            this.props.proposal.experienceOfPI.numberOfPulication = Number(newValue);
                                    }}></TextField>
                            </Stack>
                            <fieldset>
                                <legend>Ongoing studies</legend>
                                <Stack tokens={{ childrenGap: 10 }}>
                                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                                        <Stack {...columnProps7}>
                                            <Label>#1</Label>
                                        </Stack>
                                        <Stack {...columnProps2}>
                                            {
                                                this.props.isReadonly ? <Label>{this.props.proposal.experienceOfPI?.studies[0]?.type|| ""}</Label> : <Dropdown
                                                    errorMessage={this.props.proposal.fieldErrors ? this.props.proposal.fieldErrors[1]["typeOfStudy"]?.error : null}
                                                    placeholder="Select a type"
                                                    options={optionsForTypeOfStudy}
                                                    //styles={dropdownStyles}
                                                    onChange={(event: any, option?: IDropdownOption, index?: number): void => {
                                                        this.props.proposal.experienceOfPI.studies[0].type = option ? option.text : "";
                                                    }}
                                                    selectedKey={this.props.proposal.experienceOfPI?.studies[0].type} />
                                            }


                                        </Stack>
                                        <Stack {...columnProps2}>
                                            <TextField placeholder="Title" value={this.props.proposal.experienceOfPI?.studies[0].title}
                                                readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                                onChange={(_event: any, newValue?: string) => {
                                                    if (this.props.proposal.experienceOfPI?.studies)
                                                        this.props.proposal.experienceOfPI.studies[0].title = newValue;
                                                }}></TextField>
                                        </Stack>
                                        <Stack {...columnProps2}>
                                            <TextField placeholder="Industry partner, if any" value={this.props.proposal.experienceOfPI?.studies[0].industryPartner}
                                                readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                                onChange={(_event: any, newValue?: string) => {
                                                    if (this.props.proposal.experienceOfPI?.studies)
                                                        this.props.proposal.experienceOfPI.studies[0].industryPartner = newValue;
                                                }}></TextField>
                                        </Stack>
                                    </Stack>

                                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                                        <Stack {...columnProps7}>
                                            <Label>#2</Label>
                                        </Stack>
                                        <Stack {...columnProps2}>
                                            {
                                                this.props.isReadonly ? <Label>{this.props.proposal.experienceOfPI?.studies[1]?.type || ""}</Label> : <Dropdown
                                                    errorMessage={this.props.proposal.fieldErrors ? this.props.proposal.fieldErrors[1]["typeOfStudy"]?.error : null}
                                                    placeholder="Select a type"
                                                    options={optionsForTypeOfStudy}
                                                    //styles={dropdownStyles}
                                                    onChange={(event: any, option?: IDropdownOption, index?: number): void => {
                                                        this.props.proposal.experienceOfPI.studies[1].type = option ? option.text : "";
                                                    }}
                                                    selectedKey={this.props.proposal.experienceOfPI?.studies[1].type} />
                                            }

                                        </Stack>
                                        <Stack {...columnProps2}>
                                            <TextField placeholder="Title" value={this.props.proposal.experienceOfPI?.studies[1].title}
                                                readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                                onChange={(_event: any, newValue?: string) => {
                                                    if (this.props.proposal.experienceOfPI?.studies)
                                                        this.props.proposal.experienceOfPI.studies[1].title = newValue;
                                                }}></TextField>
                                        </Stack>
                                        <Stack {...columnProps2}>
                                            <TextField placeholder="Industry partner, if any" value={this.props.proposal.experienceOfPI?.studies[1].industryPartner}
                                                readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                                onChange={(_event: any, newValue?: string) => {
                                                    if (this.props.proposal.experienceOfPI?.studies)
                                                        this.props.proposal.experienceOfPI.studies[1].industryPartner = newValue;
                                                }}></TextField>
                                        </Stack>
                                    </Stack>

                                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                                        <Stack {...columnProps7}>
                                            <Label>#3</Label>
                                        </Stack>
                                        <Stack {...columnProps2}>
                                            {
                                                this.props.isReadonly ? <Label>{this.props.proposal.experienceOfPI?.studies[2]?.type || ""}</Label> : <Dropdown
                                                    errorMessage={this.props.proposal.fieldErrors ? this.props.proposal.fieldErrors[2]["typeOfStudy"]?.error : null}
                                                    placeholder="Select a type"
                                                    options={optionsForTypeOfStudy}
                                                    //styles={dropdownStyles}
                                                    onChange={(event: any, option?: IDropdownOption, index?: number): void => {
                                                        this.props.proposal.experienceOfPI.studies[2].type = option ? option.text : "";
                                                    }}
                                                    selectedKey={this.props.proposal.experienceOfPI?.studies[2].type} />
                                            }
                                            
                                        </Stack>
                                        <Stack {...columnProps2}>
                                            <TextField placeholder="Title" value={this.props.proposal.experienceOfPI?.studies[2].title}
                                                readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                                onChange={(_event: any, newValue?: string) => {
                                                    if (this.props.proposal.experienceOfPI?.studies)
                                                        this.props.proposal.experienceOfPI.studies[2].title = newValue;
                                                }}></TextField>
                                        </Stack>
                                        <Stack {...columnProps2}>
                                            <TextField placeholder="Industry partner, if any" value={this.props.proposal.experienceOfPI?.studies[2].industryPartner}
                                                readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                                onChange={(_event: any, newValue?: string) => {
                                                    if (this.props.proposal.experienceOfPI?.studies)
                                                        this.props.proposal.experienceOfPI.studies[2].industryPartner = newValue;
                                                }}></TextField>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </fieldset>
                        </Stack>
                    </fieldset>

                    <Stack className="marginTop" horizontal tokens={{ childrenGap: 10 }}>
                        <Stack {...columnProps8}>
                            <TextField label="Monitoring" placeholder="Name" value={this.props.proposal.projectPlan?.monitoring}
                                readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                onChange={(_event: any, newValue?: string) => {
                                    if (!this.props.proposal.projectPlan)
                                        this.props.proposal.projectPlan = {};
                                    this.props.proposal.projectPlan.monitoring = newValue;
                                }}></TextField>
                        </Stack>
                        <Stack {...columnProps2}>
                            <TextField label="Data management" placeholder="Name" value={this.props.proposal.projectPlan?.dataManagement}
                                readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                onChange={(_event: any, newValue?: string) => {
                                    if (!this.props.proposal.projectPlan)
                                        this.props.proposal.projectPlan = {};
                                    this.props.proposal.projectPlan.dataManagement = newValue;
                                }}></TextField>
                        </Stack>
                        <Stack {...columnProps2}>
                            <TextField label="Analysis" placeholder="Name" value={this.props.proposal.projectPlan?.analysis}
                                readOnly={this.props.isReadonly} borderless={this.props.isReadonly}
                                onChange={(_event: any, newValue?: string) => {
                                    if (!this.props.proposal.projectPlan)
                                        this.props.proposal.projectPlan = {};
                                    this.props.proposal.projectPlan.analysis = newValue;
                                }}></TextField>
                        </Stack>
                    </Stack>
                </Stack>

                {
                    this.props.showWizardNavigation && <StackItem className={css("wizardNavigationContainer")}>
                        <WizardNavigationButtons {...this.props} step={this.props.currentStep} save={this.props.onSave}></WizardNavigationButtons>
                    </StackItem>
                }
            </Stack>
        );

    }
}