import * as React from 'react';
import * as _ from 'lodash';
import styles from './FilterBox.module.scss';
import { Label, TextField, IconButton, IStyleFunctionOrObject, ITextField } from 'office-ui-fabric-react';
import { ITextFieldStyles, ITextFieldStyleProps } from 'office-ui-fabric-react/lib/TextField';
import { concatStyleSets, mergeStyleSets, IStyleSet, mergeStyles, IStyle } from 'office-ui-fabric-react/lib/Styling';

export interface IFilterBoxProps {
    onFilterTriggered: (filter: string) => void;
    onFilterCleared: () => void;
    delay: number;
    mainStyle?: React.CSSProperties;
    labelStyle?: React.CSSProperties;
    iconStyle?: React.CSSProperties;
    labelText?: string;
    placeHolder?: string;

    filter: string;

    styles?: IFilterBoxStyles;
}

export interface IFilterBoxStyles {
    root?: IStyle;
    label?: IStyle;
    textField?: Partial<ITextFieldStyles>;
}

export interface IFilterBoxState {
    filter: string;
    filtered: boolean;
    labelText?: string;
    placeHolder?: string;
}

export class FilterBox extends React.Component<IFilterBoxProps, IFilterBoxState>{

    private static filterTypingTimeoutRef:any = null;
    private textBox:ITextField = null;

    constructor(props: IFilterBoxProps) {
        super(props);

        this.state = {
            filter: props.filter,
            filtered: !!props.filter,
            labelText: props.labelText ? props.labelText : null,
            placeHolder: props.placeHolder
        };
    }

    public componentWillReceiveProps(newProps: IFilterBoxProps) {
        if(this.props.filter != newProps.filter){
            this.setState({filter: newProps.filter, filtered: !!newProps.filter});
        }

        if (this.state.labelText != newProps.labelText) {
            this.setState({ labelText: newProps.labelText });
        }

        if (this.state.placeHolder != newProps.placeHolder) {
            this.setState({ placeHolder: newProps.placeHolder });
        }
    }

    private onFilterEnterPressed = (): void => {
        // if enter was pressed, trigger filter immediately
        if (FilterBox.filterTypingTimeoutRef) {
            window.clearTimeout(FilterBox.filterTypingTimeoutRef);
            FilterBox.filterTypingTimeoutRef = null;
        }
        this.triggerFiltering();
    }

    private onFilterChanged = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
        this.setState({
            filter: newValue && newValue.trim().length !== 0 ? newValue.toLowerCase() : null,
            filtered: !!newValue
        });

        if (FilterBox.filterTypingTimeoutRef) {
            window.clearTimeout(FilterBox.filterTypingTimeoutRef);
            FilterBox.filterTypingTimeoutRef = null;
        }

        FilterBox.filterTypingTimeoutRef = window.setTimeout(() => {
            FilterBox.filterTypingTimeoutRef = null;
            this.triggerFiltering();
        }, this.props.delay);
    }

    private triggerFiltering = (): void => {
        this.setState({
            filtered: !!this.state.filter
        });

        if (this.props.onFilterTriggered) {
            this.props.onFilterTriggered(this.state.filter);
        }
    }

    public clearFilter = (skipEvent: boolean = false): void => {
        this.setState({
            filter: null,
            filtered: false
        });

        if (!skipEvent && this.props.onFilterCleared) {
            this.props.onFilterCleared();
        }
    }

    private onClearFilterClicked = (): void => {
        this.clearFilter(false);
    }

    public focus = (): void =>{
       if(this.textBox){
           this.textBox.focus();
           this.textBox.setSelectionStart(0);
           this.textBox.setSelectionEnd(this.textBox.value.length);
       }
    }

    private setTextFieldRef = (fieldRef: ITextField):void =>{
        this.textBox = fieldRef;
    }

    public render(): React.ReactElement<IFilterBoxProps> {
        let rootStyles:IStyle[] = [{ marginTop: '4px', display: 'inline-flex' }];
        if(this.props.styles?.root){
            rootStyles.push(this.props.styles?.root);
        }
        let rootClassName = mergeStyles(rootStyles);

        let labelStyles:IStyle[] = [{ marginRight: '10px' }];
        if(this.props.styles?.label){
            labelStyles.push(this.props.styles?.label);
        }
        //let label = this.props.styles && this.props.styles.label ? this.props.styles.label : { marginRight: '10px' };
        let labelClassName = mergeStyles(labelStyles);

        //let textFieldStyles = this.props.styles && this.props.styles.textField ? this.props.styles.textField : null;
        let iconStyle: React.CSSProperties = this.props.iconStyle || { backgroundColor: 'transparent', color: 'inherit' };

        let textFieldStyles:Partial<ITextFieldStyles> = {field:{selectors:{"::placeholder":{color:'#ccc',fontStyle:'italic'}}}};
        if(this.props.styles && this.props.styles.textField){
            textFieldStyles = _.merge(textFieldStyles, this.props.styles.textField);
        }
        

        return (
            <React.Fragment>
                <div className={rootClassName}>
                    {
                        this.state.labelText ? <Label className={labelClassName}>{this.state.labelText}</Label> : null
                    }                    
                    <TextField componentRef={this.setTextFieldRef} styles={textFieldStyles} placeholder={this.state.placeHolder} onChange={this.onFilterChanged} value={this.state.filter} onKeyDown={(event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => { if (event.keyCode === 13) { this.onFilterEnterPressed(); } }}></TextField>
                    <IconButton style={iconStyle} iconProps={{ iconName: 'ClearFilter' }} onClick={this.onClearFilterClicked} disabled={!this.state.filtered}></IconButton>
                </div>
            </React.Fragment>
        );
    }

}