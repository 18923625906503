import * as React from 'react';
import { css, Stack, PrimaryButton, Text, getTheme, Link, DetailsList, DetailsListLayoutMode, IColumn, SelectionMode, IDetailsRowProps, DetailsRow, Spinner, IconButton, Dialog, DialogFooter, DefaultButton, ConstrainMode, Icon } from 'office-ui-fabric-react';
import { PageURL } from '../../App';
import './Welcome.scss';
import { IUserAccount, LoginComponent } from '../LoginComponent/LoginComponent';
import { Redirect } from 'react-router';
import DataProvider from '../../dataprovider/DataProvider';
import { IProposal } from '../Proposal/Models/IProposal';
import moment from 'moment';

const theme = getTheme();

export interface IWelcomePageProps {
    location: Location;
    history: any;
    stepSizeClass: string;
    isLoggedIn: boolean;
    dataProvider: DataProvider;
    currentUser?: IUserAccount;
}

export interface IWelcomePageState {
    isLoggedIn: boolean;
    myItems?: any[];
    isLoading: boolean;
    isDeleteConfirmationOpen: boolean;
    selectedItem: any;
    isDeleting: boolean;
}

export class WelcomePage extends React.Component<IWelcomePageProps, IWelcomePageState> {

    private _columns: IColumn[] = [
        { key: 'studyTitle', name: 'Study Title', fieldName: 'studyTitle', minWidth: 270, maxWidth: 300,  isResizable: true },
        { key: 'propId', name: 'Proposal ID', fieldName: 'proposalID', minWidth: 100, maxWidth: 130, isResizable: true },
        { key: 'summary', name: 'Summary', fieldName: 'summary', minWidth: 270, maxWidth: 300, isResizable: true },
        { key: 'created', name: 'Created', fieldName: 'created', minWidth: 100, maxWidth: 100, isResizable: false },
        { key: 'actions', name: '', fieldName: null, minWidth: 40, maxWidth: 40, isResizable: false },
    ]

    constructor(props: IWelcomePageProps) {
        super(props);

        this.state = {
            isLoggedIn: props.isLoggedIn,
            myItems: [],
            isLoading: false,
            isDeleteConfirmationOpen: false,
            selectedItem: null,
            isDeleting: false
        };
        this.setLoggedInInformation();

    }
    public componentDidMount() {
        console.log("Welcomepage componentDidMount");
        
    }

    public componentWillReceiveProps(newProps: IWelcomePageProps) {
        console.log("WelcomePage componentWillReceiveProps");
        if (this.state.isLoggedIn != newProps.isLoggedIn) {
            this.setState({ isLoggedIn: newProps.isLoggedIn }, () => {
                this.setLoggedInInformation();
            });
        }
    }

    private setLoggedInInformation() {
        console.log("setLoggedInInof: " + this.state.isLoggedIn);
        if (this.state.isLoggedIn && this.props.currentUser) {
            this.setState({ isLoading: true });
            this.props.dataProvider.getAllProposals(this.props.currentUser.userId).then(items => {
                console.log(items);
                this.setState({ myItems: items });
            }).catch((err) => {
                console.log("Error trying to load proposals: " + err);
                this.setState({ myItems: [] });
            }).finally(() => {
                this.setState({ isLoading: false });
            });
        } else {
            this.setState({ myItems: [] });

        }
    }
    private _renderRow = (props: IDetailsRowProps): JSX.Element => {
        if (props) {
            return <div onClick={() => this._onDetailsLineClick(props.item)}><DetailsRow {...props} /></div>;
        }
    }


    private _onDetailsLineClick = (item: any) => {
        this.props.history.push(PageURL.CreateProposal + "?id="+item.id);
        //alert("clicked: " + JSON.stringify(item));
    }

    private _renderItemColumn = (item: any[], index: number, column: IColumn) => {
        const fieldContent = (item as any)[column.fieldName as string] as string;

        switch (column.key) {
            case 'created':
                return Boolean(fieldContent) ? moment(new Date(fieldContent)).format("DD.MM.YYYY") : null;
            case 'actions':
                return <IconButton iconProps={{ iconName: 'Delete' }} onClick={
                    (event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        this.setState({
                            selectedItem: item,
                            isDeleteConfirmationOpen: true
                        });
                    }
                } />
            default:
                return fieldContent;
        }
    }

    private onDeleteClicked = () => {
        this.setState({ isDeleting: true });
        this.props.dataProvider.deleteProposal(this.state.selectedItem.id).then(() => {
            this.props.dataProvider.getAllProposals(this.props.currentUser.userId).then(items => {
                console.log(items);
                this.setState({ myItems: items, isLoading: false, isDeleting: false, isDeleteConfirmationOpen: false });
            });
        });
    };

    private onDismissDeleteDialog = () => {
        this.setState({
            selectedItem: null,
            isDeleteConfirmationOpen: false
        });
    };

    public render(): React.ReactElement<IWelcomePageProps> {


        return (
            <Stack verticalAlign="center" className="fullWidth" horizontalAlign="center" verticalFill={true} tokens={{ childrenGap: 20 }}>
                <Stack horizontal className="fullWidth" horizontalAlign="center">
                    <h3>Investigator-initiated Studies</h3>
                </Stack>
                <Stack verticalAlign="space-between" horizontalAlign="start" className={css("welcomeContainer", this.props.stepSizeClass, "animate", "popIn")} tokens={{ childrenGap: 5 }}>                    
                    <h4>Overview</h4>
                    <p>Thank you for your interest in working with Straumann to support medical research. Following our commitment to "More Than Creating Smiles: Restoring Confidence", we want to be your partner of choice in esthetic dentistry. </p>
                    <p>Close collaboration with dental professionals is a hallmark of Straumann's success. To continue this tradition and facilitate the process of turning concepts into solutions, Straumann invites medical researchers to submit study proposals, which are received, reviewed and given feedback by a dedicated Research Screening Committee. The following are the types of research projects the committee reviews: .</p>
                    <ul>
                        <li><span className="bold">Pre Clinical Studies</span>
                            Pre-clinical studies involve testing our products by mechanical, cellular or digital assays (in vitro studies) or by histological or surface-driven analysis (animal studies). </li>
                        <li><span className="bold">Clinical Studies</span>
                            An Investigator-Initiated Study (IIS) aims to test clinical hypothesis within the intended use of our products and solutions.   </li>
                    </ul>
                    <h4>Focus topics</h4>
                    <ol>
                        <li>Reduced invasiveness and improved efficiency treatment protocols including Straumann implants, prosthetics and biomaterials</li>
                        <li>Prevention and management of biologic and technical complications and treatment protocols</li>
                        <li>Digital technologies</li>
                        <li>Analysis of retrospective databases, registries and data collections</li>
                    </ol>
                    <Stack className="fullWidth" horizontalAlign="center">                    
                    {
                            !this.state.isLoggedIn ?
                                <Stack horizontal className="fullWidth" horizontalAlign="center"><div style={{fontWeight:'bold'}}>Please sign in to be able to create a request</div></Stack>
                                :
                                <>
                                    <Stack horizontal className="fullWidth" horizontalAlign="center">
                                        <div style={{height:'38px'}}>
                                            <PrimaryButton
                                            text="Create new Proposal"                                    
                                            className="linkButton"
                                            // disabled={!this.state.isLoggedIn}
                                            onClick={() => {
                                                //sessionStorage.setItem("Country", country);
                                                // alert("clicked")
                                                this.props.history.push(PageURL.CreateProposal);
                                            }}
                                            allowDisabledFocus
                                            />
                                        </div>
                                    </Stack>

                                    <Stack horizontal className="fullWidth" horizontalAlign="center">
                                        <div style={{marginTop:'15px'}}>
                                        {
                                            this.state.myItems?.length > 0 ?
                                                    <>
                                                        <fieldset style={{ border: '1px solid #ddd', padding: '15px', boxShadow: '3px 3px 9px -3px #A09E9B', borderRadius:'5px'}}>
                                                            <legend style={{ paddingLeft: '10px', paddingRight:'10px', width:'initial', marginBottom:'0px'}}>My Draft Proposals</legend>
                                                            {/*<h3>My draft proposals</h3>*/}
                                                            <DetailsList
                                                                className="tableStyle"
                                                                items={this.state.myItems}
                                                                columns={this._columns}
                                                                setKey="set"
                                                                layoutMode={DetailsListLayoutMode.justified}
                                                                //constrainMode={ConstrainMode.horizontalConstrained}
                                                                skipViewportMeasures={true}
                                                                selectionPreservedOnEmptyClick={true}
                                                                onRenderRow={this._renderRow}
                                                                onRenderItemColumn={this._renderItemColumn}
                                                                selectionMode={SelectionMode.none}
                                                                styles={{ root: {paddingTop:'0px'}}}
                                                                    />
                                                        </fieldset>
                                                </> : <>{this.state.isLoading ? <Spinner label="Looking for draft proposals" /> : null }</>
                                            }
                                        </div>
                                    </Stack>
                            </>

                        }
                    </Stack>
                    {/*<fieldset className="contactUs">Contact us: <a href="mailto:research-screening@straumann.com">Research Screening</a></fieldset>*/}
                    <Stack className="fullWidth" style={{ paddingTop: '20px' }} horizontal horizontalAlign={'end'} tokens={{ childrenGap: '5' }}><a href="mailto:research-screening@straumann.com" style={{display:'flex', textDecoration:'none'}}><span style={{marginRight:'5px'}}>Contact us</span><Icon iconName="Mail" style={{position:'relative', top:'2px'}}/></a></Stack>
                    <Dialog
                        isBlocking={true}
                        isOpen={this.state.isDeleteConfirmationOpen}
                        onDismiss={this.onDismissDeleteDialog}
                        title="Delete Confirmation"
                        subText="Are you sure you want to delete this proposal?"
                        isDarkOverlay={true}
                        dialogContentProps={{showCloseButton: false}}
                    >                        
                        <DialogFooter>
                            <Stack tokens={{ childrenGap: '10' }}>
                                <Stack horizontal horizontalAlign={'end'} tokens={{childrenGap:'10'}}>
                                    <PrimaryButton onClick={this.onDeleteClicked} text="Delete" disabled={this.state.isDeleting} />
                                    <DefaultButton onClick={this.onDismissDeleteDialog} text="Cancel" disabled={this.state.isDeleting} />
                                </Stack>
                                <Stack horizontal horizontalAlign={'end'}>
                                    {this.state.isDeleting && <Spinner label="Deleting Proposal..." labelPosition={'right'} />}
                                </Stack>
                            </Stack>
                        </DialogFooter>
                    </Dialog>
                </Stack>

            </Stack>
        );
    }
}