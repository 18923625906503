import * as React from 'react';
import { Icon, INavLink, INavLinkGroup, IRenderFunction, Nav } from 'office-ui-fabric-react';

const navLinkGroups: INavLinkGroup[] = [
    {
        name: 'Menu',
        links: [
            { name: 'Study Team', url: '', key: '1' },
            { name: 'Study Description', url: '', key: '2' },
            { name: 'Study Power', url: '', key: '3' },
            { name: 'Study Interventions', url: '', key: '4' },
            { name: 'Materials and Methods', url: '', key: '5' },
            { name: 'Requested Support', url: '', key: '6' },
            { name: 'Project Plan', url: '', key: '7' },
            { name: 'Attachment', url: '', key: '8' },
            { name: 'Summary', url: '', key: '9' },
        ],
    },
];

const onRenderLink = (errors: string[], props?: INavLink, defaultRender?: (props?: INavLink) => JSX.Element | null): JSX.Element | null =>{
    if(errors && errors.indexOf(props.name)!==-1){
        props.icon = "Error";
    } else {
        props.icon = null;
    }
    return <span>{defaultRender(props)}</span>;    
};

const NavMenu = (props: any) => {
    let errors = props?.errors;    
    return (
        <Nav
            key={ props?.proposal?.errorTabs?.join("-")}
            /*styles={{root:{position: 'fixed', left: '160px'}}}*/
            onRenderGroupHeader={onRenderGroupHeader}
            groups={navLinkGroups}
            onRenderLink={onRenderLink.bind(null, props?.proposal?.errorTabs)}
            onLinkClick={(ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => { props.goToStep(Number(item?.key)) }}
            selectedKey={props.currentStep.toString()}
        />
    );
};

function onRenderGroupHeader(group: INavLinkGroup | undefined): JSX.Element {
    return <h3>{group?.name}</h3>;
}


export default NavMenu;